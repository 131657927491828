import React from 'react'
import Grid from '../layout/grid'
import NumberFormat from 'react-number-format';

export default props => (
    <Grid cols={props.cols}>
        <div className='form-group'>
            <label htmlFor={props.name} title={props.title}>{props.label}</label>
            {props.onChange ? (
				<NumberFormat {...props.input} className='form-control'
					readOnly={props.readOnly} type={props.type}
					placeholder={props.placeholder}
					decimalSeparator={","} decimalScale={props.casas} fixedDecimalScale={props.casas} thousandSeparator={'.'}
					value={props.value}
					onChange={props.onChange}  />
			) : (
                <NumberFormat {...props.input} className='form-control'
                    readOnly={props.readOnly} type={props.type}
                    placeholder={props.placeholder}
                    decimalSeparator={","} decimalScale={props.casas} fixedDecimalScale={props.casas} thousandSeparator={'.'}  />
            )}
        </div>
    </Grid>
)
