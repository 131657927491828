const INITIAL_STATE = { 
    registro: {}, 
    aguardando: false,
    modoTela: 'lista', 
    lista: [], 
    listaUsuario: [],
    listaApuracao: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'META_GERENCIA_SEMESTRE_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

        case 'META_GERENCIA_SEMESTRE_LISTADO':
            return { ...state, lista: action.payload.data };

        case 'META_GERENCIA_SEMESTRE_SET_AGUARDANDO':
            return { ...state, aguardando: action.payload };

        case 'META_GERENCIA_SEMESTRE_USUARIO_SELECT_LISTADO':
			return {
				...state,
				listaUsuario: action.payload.data
            };
        
        case 'META_GERENCIA_SEMESTRE_APURACAO_LISTADO':
			return {
				...state,
				listaApuracao: action.payload.data
            };
                      
        default:
            return state;
    }
}
