import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndCheckbox from '../common/form/labelAndCheckbox';
import Select from '../common/form/select';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndInputDate from '../common/form/labelAndInputDate';
import ButtonTable from '../common/table/buttonTable';
import { setError } from '../common/toast/toast';
import imagemAdd from '../assets/images/icone_add_imagem.png';
import pdfAdd from '../assets/images/icone_add_pdf.png';
import pdfAdicionado from '../assets/images/icone_pdf_adicionado.png';

import { setModoTela, initForm } from './metaHeitorSemestreActions';

class MetaHeitorSemestreForm extends Component {

	state = {
		nome_vendedor: null,
		codigo_vendedor: null
	}

    componentWillMount() {

    }

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

        return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>
					<ContentCardBody>
						<Row>

							<Field
								name='ano'
								component={Select}
								options={[{ id: 2025, valor: 2025 }, { id: 2026, valor: 2026 }]}
								label='Ano'
								cols='12 4 3 2'
								placeholder='Selecione o ano'
								readOnly={readOnly} />
								
							<Field
								name='semestre'
								component={Select}
								options={[{ id: 1, valor: '1º semestre' }, { id: 2, valor: '2º semestre' } ]}
								label='Semestre'
								cols='12 4 3 2'
								placeholder='Selecione o semestre'
								readOnly={readOnly} />

							<Field
								name='nome'
								component={LabelAndInput}
								label='Nome' placeholder='Informe'
								cols='12 6 4 2'
								readOnly={readOnly} />

						</Row>
						<Row>
							<Field
								name='volume_1'
								component={LabelAndInputNumber}
								label='Volume (faixa 1)' placeholder='Informe'
								cols='12 6 4 2'
								casas={2}
								readOnly={readOnly} />
							
							<Field
								name='volume_2'
								component={LabelAndInputNumber}
								label='Volume (faixa 2)' placeholder='Informe'
								cols='12 6 4 2'
								casas={2}
								readOnly={readOnly} />
							
							<Field
								name='volume_3'
								component={LabelAndInputNumber}
								label='Volume (faixa 3)' placeholder='Informe'
								cols='12 6 4 2'
								casas={2}
								readOnly={readOnly} />
							
							<Field
								name='volume_4'
								component={LabelAndInputNumber}
								label='Volume (faixa 4)' placeholder='Informe'
								cols='12 6 4 2'
								casas={2}
								readOnly={readOnly} />

						</Row>
						<Row>
							<Field
								name='margem_1'
								component={LabelAndInputNumber}
								label='Margem (faixa 1)' placeholder='Informe'
								cols='12 6 4 2'
								casas={2}
								readOnly={readOnly} />
							
							<Field
								name='margem_2'
								component={LabelAndInputNumber}
								label='Margem (faixa 2)' placeholder='Informe'
								cols='12 6 4 2'
								casas={2}
								readOnly={readOnly} />
							
							<Field
								name='margem_3'
								component={LabelAndInputNumber}
								label='Margem (faixa 3)' placeholder='Informe'
								cols='12 6 4 2'
								casas={2}
								readOnly={readOnly} />
							
							<Field
								name='margem_4'
								component={LabelAndInputNumber}
								label='Margem (faixa 4)' placeholder='Informe'
								cols='12 6 4 2'
								casas={2}
								readOnly={readOnly} />

						</Row>
						<Row>
							<Field
								name='prazo_1'
								component={LabelAndInputNumber}
								label='Prazo (faixa 1)' placeholder='Informe'
								cols='12 6 4 2'
								casas={2}
								readOnly={readOnly} />
							
							<Field
								name='prazo_2'
								component={LabelAndInputNumber}
								label='Prazo (faixa 2)' placeholder='Informe'
								cols='12 6 4 2'
								casas={2}
								readOnly={readOnly} />
							
							<Field
								name='prazo_3'
								component={LabelAndInputNumber}
								label='Prazo (faixa 3)' placeholder='Informe'
								cols='12 6 4 2'
								casas={2}
								readOnly={readOnly} />
							
							<Field
								name='prazo_4'
								component={LabelAndInputNumber}
								label='Prazo (faixa 4)' placeholder='Informe'
								cols='12 6 4 2'
								casas={2}
								readOnly={readOnly} />
			
						</Row>

						<Row>
							<Field
								name='inadimplencia_1'
								component={LabelAndInputNumber}
								label='Inadimplência (faixa 1)' placeholder='Informe'
								cols='12 6 4 2'
								casas={2}
								readOnly={readOnly} />
							
							<Field
								name='inadimplencia_2'
								component={LabelAndInputNumber}
								label='Inadimplência (faixa 2)' placeholder='Informe'
								cols='12 6 4 2'
								casas={2}
								readOnly={readOnly} />
							
							<Field
								name='inadimplencia_3'
								component={LabelAndInputNumber}
								label='Inadimplência (faixa 3)' placeholder='Informe'
								cols='12 6 4 2'
								casas={2}
								readOnly={readOnly} />
							
							<Field
								name='inadimplencia_4'
								component={LabelAndInputNumber}
								label='Inadimplência (faixa 4)' placeholder='Informe'
								cols='12 6 4 2'
								casas={2}
								readOnly={readOnly} />
			
						</Row>
						
						<Row>
							<Field
								name='quantidade_minima_fidelizacao'
								component={LabelAndInputNumber}
								label='Fidelização' placeholder='Informe'
								cols='12 6 4 2'
								casas={0}
								readOnly={readOnly}
								title='Quantidade mínima de fidelizações para Ganhar' />
						</Row> 

						<Row>
							
							<Field
								name='valor_ganho_cliente_novo'
								component={LabelAndInputNumber}
								label='Cliente Novo (R$)' placeholder='Informe'
								cols='12 6 4 2'
								casas={2}
								readOnly={readOnly} />
							
							<Field
								name='valor_ganho_cliente_retomada'
								component={LabelAndInputNumber}
								label='Cliente Retomada (R$)' placeholder='Informe'
								cols='12 6 4 2'
								casas={2}
								readOnly={readOnly} />
			
						</Row>
						<br />
						<Row>
							<Field
								name='ganha_ocorrencia_qualidade'
								component={LabelAndCheckbox}
								label='Ganha pela Qualidade' placeholder='Informe'
								cols='12 6 4 2'
								readOnly={readOnly}
								title='Não teve ocorrências de qualidade' />
							
						</Row>
						
					</ContentCardBody>
					<ContentCardFooter>
						<Row alignCenter>
							<Grid cols='6 6 4 3'>
								{this.props.excluir ? (
									<Button
										text='Excluir'
										submit type={'danger'}
										icon={'fa fa-trash'} />
								) : (
								<Button
									text='Salvar'
									submit
									type={'success'}
									icon={'fa fa-check'} />
								)}
							</Grid>
							<Grid cols='6 6 4 3'>
								<Button
									text='Voltar'
									type={'warning'}
									icon={'fa fa-chevron-left'}
									event={() => this.props.setModoTela('lista')} />
							</Grid>
						</Row>
					</ContentCardFooter>

				</Form>
			</ContentCard>
        )
    }


}

MetaHeitorSemestreForm = reduxForm({form: 'metaHeitorSemestreForm', destroyOnUnmount: false})(MetaHeitorSemestreForm);
const mapStateToProps = state => ({
	sessao: state.auth.metaHeitorSemestre,
	formularioValues: getFormValues('metaHeitorSemestreForm')(state),
	registro: state.metaHeitorSemestre.registro,
	listaUsuario: state.metaHeitorSemestre.listaUsuario.filter(item => item.administrador)
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, initForm }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(MetaHeitorSemestreForm);
