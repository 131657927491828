import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import FormatUtils from '../common/formatUtils/FormatUtils';
import DateFormat from '../common/dateFormat/DateFormat';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'META_GERENCIA_SEMESTRE_MODO_TELA',
        payload: {
			modo: modo,
			registro: registro
		}
    };
}

export function setAguardando(aguardando) {
    return {
        type: 'META_GERENCIA_SEMESTRE_SET_AGUARDANDO',
        payload: aguardando
    };
}


export function initForm(registro = {}) {
    return [
        initialize('metaGerenciaSemestreForm', registro)
    ];
}

export function getLista() {
    const request = axios.get(`${consts.API_URL}/metaGerenciaSemestre`);
    return {
        type: 'META_GERENCIA_SEMESTRE_LISTADO',
        payload: request
    };
}

export function getListaApuracao() {
    const request = axios.get(`${consts.API_URL}/metaGerenciaSemestre/apuracao`);
    return {
        type: 'META_GERENCIA_SEMESTRE_APURACAO_LISTADO',
        payload: request
    };
}

export function getListaApuracaoGeral() {
    const request = axios.get(`${consts.API_URL}/metaGerenciaSemestre/listarApuracaoGeral`);
    return {
        type: 'META_GERENCIA_SEMESTRE_APURACAO_GERAL_LISTADO',
        payload: request
    };
}

export function getListaApuracaoGeralDiario() {
    const request = axios.get(`${consts.API_URL}/metaGerenciaSemestre/listarApuracaoGeralDiario`);
    return {
        type: 'META_GERENCIA_SEMESTRE_APURACAO_GERAL_DIARIO_LISTADO',
        payload: request
    };
}

export function salvar(registro) {
    return (dispatch, getState) => {

		if (!registro.id) {
	        axios.post(`${consts.API_URL}/metaGerenciaSemestre`, {
                ...registro,
				volume_1: FormatUtils.formatarValorBanco(registro.volume_1 || 0, 2),
				volume_2: FormatUtils.formatarValorBanco(registro.volume_2 || 0, 2),
				volume_3: FormatUtils.formatarValorBanco(registro.volume_3 || 0, 2),
				volume_4: FormatUtils.formatarValorBanco(registro.volume_4 || 0, 2),
				margem_1: FormatUtils.formatarValorBanco(registro.margem_1 || 0, 2),
				margem_2: FormatUtils.formatarValorBanco(registro.margem_2 || 0, 2),
				margem_3: FormatUtils.formatarValorBanco(registro.margem_3 || 0, 2),
				margem_4: FormatUtils.formatarValorBanco(registro.margem_4 || 0, 2),
				prazo_1: FormatUtils.formatarValorBanco(registro.prazo_1 || 0, 2),
				prazo_2: FormatUtils.formatarValorBanco(registro.prazo_2 || 0, 2),
				prazo_3: FormatUtils.formatarValorBanco(registro.prazo_3 || 0, 2),
				prazo_4: FormatUtils.formatarValorBanco(registro.prazo_4 || 0, 2),
				inadimplencia_1: FormatUtils.formatarValorBanco(registro.inadimplencia_1 || 0, 2),
				inadimplencia_2: FormatUtils.formatarValorBanco(registro.inadimplencia_2 || 0, 2),
				inadimplencia_3: FormatUtils.formatarValorBanco(registro.inadimplencia_3 || 0, 2),
				inadimplencia_4: FormatUtils.formatarValorBanco(registro.inadimplencia_4 || 0, 2),
				ebitda_1: FormatUtils.formatarValorBanco(registro.ebitda_1 || 0, 2),
				ebitda_2: FormatUtils.formatarValorBanco(registro.ebitda_2 || 0, 2),
				ebitda_3: FormatUtils.formatarValorBanco(registro.ebitda_3 || 0, 2),
				ebitda_4: FormatUtils.formatarValorBanco(registro.ebitda_4 || 0, 2),
				ebitda_realizado_1: FormatUtils.formatarValorBanco(registro.ebitda_realizado_1 || 0, 2),
				ebitda_realizado_2: FormatUtils.formatarValorBanco(registro.ebitda_realizado_2 || 0, 2),
				ebitda_realizado_3: FormatUtils.formatarValorBanco(registro.ebitda_realizado_3 || 0, 2),
				ebitda_realizado_4: FormatUtils.formatarValorBanco(registro.ebitda_realizado_4 || 0, 2),
				ebitda_realizado_5: FormatUtils.formatarValorBanco(registro.ebitda_realizado_5 || 0, 2),
				ebitda_realizado_6: FormatUtils.formatarValorBanco(registro.ebitda_realizado_6 || 0, 2),
				faturamento_realizado_1: FormatUtils.formatarValorBanco(registro.faturamento_realizado_1 || 0, 2),
				faturamento_realizado_2: FormatUtils.formatarValorBanco(registro.faturamento_realizado_2 || 0, 2),
				faturamento_realizado_3: FormatUtils.formatarValorBanco(registro.faturamento_realizado_3 || 0, 2),
				faturamento_realizado_4: FormatUtils.formatarValorBanco(registro.faturamento_realizado_4 || 0, 2),
				faturamento_realizado_5: FormatUtils.formatarValorBanco(registro.faturamento_realizado_5 || 0, 2),
				faturamento_realizado_6: FormatUtils.formatarValorBanco(registro.faturamento_realizado_6 || 0, 2),
				id_usuario_inclusao: getState().auth.usuario.id,
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		} else {
			axios.put(`${consts.API_URL}/metaGerenciaSemestre`, {
                ...registro,
				volume_1: FormatUtils.formatarValorBanco(registro.volume_1 || 0, 2),
				volume_2: FormatUtils.formatarValorBanco(registro.volume_2 || 0, 2),
				volume_3: FormatUtils.formatarValorBanco(registro.volume_3 || 0, 2),
				volume_4: FormatUtils.formatarValorBanco(registro.volume_4 || 0, 2),
				margem_1: FormatUtils.formatarValorBanco(registro.margem_1 || 0, 2),
				margem_2: FormatUtils.formatarValorBanco(registro.margem_2 || 0, 2),
				margem_3: FormatUtils.formatarValorBanco(registro.margem_3 || 0, 2),
				margem_4: FormatUtils.formatarValorBanco(registro.margem_4 || 0, 2),
				prazo_1: FormatUtils.formatarValorBanco(registro.prazo_1 || 0, 2),
				prazo_2: FormatUtils.formatarValorBanco(registro.prazo_2 || 0, 2),
				prazo_3: FormatUtils.formatarValorBanco(registro.prazo_3 || 0, 2),
				prazo_4: FormatUtils.formatarValorBanco(registro.prazo_4 || 0, 2),
				inadimplencia_1: FormatUtils.formatarValorBanco(registro.inadimplencia_1 || 0, 2),
				inadimplencia_2: FormatUtils.formatarValorBanco(registro.inadimplencia_2 || 0, 2),
				inadimplencia_3: FormatUtils.formatarValorBanco(registro.inadimplencia_3 || 0, 2),
				inadimplencia_4: FormatUtils.formatarValorBanco(registro.inadimplencia_4 || 0, 2),
				ebitda_1: FormatUtils.formatarValorBanco(registro.ebitda_1 || 0, 2),
				ebitda_2: FormatUtils.formatarValorBanco(registro.ebitda_2 || 0, 2),
				ebitda_3: FormatUtils.formatarValorBanco(registro.ebitda_3 || 0, 2),
				ebitda_4: FormatUtils.formatarValorBanco(registro.ebitda_4 || 0, 2),
				ebitda_realizado_1: FormatUtils.formatarValorBanco(registro.ebitda_realizado_1 || 0, 2),
				ebitda_realizado_2: FormatUtils.formatarValorBanco(registro.ebitda_realizado_2 || 0, 2),
				ebitda_realizado_3: FormatUtils.formatarValorBanco(registro.ebitda_realizado_3 || 0, 2),
				ebitda_realizado_4: FormatUtils.formatarValorBanco(registro.ebitda_realizado_4 || 0, 2),
				ebitda_realizado_5: FormatUtils.formatarValorBanco(registro.ebitda_realizado_5 || 0, 2),
				ebitda_realizado_6: FormatUtils.formatarValorBanco(registro.ebitda_realizado_6 || 0, 2),
				faturamento_realizado_1: FormatUtils.formatarValorBanco(registro.faturamento_realizado_1 || 0, 2),
				faturamento_realizado_2: FormatUtils.formatarValorBanco(registro.faturamento_realizado_2 || 0, 2),
				faturamento_realizado_3: FormatUtils.formatarValorBanco(registro.faturamento_realizado_3 || 0, 2),
				faturamento_realizado_4: FormatUtils.formatarValorBanco(registro.faturamento_realizado_4 || 0, 2),
				faturamento_realizado_5: FormatUtils.formatarValorBanco(registro.faturamento_realizado_5 || 0, 2),
				faturamento_realizado_6: FormatUtils.formatarValorBanco(registro.faturamento_realizado_6 || 0, 2),
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}

export function excluir(registro) {
    return (dispatch, getState) => {
        axios.delete(`${consts.API_URL}/metaGerenciaSemestre?id=${registro.id}&id_usuario_alteracao=${getState().auth.usuario.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista());
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function getListaUsuario() {
    const request = axios.get(`${consts.API_URL}/usuario/listarSelect`);
    return {
        type: 'META_GERENCIA_SEMESTRE_USUARIO_SELECT_LISTADO',
        payload: request
    };
}