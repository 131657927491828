import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import Form from './metaManutencaoConfiguracaoForm';
import ReactExport from 'react-export-excel-fixed-xlsx';
import LabelAndInputMask from '../common/form/labelAndInputMask';

import { 
	setModoTela, initForm, salvar, excluir, 
	getLista, getListaUsuario, getListaVeiculo
} from './metaManutencaoConfiguracaoActions';
import FormatUtils from '../common/formatUtils/FormatUtils';
import DateFormat from '../common/dateFormat/DateFormat';
import Select from '../common/form/select';
import consts from '../consts';

class MetaManutencaoConfiguracao extends Component {

	state = {
		linhaSelecionada: null,
		pesquisar: ''
	}

    componentWillMount() {
        this.props.getLista();
		this.props.getListaUsuario();
		this.props.getListaVeiculo();
	}
	
	componentWillUnmount() {
	
	}

    render() {
        return (
            <div>
                <Content>
					{this.props.modoTela == 'cadastro' ? (
						<Form onSubmit={this.props.salvar} />
					) : null}
					{this.props.modoTela == 'exclusao' ? (
						<Form excluir onSubmit={this.props.excluir} />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
                </Content>
            </div>
        )
    }

	lista() {
	
		return (
			<ContentCard>
				<ContentCardHeader>
					<Row>
						<Grid cols='6 6 4 2'>
							<Button
								text='Adicionar'
								type={'success'}
								icon={'fa fa-plus'}
								event={() => {
									this.props.setModoTela('cadastro', {});
									this.props.initForm({});
								}} />
						</Grid>
					</Row>

				</ContentCardHeader>
				<ContentCardBody>

					<input className='form-control'
						 placeholder='Pesquisar'
						 type='text'
						 value={this.state.pesquisar}
						 onChange={e => {
							 this.setState({ ...this.state, pesquisar: e.target.value });
						 }} />
					<Table responsive>
						<THead>
							<Tr>
								<Th alignCenter>Ano</Th>
								<Th alignCenter>Trimestre</Th>
								<Th alignCenter>Usuário</Th>
								<Th></Th>
							</Tr>
						</THead>
						<TBody>
							{(this.props.lista).filter(item => {
								if (this.state.pesquisar
									&& !(`${DateFormat.formatarDataSqlParaTela(item.ano)}  ${item.trimestre || ''} ${item.nome_usuario || ''}`).toUpperCase().includes(this.state.pesquisar.toUpperCase())) {
									return false;
								}
								return true;
							}).map(item => (
								<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
									onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
									onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
									<Td alignCenter>{item.ano}</Td>
									<Td alignCenter>{item.trimestre}</Td>
									<Td alignCenter>{item.nome_usuario}</Td>
									<Td alignRight minWidth={100}>
										<ButtonTable
											type={'warning'}
											icon={'fas fa-pencil-alt'}
											visible={this.state.linhaSelecionada == item.id}
											event={() => {
												this.props.setModoTela('cadastro', {
													...item,
													percentual_reducao_custo_frota_faixa_1: FormatUtils.formatarValorTela(item.percentual_reducao_custo_frota_faixa_1, 2),
													percentual_reducao_custo_frota_faixa_2: FormatUtils.formatarValorTela(item.percentual_reducao_custo_frota_faixa_2, 2),
													custo_frota_ano_anterior: FormatUtils.formatarValorTela(item.custo_frota_ano_anterior, 2),
													media_produtividade_km: FormatUtils.formatarValorTela(item.media_produtividade_km, 0),
													percentual_feedback_motorista: FormatUtils.formatarValorTela(item.percentual_feedback_motorista, 2)
												});
												this.props.initForm({
													...item,
													percentual_reducao_custo_frota_faixa_1: FormatUtils.formatarValorTela(item.percentual_reducao_custo_frota_faixa_1, 2),
													percentual_reducao_custo_frota_faixa_2: FormatUtils.formatarValorTela(item.percentual_reducao_custo_frota_faixa_2, 2),
													custo_frota_ano_anterior: FormatUtils.formatarValorTela(item.custo_frota_ano_anterior, 2),
													media_produtividade_km: FormatUtils.formatarValorTela(item.media_produtividade_km, 0),
													percentual_feedback_motorista: FormatUtils.formatarValorTela(item.percentual_feedback_motorista, 2)
												});
											}} />

										<ButtonTable
											type={'danger'}
											icon={'fas fa-trash-alt'}
											visible={this.state.linhaSelecionada == item.id}
											event={() => {
												this.props.setModoTela('exclusao', {
													...item,
													percentual_reducao_custo_frota_faixa_1: FormatUtils.formatarValorTela(item.percentual_reducao_custo_frota_faixa_1, 2),
													percentual_reducao_custo_frota_faixa_2: FormatUtils.formatarValorTela(item.percentual_reducao_custo_frota_faixa_2, 2),
													custo_frota_ano_anterior: FormatUtils.formatarValorTela(item.custo_frota_ano_anterior, 2),
													media_produtividade_km: FormatUtils.formatarValorTela(item.media_produtividade_km, 0),
													percentual_feedback_motorista: FormatUtils.formatarValorTela(item.percentual_feedback_motorista, 2)
												});
												this.props.initForm({
													...item,
													percentual_reducao_custo_frota_faixa_1: FormatUtils.formatarValorTela(item.percentual_reducao_custo_frota_faixa_1, 2),
													percentual_reducao_custo_frota_faixa_2: FormatUtils.formatarValorTela(item.percentual_reducao_custo_frota_faixa_2, 2),
													custo_frota_ano_anterior: FormatUtils.formatarValorTela(item.custo_frota_ano_anterior, 2),
													media_produtividade_km: FormatUtils.formatarValorTela(item.media_produtividade_km, 0),
													percentual_feedback_motorista: FormatUtils.formatarValorTela(item.percentual_feedback_motorista, 2)
												});
											}} />
									</Td>
								</Tr>
							))}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}


}

const mapStateToProps = state => ({
	sessao: state.auth.metaManutencaoConfiguracao,
	modoTela: state.metaManutencaoConfiguracao.modoTela,
	lista: state.metaManutencaoConfiguracao.lista,
	listaUsuario: state.metaManutencaoConfiguracao.listaUsuario.filter(item => item.administrador)
});
const mapDispatchToProps = dispatch => bindActionCreators({ 
	setModoTela, initForm, salvar, excluir, 
	getLista, getListaUsuario, getListaVeiculo
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(MetaManutencaoConfiguracao);
