const INITIAL_STATE = {
	registro: {},
	modoTela: 'lista',
	aguardando: false,
	lista: [],
	listaLogistica: [],
	listaFinalizado: [],
	listaEntrega: [],
	listaEstoque: [],
	listaUnidade: [],
	listaPedidoMotorista: [],
	listaMotorista: [],
	listaVeiculo: [],
	listaCliente: [],
	listaEndereco: [],
	listaProduto: [],
	listaDistribuidora: [],
	listaPoliticaPreco: [],
	listaCustoPrazo: [],
	listaStatus: [
		{ id: 1, valor: 'Reabrir' },
		{ id: 4, valor: 'Aprovado' },
		{ id: 5, valor: 'Logística Parcial' },
		{ id: 6, valor: 'Logística Finalizada' },
		{ id: 7, valor: 'Finalizado Base' },
		{ id: 8, valor: 'Abastecido Base e Aguard. Faturar' }
	],
	dialogPedido: null,
	estoqueManualLancado: true,
	abastecidaLancada: true
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'PEDIDO_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};
		case 'PEDIDO_SET_AGUARDANDO':
			return { ...state,
				aguardando: action.payload
			};
		
		case 'PEDIDO_SET_DIALOG_SM':
			return {
				...state,
				dialogPedido: action.payload
			};

        case 'PEDIDO_LISTADO':
            return {
				...state,
				lista: action.payload.data
			};

		case 'PEDIDO_LOGISTICA_LISTADO':
            return {
				...state,
				listaLogistica: action.payload.data
			};

		case 'PEDIDO_FINALIZADO_LISTADO':
            return {
				...state,
				listaFinalizado: action.payload.data
			};

		case 'PEDIDO_ENTREGA_LISTADO':
            return {
				...state,
				listaEntrega: action.payload.data
			};

		case 'PEDIDO_ESTOQUE_LISTADO':
            return {
				...state,
				listaEstoque: action.payload.data
			};
					
		case 'PEDIDO_DISTRIBUIDORA_SELECT_LISTADO':
            return {
				...state,
				listaDistribuidora: action.payload.data
			};

		case 'PEDIDO_POLITICA_PRECO_SELECT_LISTADO':
            return {
				...state,
				listaPoliticaPreco: action.payload.data
			};
			
		case 'PEDIDO_CUSTO_PRAZO_SELECT_LISTADO':
            return {
				...state,
				listaCustoPrazo: action.payload.data
			};

		case 'PEDIDO_MOTORISTA_SELECT_LISTADO':
            return {
				...state,
				listaMotorista: action.payload.data
			};

		case 'PEDIDO_UNIDADE_SELECT_LISTADO':
            return {
				...state,
				listaUnidade: action.payload.data
			};

		case 'PEDIDO_VEICULO_SELECT_LISTADO':			
            return {
				...state,
				listaVeiculo: action.payload.data
			};

		case 'PEDIDO_CLIENTE_SELECT_LISTADO':
            return {
				...state,
				listaCliente: action.payload.data
			};

		case 'PEDIDO_ENDERECO_SELECT_LISTADO':
            return {
				...state,
				listaEndereco: action.payload.data
			};

		case 'PEDIDO_PRODUTO_SELECT_LISTADO':
            return {
				...state,
				listaProduto: action.payload.data
			};

		case 'PEDIDO_ESTOQUE_MANUAL_LANCADO':
			return {
				...state,
				estoqueManualLancado: action.payload
			};

		case 'PEDIDO_ABASTECIDA_LANCADA':
			return {
				...state,
				abastecidaLancada: action.payload
			};

		case 'PEDIDO_MOTORISTA_LISTADO':
			return {
				...state,
				listaPedidoMotorista: action.payload.data
			};

        default:
            return state;
    }
}
