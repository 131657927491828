import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import Select from '../common/form/select';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import NumberFormat from 'react-number-format';
import { setError } from '../common/toast/toast';
import readXlsxFile from 'read-excel-file/web-worker'

import { setModoTela, initForm, importarVenda, importarInadimplencia } from './metaVendaActions';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import FormatUtils from '../common/formatUtils/FormatUtils';
import imagemLogo from '../assets/images/logo.png';

class MetaVendaForm extends Component {

	state = {
		mes: new Date().getMonth() + 1
	}

	componentDidMount() {

		const fileSelectorVendas = document.createElement('input');
		fileSelectorVendas.setAttribute('type', 'file');
		fileSelectorVendas.addEventListener("change", async (data) => {

			let reader = new FileReader();
			let file = data && data.path && data.path[0] && data.path[0].files && data.path[0].files[0] ? data.path[0].files[0] : null;
			if (!file) {
				file = data && data.target && data.target.files && data.target.files[0] ? data.target.files[0] : null;
			}

			if (file) {

				if (!file.type.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
					|| (file.type.includes('application/excel') || file.type.includes('application/vnd.ms-excel'))) {
					setError('Selecione apenas (.XLSX)');
					return;
				}

				let tabela = [];
				await readXlsxFile(file, { sheet: 7 }).then((rows) => {
					tabela = rows;
				});

				this.props.importarVenda(tabela, {
					...this.props.formularioValues,
					meses: this.props.formularioValues.meses.map(mes => ({
						...mes,
						realizado_volume_a1: FormatUtils.formatarValorBanco(mes.realizado_volume_a1 || 0, 4),
						realizado_margem_a1: FormatUtils.formatarValorBanco(mes.realizado_margem_a1 || 0, 4),
						realizado_inadimplencia_a1: FormatUtils.formatarValorBanco(mes.realizado_inadimplencia_a1 || 0, 4),
						realizado_prazo_a1: FormatUtils.formatarValorBanco(mes.realizado_prazo_a1 || 0, 4),
						realizado_volume_a2: FormatUtils.formatarValorBanco(mes.realizado_volume_a2 || 0, 4),
						realizado_margem_a2: FormatUtils.formatarValorBanco(mes.realizado_margem_a2 || 0, 4),
						realizado_inadimplencia_a2: FormatUtils.formatarValorBanco(mes.realizado_inadimplencia_a2 || 0, 4),
						realizado_prazo_a2: FormatUtils.formatarValorBanco(mes.realizado_prazo_a2 || 0, 4),
						realizado_volume_a3: FormatUtils.formatarValorBanco(mes.realizado_volume_a3 || 0, 4),
						realizado_margem_a3: FormatUtils.formatarValorBanco(mes.realizado_margem_a3 || 0, 4),
						realizado_inadimplencia_a3: FormatUtils.formatarValorBanco(mes.realizado_inadimplencia_a3 || 0, 4),
						realizado_prazo_a3: FormatUtils.formatarValorBanco(mes.realizado_prazo_a3 || 0, 4),
						realizado_volume_a4: FormatUtils.formatarValorBanco(mes.realizado_volume_a4 || 0, 4),
						realizado_margem_a4: FormatUtils.formatarValorBanco(mes.realizado_margem_a4 || 0, 4),
						realizado_inadimplencia_a4: FormatUtils.formatarValorBanco(mes.realizado_inadimplencia_a4 || 0, 4),
						realizado_prazo_a4: FormatUtils.formatarValorBanco(mes.realizado_prazo_a4 || 0, 4)
					}))
				});

				reader.readAsDataURL(file);

			}
		});

		this.fileSelectorVendas = fileSelectorVendas;

		const fileSelectorInadimplencia = document.createElement('input');
		fileSelectorInadimplencia.setAttribute('type', 'file');
		fileSelectorInadimplencia.addEventListener("change", async (data) => {

			let reader = new FileReader();
			let file = data && data.path && data.path[0] && data.path[0].files && data.path[0].files[0] ? data.path[0].files[0] : null;
			if (!file) {
				file = data && data.target && data.target.files && data.target.files[0] ? data.target.files[0] : null;
			}

			if (file) {

				if (!file.type.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
					|| (file.type.includes('application/excel') || file.type.includes('application/vnd.ms-excel'))) {
					setError('Selecione apenas (.XLSX)');
					return;
				}

				let tabela = [];
				await readXlsxFile(file, { sheet: 1 }).then((rows) => {
					tabela = rows;
				});

				this.props.importarInadimplencia(tabela, {
					...this.props.formularioValues,
					meses: this.props.formularioValues.meses.map(mes => ({
						...mes,
						realizado_volume_a1: FormatUtils.formatarValorBanco(mes.realizado_volume_a1 || 0, 4),
						realizado_margem_a1: FormatUtils.formatarValorBanco(mes.realizado_margem_a1 || 0, 4),
						realizado_inadimplencia_a1: FormatUtils.formatarValorBanco(mes.realizado_inadimplencia_a1 || 0, 4),
						realizado_prazo_a1: FormatUtils.formatarValorBanco(mes.realizado_prazo_a1 || 0, 4),
						realizado_volume_a2: FormatUtils.formatarValorBanco(mes.realizado_volume_a2 || 0, 4),
						realizado_margem_a2: FormatUtils.formatarValorBanco(mes.realizado_margem_a2 || 0, 4),
						realizado_inadimplencia_a2: FormatUtils.formatarValorBanco(mes.realizado_inadimplencia_a2 || 0, 4),
						realizado_prazo_a2: FormatUtils.formatarValorBanco(mes.realizado_prazo_a2 || 0, 4),
						realizado_volume_a3: FormatUtils.formatarValorBanco(mes.realizado_volume_a3 || 0, 4),
						realizado_margem_a3: FormatUtils.formatarValorBanco(mes.realizado_margem_a3 || 0, 4),
						realizado_inadimplencia_a3: FormatUtils.formatarValorBanco(mes.realizado_inadimplencia_a3 || 0, 4),
						realizado_prazo_a3: FormatUtils.formatarValorBanco(mes.realizado_prazo_a3 || 0, 4),
						realizado_volume_a4: FormatUtils.formatarValorBanco(mes.realizado_volume_a4 || 0, 4),
						realizado_margem_a4: FormatUtils.formatarValorBanco(mes.realizado_margem_a4 || 0, 4),
						realizado_inadimplencia_a4: FormatUtils.formatarValorBanco(mes.realizado_inadimplencia_a4 || 0, 4),
						realizado_prazo_a4: FormatUtils.formatarValorBanco(mes.realizado_prazo_a4 || 0, 4)
					}))
				});

				reader.readAsDataURL(file);

			}
		});

		this.fileSelectorInadimplencia = fileSelectorInadimplencia;

	}

    componentWillMount() {
	}

	getSemana(data) {
		let d = new Date(data);
		let date = d.getDate();
		let day = d.getDay();

		return Math.ceil((date - 1 - day) / 7) < 0 ? 0 : Math.ceil((date - 1 - day) / 7);
	}

	getFaixa(valor) {
		let faixas = [
			{ inicio: 90 , fim: 95, valor: 15 },
			{ inicio: 95 , fim: 105, valor: 30 },
			{ inicio: 105, fim: 115, valor: 50 },
			{ inicio: 115, fim: 100000, valor: 60 }
		];

		let faixa = faixas.filter(item => (valor || 0) >= item.inicio && (valor || 0) < item.fim)[0];
		
		return faixa ? faixa.valor : 0;
	}

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

		let listaExecicio = [];
		for (let i = 2023; i <= new Date().getFullYear() + 1; i++) {
			listaExecicio.push({
				id: i,
				valor: i
			});		
		}

		let listaCompetencia = [];
		for (let i = 2023; i <= new Date().getFullYear() + 1; i++) {
			for (let j = 1; j <= 12; j++) {
				listaCompetencia.push({
					id: `${i}${String(j).padStart(2, '0')}`,
					valor: `${String(j).padStart(2, '0')}/${i}`
				});		
			}			
		}

		let semanas = [];
		for (let i = 1; i <= 5; i++) {
			semanas.push(i);
		}

		let dias = [];
		for (let i = 1; i <= 7; i++) {
			dias.push(i);
		}

		let dataMes = new Date(`${this.props.formularioValues.exercicio}/${String(this.state.mes).padStart(2, '0')}/01 12:00`);
		dataMes.setMonth(dataMes.getMonth() + 1);
		dataMes.setDate(dataMes.getDate() - 1);
		let diasMes = dataMes.getDate();
		let diasMesMediaDiaria = dataMes.getDate();
		let dataInicioMes = new Date(`${this.props.registro.exercicio}/${String(this.state.mes).padStart(2, '0')}/01 12:00`);
		while (this.state.mes == (dataInicioMes.getMonth() + 1)) {
			//Domingo - remove domingos
			if (dataInicioMes.getDay() == 0) {
				console.log(dataInicioMes);
				diasMesMediaDiaria--;
			}
			dataInicioMes.setDate(dataInicioMes.getDate() + 1);
		}

		let diaAtual = dataMes.getMonth() == new Date().getMonth() && dataMes.getFullYear() == new Date().getFullYear() ? new Date().getDate() : diasMes;
		
		// let volumeTotal = 0;
		// let volumeTotalA1 = 0;
		// let volumeTotalA2 = 0;
		// let volumeTotalA3 = 0;
		// let volumeTotalA4 = 0;
		// let mes = this.props.formularioValues.meses.filter(item => item.mes == this.state.mes)[0];
		// if (mes) {		
		// 	(mes.realizado_volume_semana_a1 || []).forEach(itemSemana => {
		// 		itemSemana.dias.forEach(itemDia => {					
		// 			volumeTotal += itemDia ? itemDia.volume : 0;				
		// 			volumeTotalA1 += itemDia ? itemDia.volume : 0;				
		// 		});
		// 	});
		// 	(mes.realizado_volume_semana_a2 || []).forEach(itemSemana => {
		// 		itemSemana.dias.forEach(itemDia => {
		// 			volumeTotal += itemDia ? itemDia.volume : 0;
		// 			volumeTotalA2 += itemDia ? itemDia.volume : 0;
		// 		});
		// 	});
		// 	(mes.realizado_volume_semana_a3 || []).forEach(itemSemana => {
		// 		itemSemana.dias.forEach(itemDia => {
		// 			volumeTotal += itemDia ? itemDia.volume : 0;
		// 			volumeTotalA3 += itemDia ? itemDia.volume : 0;
		// 		});
		// 	});
		// 	(mes.realizado_volume_semana_a4 || []).forEach(itemSemana => {
		// 		itemSemana.dias.forEach(itemDia => {
		// 			volumeTotal += itemDia ? itemDia.volume : 0;
		// 			volumeTotalA4 += itemDia ? itemDia.volume : 0;
		// 		});
		// 	});
		// }

		

		let acessoGeral = this.props.usuarioCarregado && (
			this.props.usuarioCarregado.id_perfil == 2
			|| this.props.usuarioCarregado.acesso_meta_motorista
			|| this.props.usuarioCarregado.id_perfil == 6 // Vendedor
		); //ADMINISTRADOR
		let acessoA1 = this.props.formularioValues.nome_a1 == this.props.usuarioCarregado.nome || acessoGeral;
		let acessoA2 = this.props.formularioValues.nome_a2 == this.props.usuarioCarregado.nome || acessoGeral;
		let acessoA3 = this.props.formularioValues.nome_a3 == this.props.usuarioCarregado.nome || acessoGeral;
		let acessoA4 = this.props.formularioValues.nome_a4 == this.props.usuarioCarregado.nome || acessoGeral;

		let acessoLancamento = this.props.usuarioCarregado && (this.props.usuarioCarregado.id == 57 || this.props.usuarioCarregado.id == 60); //Cíntia e Roger Augusto de Souza Santo

		let resultadoGlobalGeral = 0;
		// let resultadoGlobalMeta = 0;
		let resultadoIndividualA1 = 0
		let resultadoIndividualA2 = 0;
		let resultadoIndividualA3 = 0;
		let resultadoIndividualA4 = 0;

		let mediaDiaria = 0;
		let mediaDiariaA1 = 0;
		let mediaDiariaA2 = 0;
		let mediaDiariaA3 = 0;
		let mediaDiariaA4 = 0;

		let percentualGlobalInadimplencia = 0;
		let percentualInadimplenciaA1 = 0;
		let percentualInadimplenciaA2 = 0;
		let percentualInadimplenciaA3 = 0;
		let percentualInadimplenciaA4 = 0;

		let percentualVolumeA4 = 0;
		let percentualMargemA4 = 0;
		let percentualPrazoA4 = 0;

		let titleInadimplenciaA1 = '';
		let titleInadimplenciaA2 = '';
		let titleInadimplenciaA3 = '';
		let titleInadimplenciaA4 = '';

		this.props.formularioValues.meses.filter(item => item.mes == this.state.mes).forEach(item => {

			mediaDiaria = parseFloat(FormatUtils.formatarValorBanco(item.volume_total, 0)) / diasMesMediaDiaria;
			mediaDiariaA1 = parseFloat(FormatUtils.formatarValorBanco(item.volume_a1, 0)) / diasMesMediaDiaria;
			mediaDiariaA2 = parseFloat(FormatUtils.formatarValorBanco(item.volume_a2, 0)) / diasMesMediaDiaria;
			mediaDiariaA3 = parseFloat(FormatUtils.formatarValorBanco(item.volume_a3, 0)) / diasMesMediaDiaria;
			mediaDiariaA4 = parseFloat(FormatUtils.formatarValorBanco(item.volume_a4, 0)) / diasMesMediaDiaria;

			percentualGlobalInadimplencia = parseFloat(item.realizado_inadimplencia_global) > 0 ?
				(parseFloat(FormatUtils.formatarValorBanco(item.inadimplencia_global, 4)) * 100) / parseFloat(item.realizado_inadimplencia_global) : 0;
			percentualGlobalInadimplencia = parseInt(percentualGlobalInadimplencia < 85 ? 85 : (percentualGlobalInadimplencia > 115 ? 115 : percentualGlobalInadimplencia));

			/*Abril - PESOS
			- Volume: 30
			- Margem: 30
			- Inadimplência: 15
			- Prazo: 25*/

			if (this.state.mes <= 3) {
				resultadoGlobalGeral = parseInt(
					(parseInt(parseFloat(FormatUtils.formatarValorBanco(item.volume_total, 0)) > 0 ?
							(parseFloat(item.realizado_volume_total) * 100) / parseFloat(FormatUtils.formatarValorBanco(item.volume_total, 0)) : 0)
					+ parseInt(parseFloat(FormatUtils.formatarValorBanco(item.margem_global, 4)) > 0 ?
							(parseFloat(item.realizado_margem_global) * 100) / parseFloat(FormatUtils.formatarValorBanco(item.margem_global, 4)) : 0)
					+ percentualGlobalInadimplencia
					+ parseInt(parseFloat(item.realizado_prazo_global) > 0 ?
							(parseFloat(FormatUtils.formatarValorBanco(item.prazo_global, 4)) * 100) / parseFloat(item.realizado_prazo_global) : 0)
					) / 4
				);
			} else {
				resultadoGlobalGeral = parseInt(
					(parseInt((parseFloat(FormatUtils.formatarValorBanco(item.volume_total, 0)) > 0 ?
							(parseFloat(item.realizado_volume_total) * 100) / parseFloat(FormatUtils.formatarValorBanco(item.volume_total, 0)) : 0) * 0.30)
					+ parseInt((parseFloat(FormatUtils.formatarValorBanco(item.margem_global, 4)) > 0 ?
							(parseFloat(item.realizado_margem_global) * 100) / parseFloat(FormatUtils.formatarValorBanco(item.margem_global, 4)) : 0) * 0.30)
					+ parseInt(percentualGlobalInadimplencia * 0.15)
					+ parseInt((parseFloat(item.realizado_prazo_global) > 0 ?
							(parseFloat(FormatUtils.formatarValorBanco(item.prazo_global, 4)) * 100) / parseFloat(item.realizado_prazo_global) : 0) * 0.25)
					)
				);
			}

			// resultadoGlobalMeta = parseInt(
			// 	(parseInt(parseFloat(FormatUtils.formatarValorBanco(item.volume_total, 0)) > 0 ?
			// 			(parseFloat(item.realizado_volume_total_meta) * 100) / parseFloat(FormatUtils.formatarValorBanco(item.volume_total, 0)) : 0)
			// 	+ parseInt(parseFloat(FormatUtils.formatarValorBanco(item.margem_global, 4)) > 0 ?
			// 			(parseFloat(item.realizado_margem_global_meta) * 100) / parseFloat(FormatUtils.formatarValorBanco(item.margem_global, 4)) : 0)
			// 	+ percentualGlobalInadimplencia
			// 	+ parseInt(parseFloat(item.realizado_prazo_global_meta) > 0 ?
			// 			(parseFloat(FormatUtils.formatarValorBanco(item.prazo_global, 4)) * 100) / parseFloat(item.realizado_prazo_global_meta) : 0)
			// 	) / 3
			// );
			
			percentualInadimplenciaA1 = parseFloat(FormatUtils.formatarValorBanco(item.realizado_inadimplencia_a1, 4)) > 0 ? (
				parseFloat(FormatUtils.formatarValorBanco(item.inadimplencia_a1, 4)) > parseFloat(FormatUtils.formatarValorBanco(item.realizado_inadimplencia_a1, 4)) ? (
					(((parseFloat(FormatUtils.formatarValorBanco(item.inadimplencia_a1, 4)) + parseFloat(FormatUtils.formatarValorBanco(item.realizado_inadimplencia_a1, 4))) / parseFloat(FormatUtils.formatarValorBanco(item.inadimplencia_a1, 4))) + 1) * 100
				) : (
					(parseFloat(FormatUtils.formatarValorBanco(item.inadimplencia_a1, 4)) / parseFloat(FormatUtils.formatarValorBanco(item.realizado_inadimplencia_a1, 4))) * 100
				)
			) : 115;
			percentualInadimplenciaA1 = parseInt(percentualInadimplenciaA1 < 85 ? 85 : (percentualInadimplenciaA1 > 115 ? 115 : percentualInadimplenciaA1));

			if (this.state.mes <= 3) {
				resultadoIndividualA1 = parseInt(
					(parseInt(parseFloat(FormatUtils.formatarValorBanco(item.volume_a1, 0)) > 0 ?
							(parseFloat(item.realizado_volume_a1) * 100) / parseFloat(FormatUtils.formatarValorBanco(item.volume_a1, 0)) : 0)
					+ parseInt(parseFloat(FormatUtils.formatarValorBanco(item.margem_a1, 4)) > 0 ?
							(parseFloat(FormatUtils.formatarValorBanco(item.realizado_margem_a1, 4)) * 100) / parseFloat(FormatUtils.formatarValorBanco(item.margem_a1, 4)) : 0)
					+ percentualInadimplenciaA1
					+ parseInt(parseFloat(item.realizado_prazo_a1) > 0 ?
							(parseFloat(FormatUtils.formatarValorBanco(item.prazo_a1, 4)) * 100) / parseFloat(item.realizado_prazo_a1) : 0)
					) / 4
				);
			} else {
				resultadoIndividualA1 = parseInt(
					(parseInt((parseFloat(FormatUtils.formatarValorBanco(item.volume_a1, 0)) > 0 ?
							(parseFloat(item.realizado_volume_a1) * 100) / parseFloat(FormatUtils.formatarValorBanco(item.volume_a1, 0)) : 0) * 0.3)
					+ parseInt((parseFloat(FormatUtils.formatarValorBanco(item.margem_a1, 4)) > 0 ?
							(parseFloat(FormatUtils.formatarValorBanco(item.realizado_margem_a1, 4)) * 100) / parseFloat(FormatUtils.formatarValorBanco(item.margem_a1, 4)) : 0) * 0.3)
					+ parseInt(percentualInadimplenciaA1 * 0.15)
					+ parseInt((parseFloat(item.realizado_prazo_a1) > 0 ?
							(parseFloat(FormatUtils.formatarValorBanco(item.prazo_a1, 4)) * 100) / parseFloat(item.realizado_prazo_a1) : 0) * 0.25)
					)
				);
			}

			percentualInadimplenciaA2 = parseFloat(FormatUtils.formatarValorBanco(item.realizado_inadimplencia_a2, 4)) > 0 ? (
				parseFloat(FormatUtils.formatarValorBanco(item.inadimplencia_a2, 4)) > parseFloat(FormatUtils.formatarValorBanco(item.realizado_inadimplencia_a2, 4)) ? (
					(((parseFloat(FormatUtils.formatarValorBanco(item.inadimplencia_a2, 4)) + parseFloat(FormatUtils.formatarValorBanco(item.realizado_inadimplencia_a2, 4))) / parseFloat(FormatUtils.formatarValorBanco(item.inadimplencia_a2, 4))) + 1) * 100
				) : (
					(parseFloat(FormatUtils.formatarValorBanco(item.inadimplencia_a2, 4)) / parseFloat(FormatUtils.formatarValorBanco(item.realizado_inadimplencia_a2, 4))) * 100
				)
			) : 115;
			percentualInadimplenciaA2 = parseInt(percentualInadimplenciaA2 < 85 ? 85 : (percentualInadimplenciaA2 > 115 ? 115 : percentualInadimplenciaA2));

			if (this.state.mes <= 3) {
				resultadoIndividualA2 = parseInt(
					(parseInt(parseFloat(FormatUtils.formatarValorBanco(item.volume_a2, 0)) > 0 ?
							(parseFloat(item.realizado_volume_a2) * 100) / parseFloat(FormatUtils.formatarValorBanco(item.volume_a2, 0)) : 0)
					+ parseInt(parseFloat(FormatUtils.formatarValorBanco(item.margem_a2, 4)) > 0 ?
							(parseFloat(FormatUtils.formatarValorBanco(item.realizado_margem_a2, 4)) * 100) / parseFloat(FormatUtils.formatarValorBanco(item.margem_a2, 4)) : 0)
					+ percentualInadimplenciaA2
					+ parseInt(parseFloat(item.realizado_prazo_a2) > 0 ?
							(parseFloat(FormatUtils.formatarValorBanco(item.prazo_a2, 4)) * 100) / parseFloat(item.realizado_prazo_a2) : 0)
					) / 4
				);
			} else {
				resultadoIndividualA2 = parseInt(
					(parseInt((parseFloat(FormatUtils.formatarValorBanco(item.volume_a2, 0)) > 0 ?
							(parseFloat(item.realizado_volume_a2) * 100) / parseFloat(FormatUtils.formatarValorBanco(item.volume_a2, 0)) : 0) * 0.3)
					+ parseInt((parseFloat(FormatUtils.formatarValorBanco(item.margem_a2, 4)) > 0 ?
							(parseFloat(FormatUtils.formatarValorBanco(item.realizado_margem_a2, 4)) * 100) / parseFloat(FormatUtils.formatarValorBanco(item.margem_a2, 4)) : 0) * 0.3)
					+ parseInt(percentualInadimplenciaA2 * 0.15)
					+ parseInt((parseFloat(item.realizado_prazo_a2) > 0 ?
							(parseFloat(FormatUtils.formatarValorBanco(item.prazo_a2, 4)) * 100) / parseFloat(item.realizado_prazo_a2) : 0) * 0.25)
					)
				);

			}

			percentualInadimplenciaA3 = parseFloat(FormatUtils.formatarValorBanco(item.realizado_inadimplencia_a3, 4)) > 0 ? (
				parseFloat(FormatUtils.formatarValorBanco(item.inadimplencia_a3, 4)) > parseFloat(FormatUtils.formatarValorBanco(item.realizado_inadimplencia_a3, 4)) ? (
					(((parseFloat(FormatUtils.formatarValorBanco(item.inadimplencia_a3, 4)) + parseFloat(FormatUtils.formatarValorBanco(item.realizado_inadimplencia_a3, 4))) / parseFloat(FormatUtils.formatarValorBanco(item.inadimplencia_a3, 4))) + 1) * 100
				) : (
					(parseFloat(FormatUtils.formatarValorBanco(item.inadimplencia_a3, 4)) / parseFloat(FormatUtils.formatarValorBanco(item.realizado_inadimplencia_a3, 4))) * 100
				)
			) : 115;
			percentualInadimplenciaA3 = parseInt(percentualInadimplenciaA3 < 85 ? 85 : (percentualInadimplenciaA3 > 115 ? 115 : percentualInadimplenciaA3));

			if (this.state.mes <= 3) {
				resultadoIndividualA3 = parseInt(
					(parseInt(parseFloat(FormatUtils.formatarValorBanco(item.volume_a3, 0)) > 0 ?
							(parseFloat(item.realizado_volume_a3) * 100) / parseFloat(FormatUtils.formatarValorBanco(item.volume_a3, 0)) : 0)
					+ parseInt(parseFloat(FormatUtils.formatarValorBanco(item.margem_a3, 4)) > 0 ?
							(parseFloat(FormatUtils.formatarValorBanco(item.realizado_margem_a3, 4)) * 100) / parseFloat(FormatUtils.formatarValorBanco(item.margem_a3, 4)) : 0)
					+ percentualInadimplenciaA3
					+ parseInt(parseFloat(item.realizado_prazo_a3) > 0 ?
							(parseFloat(FormatUtils.formatarValorBanco(item.prazo_a3, 4)) * 100) / parseFloat(item.realizado_prazo_a3) : 0)
					) / 4
				);
			} else {
				resultadoIndividualA3 = parseInt(
					(parseInt((parseFloat(FormatUtils.formatarValorBanco(item.volume_a3, 0)) > 0 ?
							(parseFloat(item.realizado_volume_a3) * 100) / parseFloat(FormatUtils.formatarValorBanco(item.volume_a3, 0)) : 0) * 0.3)
					+ parseInt((parseFloat(FormatUtils.formatarValorBanco(item.margem_a3, 4)) > 0 ?
							(parseFloat(FormatUtils.formatarValorBanco(item.realizado_margem_a3, 4)) * 100) / parseFloat(FormatUtils.formatarValorBanco(item.margem_a3, 4)) : 0) * 0.3)
					+ parseInt(percentualInadimplenciaA3 * 0.15)
					+ parseInt((parseFloat(item.realizado_prazo_a3) > 0 ?
							(parseFloat(FormatUtils.formatarValorBanco(item.prazo_a3, 4)) * 100) / parseFloat(item.realizado_prazo_a3) : 0) * 0.25)
					)
				);
			}

			percentualInadimplenciaA4 = parseFloat(FormatUtils.formatarValorBanco(item.realizado_inadimplencia_a4, 4)) > 0 ? (
				parseFloat(FormatUtils.formatarValorBanco(item.inadimplencia_a4, 4)) > parseFloat(FormatUtils.formatarValorBanco(item.realizado_inadimplencia_a4, 4)) ? (
					(((parseFloat(FormatUtils.formatarValorBanco(item.inadimplencia_a4, 4)) + parseFloat(FormatUtils.formatarValorBanco(item.realizado_inadimplencia_a4, 4))) / parseFloat(FormatUtils.formatarValorBanco(item.inadimplencia_a4, 4))) + 1) * 100
				) : (
					(parseFloat(FormatUtils.formatarValorBanco(item.inadimplencia_a4, 4)) / parseFloat(FormatUtils.formatarValorBanco(item.realizado_inadimplencia_a4, 4))) * 100
				)
			) : 115;
			percentualInadimplenciaA4 = parseInt(percentualInadimplenciaA4 < 85 ? 85 : (percentualInadimplenciaA4 > 115 ? 115 : percentualInadimplenciaA4));

			// if (this.state.mes <= 3) {
			// 	resultadoIndividualA4 = parseInt(
			// 		(parseInt(parseFloat(FormatUtils.formatarValorBanco(item.volume_a4, 0)) > 0 ?
			// 				(parseFloat(item.realizado_volume_a4) * 100) / parseFloat(FormatUtils.formatarValorBanco(item.volume_a4, 0)) : 0)
			// 		+ parseInt(parseFloat(FormatUtils.formatarValorBanco(item.margem_a4, 4)) > 0 ?
			// 				(parseFloat(FormatUtils.formatarValorBanco(item.realizado_margem_a4, 4)) * 100) / parseFloat(FormatUtils.formatarValorBanco(item.margem_a4, 4)) : 0)
			// 		+ percentualInadimplenciaA4
			// 		+ parseInt(parseFloat(item.realizado_prazo_a4) > 0 ?
			// 				(parseFloat(FormatUtils.formatarValorBanco(item.prazo_a4, 4)) * 100) / parseFloat(item.realizado_prazo_a4) : 0)
			// 		) / 4
			// 	);
			// } else {
			// 	resultadoIndividualA4 = parseInt(
			// 		(parseInt((parseFloat(FormatUtils.formatarValorBanco(item.volume_a4, 0)) > 0 ?
			// 				(parseFloat(item.realizado_volume_a4) * 100) / parseFloat(FormatUtils.formatarValorBanco(item.volume_a4, 0)) : 0) * 0.3)
			// 		+ parseInt((parseFloat(FormatUtils.formatarValorBanco(item.margem_a4, 4)) > 0 ?
			// 				(parseFloat(FormatUtils.formatarValorBanco(item.realizado_margem_a4, 4)) * 100) / parseFloat(FormatUtils.formatarValorBanco(item.margem_a4, 4)) : 0) * 0.3)
			// 		+ parseInt(percentualInadimplenciaA4 * 0.15)
			// 		+ parseInt((parseFloat(item.realizado_prazo_a4) > 0 ?
			// 				(parseFloat(FormatUtils.formatarValorBanco(item.prazo_a4, 4)) * 100) / parseFloat(item.realizado_prazo_a4) : 0) * 0.25)
			// 		)
			// 	);
			// }

			percentualVolumeA4 = (parseFloat(FormatUtils.formatarValorBanco(item.volume_a4, 0)) > 0 ?
				(parseFloat(item.realizado_volume_a4) * 100) / parseFloat(FormatUtils.formatarValorBanco(item.volume_a4, 0)) : 0);
			percentualMargemA4 = (parseFloat(FormatUtils.formatarValorBanco(item.margem_a4, 4)) > 0 ?
				(parseFloat(FormatUtils.formatarValorBanco(item.realizado_margem_a4, 4)) * 100) / parseFloat(FormatUtils.formatarValorBanco(item.margem_a4, 4)) : 0);
			percentualPrazoA4 = (parseFloat(item.realizado_prazo_a4) > 0 ?
				(parseFloat(FormatUtils.formatarValorBanco(item.prazo_a4, 4)) * 100) / parseFloat(item.realizado_prazo_a4) : 0);


			if (this.state.mes <= 3) {
				resultadoIndividualA4 = parseInt(
					(percentualVolumeA4
					+ percentualMargemA4
					+ percentualInadimplenciaA4
					+ percentualPrazoA4
					) / 4
				);
			} else {

				percentualVolumeA4 = percentualVolumeA4 < 90 ? 0 : percentualVolumeA4;
				percentualMargemA4 = percentualMargemA4 < 90 ? 0 : percentualMargemA4;
				percentualInadimplenciaA4 = percentualInadimplenciaA4 < 90 ? 0 : percentualInadimplenciaA4;
				percentualPrazoA4 = percentualPrazoA4 < 90 ? 0 : percentualPrazoA4;
				
				resultadoIndividualA4 = parseInt(
					(percentualVolumeA4 * 0.3)
					+ (percentualMargemA4 * 0.3)
					+ (percentualInadimplenciaA4 * 0.1)
					+ (percentualPrazoA4 * 0.3)
				);
			}



			titleInadimplenciaA1 = (item.realizado_inadimplencia_clientes_a1 || []).map(cli => `${cli.id_petroshow} - ${cli.razao_social}: R$ ${FormatUtils.formatarValorTela(cli.valor, 2)}`).join('\n');
			titleInadimplenciaA2 = (item.realizado_inadimplencia_clientes_a2 || []).map(cli => `${cli.id_petroshow} - ${cli.razao_social}: R$ ${FormatUtils.formatarValorTela(cli.valor, 2)}`).join('\n');
			titleInadimplenciaA3 = (item.realizado_inadimplencia_clientes_a3 || []).map(cli => `${cli.id_petroshow} - ${cli.razao_social}: R$ ${FormatUtils.formatarValorTela(cli.valor, 2)}`).join('\n');
			titleInadimplenciaA4 =  (item.realizado_inadimplencia_clientes_a4 || []).map(cli => `${cli.id_petroshow} - ${cli.razao_social}: R$ ${FormatUtils.formatarValorTela(cli.valor, 2)}`).join('\n');
		});

        return (
			<ContentCard>
				<ContentCardHeader>
					{/*(acessoGeral || acessoLancamento) &&
					<Row>
						<Grid cols='6 6 3 2'>
							<Button
								text='Importar Vendas'
								type={'primary'}
								icon={'fa fa-chevron-left'}
								event={() => {
									// if (window.confirm('Remova a linha do cabeçalho do arquivo Excel (primeira linha) para depois importar')) {
										 this.fileSelectorVendas.click()
									// }
								}} />
						</Grid>
						<Grid cols='6 6 3 2'>
							{this.props.listaCliente.length > 0 && 
							<Button
								text='Importar Inadimplência'
								type={'primary'}
								icon={'fa fa-chevron-left'}
								event={() => {
									if (window.confirm('Remova a linha do cabeçalho do arquivo Excel para depois importar')) {
										 this.fileSelectorInadimplencia.click()
									}
								}} />}
						</Grid>
					</Row>*/}
				</ContentCardHeader>
				<Form event={this.props.handleSubmit}>
					<ContentCardBody>

						<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
							<img src={imagemLogo} style={{ width: 250, height: 79 }}/>

							<div style={{ display: 'flex', height: 79, flexDirection: 'column', marginLeft:24, justifyContent: 'center', paddingTop: 25 }}>
								<h4 style={{ fontSize: 18, fontWeight: 'bold', color: '#194682' }}>META GERAL - TRR VALE DA SERRA</h4>
								<h5 style={{ fontSize: 14, fontWeight: 'bold', color: '#194682' }}>NO VALE DO ARAGUAIA, COMBUSTÍVEL É VALE DA SERRA!</h5>
							</div>
						</div>
						

						<Row>

							<Field
								name='exercicio'
								component={Select}
								options={listaExecicio}
								label='Exercício'
								cols='12 12 3 2'
								placeholder='Selecione o exercício'
								readOnly={'readOnly'} />
							
							<Field
								name='gerente'
								component={LabelAndInput}
								options={listaExecicio}
								label='Gerente'
								cols='12 12 9 10'
								placeholder='Selecione o gerente'
								readOnly={'readOnly'} />

							{acessoA1 &&
							<Field
								name='nome_a1'
								component={LabelAndInput}
								label='Nome A1' placeholder='Informe o nome'
								cols='12 6 3 3'
								readOnly={'readOnly'} />}

							{acessoA2 &&
							<Field
								name='nome_a2'
								component={LabelAndInput}
								label='Nome A2' placeholder='Informe o nome'
								cols='12 6 3 3'
								readOnly={'readOnly'} />}
							
							{acessoA3 &&
							<Field
								name='nome_a3'
								component={LabelAndInput}
								label='Nome A3' placeholder='Informe o nome'
								cols='12 6 3 3'
								readOnly={'readOnly'} />}
							
							{(false && acessoA4) &&
							<Field
								name='nome_a4'
								component={LabelAndInput}
								label='Nome A4' placeholder='Informe o nome'
								cols='12 6 3 3'
								readOnly={'readOnly'} />}
						</Row>

						<Row>
							{this.props.formularioValues.meses.map(item => (
								<Grid cols='4 2 1 1' key={item.mes}>
									<button 
										type='button'
										style={{ width: '100%' }}
										class={`btn btn-${this.state.mes == item.mes ? 'info' : 'default'}`}
										onClick={() => this.setState({ ...this.state, mes: item.mes })}>
										{item.mes}
									</button>
								</Grid>								
							))}
						</Row>

						<br />

						<Row>
							<Table responsive>
								<THead>
									<Tr>
										<Th colspan={2}></Th>
										<Th alignCenter>Meta</Th>
										<Th alignCenter>Realizado</Th>
										<Th alignCenter>Projetado</Th>
										<Th alignCenter>Resultado</Th>
										<Th alignCenter colspan={7}></Th>
									</Tr>
								</THead>
								<TBody>
									{acessoGeral &&
									<>
										<Tr backgroundColor='#dfe2f5'>
											<Td colspan={2}>Volume Total</Td>
											{this.props.formularioValues.meses.filter(item => item.mes == this.state.mes).map(item => (
												<Td key={item.mes} alignCenter>
													<NumberFormat
														style={{ textAlign: 'center' }}
														className='form-control'
														readOnly={'readOnly'}
														decimalSeparator={","} 
														thousandSeparator={'.'}
														decimalScale={0} 
														fixedDecimalScale={0} 
														value={item.volume_total}
														onChange={data => {
															
														}} />
												</Td>
											))}
											{this.props.formularioValues.meses.filter(item => item.mes == this.state.mes).map(item => (
												<Td key={item.mes} alignCenter>
													<NumberFormat
														style={{ textAlign: 'center' }}
														className='form-control'
														readOnly={'readOnly'}
														decimalSeparator={","} 
														thousandSeparator={'.'}
														decimalScale={0} 
														fixedDecimalScale={0} 
														value={item.realizado_volume_total}
														onChange={data => {
															
														}} />
												</Td>
											))}
											{this.props.formularioValues.meses.filter(item => item.mes == this.state.mes).map(item => (
												<Td key={item.mes} alignCenter>
													<NumberFormat
														style={{ textAlign: 'center' }}
														className='form-control'
														readOnly={'readOnly'}
														decimalSeparator={","} 
														thousandSeparator={'.'}
														decimalScale={0} 
														fixedDecimalScale={0} 
														value={
															parseInt((parseFloat(item.realizado_volume_total) * diasMes) / diaAtual)
														}
														onChange={data => {
															
														}} />
												</Td>
											))}
											{this.props.formularioValues.meses.filter(item => item.mes == this.state.mes).map(item => (
												<Td key={item.mes} alignCenter>
													<LabelAndInput
														textAlign='center'
														className='form-control'
														readOnly={'readOnly'} 
														value={`${parseInt(
															parseFloat(FormatUtils.formatarValorBanco(item.volume_total, 0)) > 0 ?
																(parseFloat((parseFloat(item.realizado_volume_total) * diasMes) / diaAtual) * 100) 
																/ parseFloat(FormatUtils.formatarValorBanco(item.volume_total, 0))
															: 0
														)}%`}
														onChange={data => {
															
														}} />
												</Td>
											))}
											<Td rowspan={5}>
												<Table responsive>
													<TBody>
														<Tr>
															<Td colspan={2} bold>Meta Diária</Td>
															<Td colspan={2} alignCenter>
																<NumberFormat
																	style={{ textAlign: 'center' }}
																	className='form-control'
																	readOnly={'readOnly'}
																	decimalSeparator={","} 
																	thousandSeparator={'.'}
																	decimalScale={0} 
																	fixedDecimalScale={0} 
																	value={FormatUtils.formatarValorTela(mediaDiaria, 0)}
																	onChange={data => {
																			
																}} />
															</Td>
															<Td alignCenter></Td>
															<Td alignCenter></Td>
															<Td alignCenter></Td>
															<Td alignCenter></Td>
															<Td alignCenter></Td>
														</Tr>
														<Tr>
															<Th alignCenter>Semana</Th>
															<Th alignCenter>Dom</Th>
															<Th alignCenter>Seg</Th>
															<Th alignCenter>Ter</Th>
															<Th alignCenter>Qua</Th>
															<Th alignCenter>Qui</Th>
															<Th alignCenter>Sex</Th>
															<Th alignCenter>Sab</Th>
															<Th alignCenter>Saldo</Th>
														</Tr>

														{semanas.map(semana => {

															let metaTotalSemanal = 0;
															for (let dia = 1; dia <= diasMes; dia++) {
																//Se está na semana e não é domingo
																if (this.getSemana(`${this.props.formularioValues.exercicio}/${this.state.mes}/${dia}`) + 1 == semana
																	&& new Date(`${this.props.formularioValues.exercicio}/${this.state.mes}/${dia} 12:00`).getDay() != 0) {
																	metaTotalSemanal += mediaDiaria;
																}
																															
															}

															let realizadoTotalSemanal = 0;

															return (
																<Tr key={semana}>
																	<Th alignCenter>{semana}</Th>
																	{dias.map(dia => {
																		let volume = 0;
																		let mes = this.props.formularioValues.meses.filter(item => item.mes == this.state.mes)[0];
																		if (mes) {																	
																			let itemSemana = (mes.realizado_volume_semana_a1 || []).filter(sem => sem.semana == semana - 1)[0];
																			if (itemSemana) {
																				let itemDia = itemSemana.dias.filter(d => d.dia == dia - 1)[0];
																				volume += itemDia ? itemDia.volume : 0;
																			}
																		
																			itemSemana = (mes.realizado_volume_semana_a2 || []).filter(sem => sem.semana == semana - 1)[0];
																			if (itemSemana) {
																				let itemDia = itemSemana.dias.filter(d => d.dia == dia - 1)[0];
																				volume += itemDia ? itemDia.volume : 0;
																			}
																		
																			itemSemana = (mes.realizado_volume_semana_a3 || []).filter(sem => sem.semana == semana - 1)[0];
																			if (itemSemana) {
																				let itemDia = itemSemana.dias.filter(d => d.dia == dia - 1)[0];
																				volume += itemDia ? itemDia.volume : 0;
																			}

																			itemSemana = (mes.realizado_volume_semana_a4 || []).filter(sem => sem.semana == semana - 1)[0];
																			if (itemSemana) {
																				let itemDia = itemSemana.dias.filter(d => d.dia == dia - 1)[0];
																				volume += itemDia ? itemDia.volume : 0;
																			}
																		}

																		realizadoTotalSemanal += volume;

																		return (
																			<Td key={`${semana}-${dia}`} alignCenter>
																				<NumberFormat
																					style={{ 
																						textAlign: 'center', 
																						color: volume == 0 ? null : (volume < mediaDiaria ? '#fff' : '#fff'),
																						backgroundColor: volume == 0 ? null : (volume < mediaDiaria ? '#de3131' : '#22ba43')
																					}}
																					className='form-control'
																					readOnly={'readOnly'}
																					decimalSeparator={","} 
																					thousandSeparator={'.'}
																					decimalScale={0} 
																					fixedDecimalScale={0} 
																					value={volume}
																					mediaDiaria
																					onChange={data => {
				
																					}} />
																			</Td>
																		);
																	})}
																	<Td alignCenter>
																		<NumberFormat
																			style={{ 
																				textAlign: 'center', 
																				color: (realizadoTotalSemanal - metaTotalSemanal) < 0 ? '#fff' : '#fff',
																				backgroundColor: (realizadoTotalSemanal - metaTotalSemanal) < 0 ? '#de3131' : '#22ba43'
																			}}
																			className='form-control'
																			readOnly={'readOnly'}
																			decimalSeparator={","} 
																			thousandSeparator={'.'}
																			decimalScale={0} 
																			fixedDecimalScale={0} 
																			value={FormatUtils.formatarValorTela(realizadoTotalSemanal - metaTotalSemanal, 0)}
																			onChange={data => {
																					
																			}} />
																	</Td>
																</Tr>
															)
														})}
													</TBody>
												</Table>
											</Td>
										</Tr>
										<Tr backgroundColor='#dfe2f5'>
											<Td colspan={2}>Margem Global</Td>
											{this.props.formularioValues.meses.filter(item => item.mes == this.state.mes).map(item => (
												<Td key={item.mes} alignCenter>
													<NumberFormat
														style={{ textAlign: 'center' }}
														className='form-control'
														readOnly={'readOnly'}
														decimalSeparator={","} 
														thousandSeparator={'.'}
														decimalScale={3} 
														fixedDecimalScale={3} 
														value={item.margem_global}
														onChange={data => {
															
														}} />
												</Td>
											))}
											{this.props.formularioValues.meses.filter(item => item.mes == this.state.mes).map(item => (
												<Td key={item.mes} alignCenter>
													<NumberFormat
														style={{ textAlign: 'center' }}
														className='form-control'
														readOnly={'readOnly'}
														decimalSeparator={","} 
														thousandSeparator={'.'}
														decimalScale={3} 
														fixedDecimalScale={3} 
														value={item.realizado_margem_global}
														onChange={data => {
															
														}} />
												</Td>
											))}
											{this.props.formularioValues.meses.filter(item => item.mes == this.state.mes).map(item => (
												<Td key={item.mes} alignCenter>
													<NumberFormat
														style={{ textAlign: 'center' }}
														className='form-control'
														readOnly={'readOnly'}
														decimalSeparator={","} 
														thousandSeparator={'.'}
														decimalScale={3} 
														fixedDecimalScale={3} 
														value={item.realizado_margem_global}
														onChange={data => {
															
														}} />
												</Td>
											))}
											{this.props.formularioValues.meses.filter(item => item.mes == this.state.mes).map(item => (
												<Td key={item.mes} alignCenter>
													<LabelAndInput
														textAlign='center'
														className='form-control'
														readOnly={'readOnly'} 
														value={`${parseInt(
															parseFloat(FormatUtils.formatarValorBanco(item.margem_global, 4)) > 0 ?
																(parseFloat(item.realizado_margem_global) * 100) 
																/ parseFloat(FormatUtils.formatarValorBanco(item.margem_global, 4))
															: 0
														)}%`}
														onChange={data => {
															
														}} />
												</Td>
											))}
										</Tr>
										<Tr backgroundColor='#dfe2f5'>
											<Td colspan={2}>Inadimplência Global</Td>
											{this.props.formularioValues.meses.filter(item => item.mes == this.state.mes).map(item => (
												<Td key={item.mes} alignCenter>
													<NumberFormat
														style={{ textAlign: 'center' }}
														className='form-control'
														readOnly={'readOnly'}
														decimalSeparator={","} 
														thousandSeparator={'.'}
														decimalScale={3} 
														fixedDecimalScale={3} 
														value={item.inadimplencia_global}
														onChange={data => {
														
														}} />
												</Td>
											))}
											{this.props.formularioValues.meses.filter(item => item.mes == this.state.mes).map(item => (
												<Td key={item.mes} alignCenter>
													<NumberFormat
														style={{ textAlign: 'center' }}
														className='form-control'
														readOnly={'readOnly'}
														decimalSeparator={","} 
														thousandSeparator={'.'}
														decimalScale={3} 
														fixedDecimalScale={3} 
														value={item.realizado_inadimplencia_global}
														onChange={data => {
														
														}} />
												</Td>
											))}
											{this.props.formularioValues.meses.filter(item => item.mes == this.state.mes).map(item => (
												<Td key={item.mes} alignCenter>
													<NumberFormat
														style={{ textAlign: 'center' }}
														className='form-control'
														readOnly={'readOnly'}
														decimalSeparator={","} 
														thousandSeparator={'.'}
														decimalScale={3} 
														fixedDecimalScale={3} 
														value={item.realizado_inadimplencia_global}
														onChange={data => {
														
														}} />
												</Td>
											))}
											{this.props.formularioValues.meses.filter(item => item.mes == this.state.mes).map(item => (
												<Td key={item.mes} alignCenter>
													<LabelAndInput
														textAlign='center'
														className='form-control'
														readOnly={'readOnly'} 
														value={`${parseInt(
															percentualGlobalInadimplencia
														)}%`}
														onChange={data => {
															
														}} />
												</Td>
											))}
										</Tr>
										<Tr backgroundColor='#dfe2f5'>
											<Td colspan={2}>Prazo Médio Global</Td>
											{this.props.formularioValues.meses.filter(item => item.mes == this.state.mes).map(item => (
												<Td key={item.mes} alignCenter>
													<NumberFormat
														style={{ textAlign: 'center' }}
														className='form-control'
														readOnly={'readOnly'}
														decimalSeparator={","} 
														thousandSeparator={'.'}
														decimalScale={1} 
														fixedDecimalScale={1} 
														value={item.prazo_global}
														onChange={data => {
															
														}} />
												</Td>
											))}
											{this.props.formularioValues.meses.filter(item => item.mes == this.state.mes).map(item => (
												<Td key={item.mes} alignCenter>
													<NumberFormat
														style={{ textAlign: 'center' }}
														className='form-control'
														readOnly={'readOnly'}
														decimalSeparator={","} 
														thousandSeparator={'.'}
														decimalScale={1} 
														fixedDecimalScale={1} 
														value={item.realizado_prazo_global}
														onChange={data => {
															
														}} />
												</Td>
											))}
											{this.props.formularioValues.meses.filter(item => item.mes == this.state.mes).map(item => (
												<Td key={item.mes} alignCenter>
													<NumberFormat
														style={{ textAlign: 'center' }}
														className='form-control'
														readOnly={'readOnly'}
														decimalSeparator={","} 
														thousandSeparator={'.'}
														decimalScale={1} 
														fixedDecimalScale={1} 
														value={item.realizado_prazo_global}
														onChange={data => {
															
														}} />
												</Td>
											))}
											{this.props.formularioValues.meses.filter(item => item.mes == this.state.mes).map(item => (
												<Td key={item.mes} alignCenter>
													<LabelAndInput
														textAlign='center'
														className='form-control'
														readOnly={'readOnly'} 
														value={`${parseInt(
															parseFloat(item.realizado_prazo_global) > 0 ?
																(parseFloat(FormatUtils.formatarValorBanco(item.prazo_global, 4)) * 100) 
																/ parseFloat(item.realizado_prazo_global)
															: 0
														)}%`}
														onChange={data => {
															
														}} />
												</Td>
											))}
										</Tr>

										<Tr backgroundColor='#dfe2f5'>
											<Td colspan={5} alignRight bold>Global</Td>
											<Td alignCenter>
												<LabelAndInput
													textAlign='center'
													className='form-control'
													readOnly={'readOnly'} 
													value={`${resultadoGlobalGeral}%`}
													onChange={data => {
														
													}} />
											</Td>
										</Tr>
									</>}

									{/*
									A1
									*/}

									{acessoA1 &&
									<>
										<Tr backgroundColor='#fff'>
											<Td rowspan={6} bold>A1</Td>
											<Td>Volume</Td>
											{this.props.formularioValues.meses.filter(item => item.mes == this.state.mes).map(item => (
												<Td key={item.mes} alignCenter>
													<NumberFormat
														style={{ textAlign: 'center' }}
														className='form-control'
														readOnly={'readOnly'}
														decimalSeparator={","} 
														thousandSeparator={'.'}
														decimalScale={0} 
														fixedDecimalScale={0} 
														value={item.volume_a1}
														onChange={data => {
															
														}} />
												</Td>
											))}
											{this.props.formularioValues.meses.filter(item => item.mes == this.state.mes).map(item => (
												<Td key={item.mes} alignCenter>
													<NumberFormat
														style={{ textAlign: 'center' }}
														className='form-control'
														readOnly={'readOnly'}
														decimalSeparator={","} 
														thousandSeparator={'.'}
														decimalScale={0} 
														fixedDecimalScale={0} 
														value={item.realizado_volume_a1}
														onChange={data => {
															
														}} />
												</Td>
											))}
											{this.props.formularioValues.meses.filter(item => item.mes == this.state.mes).map(item => (
												<Td key={item.mes} alignCenter>
													<NumberFormat
														style={{ textAlign: 'center' }}
														className='form-control'
														readOnly={'readOnly'}
														decimalSeparator={","} 
														thousandSeparator={'.'}
														decimalScale={0} 
														fixedDecimalScale={0} 
														value={
															parseInt((parseFloat(FormatUtils.formatarValorBanco(item.realizado_volume_a1)) * diasMes) / diaAtual)
														}
														onChange={data => {
															
														}} />
												</Td>
											))}
											{this.props.formularioValues.meses.filter(item => item.mes == this.state.mes).map(item => (
												<Td key={item.mes} alignCenter>
													<LabelAndInput
														textAlign='center'
														className='form-control'
														readOnly={'readOnly'} 
														value={`${parseInt(
															parseFloat(FormatUtils.formatarValorBanco(item.volume_a1, 0)) > 0 ?
																(parseFloat((parseFloat(FormatUtils.formatarValorBanco(item.realizado_volume_a1)) * diasMes) / diaAtual) * 100) 
																/ parseFloat(FormatUtils.formatarValorBanco(item.volume_a1, 0))
															: 0
														)}%`}
														onChange={data => {
															
														}} />
												</Td>
											))}
											<Td rowspan={6}>

												<Table responsive>
													<TBody>
														<Tr>
															<Td colspan={2} bold>Meta Diária</Td>
															<Td colspan={2} alignCenter>
																<NumberFormat
																	style={{ textAlign: 'center' }}
																	className='form-control'
																	readOnly={'readOnly'}
																	decimalSeparator={","} 
																	thousandSeparator={'.'}
																	decimalScale={0} 
																	fixedDecimalScale={0} 
																	value={FormatUtils.formatarValorTela(mediaDiariaA1, 0)}
																	onChange={data => {
																			
																}} />
															</Td>
															<Td alignCenter></Td>
															<Td alignCenter></Td>
															<Td alignCenter></Td>
															<Td alignCenter></Td>
															<Td alignCenter></Td>
														</Tr>
														<Tr>
															<Th alignCenter>Semana</Th>
															<Th alignCenter>Dom</Th>
															<Th alignCenter>Seg</Th>
															<Th alignCenter>Ter</Th>
															<Th alignCenter>Qua</Th>
															<Th alignCenter>Qui</Th>
															<Th alignCenter>Sex</Th>
															<Th alignCenter>Sab</Th>
															<Th alignCenter>Saldo</Th>
														</Tr>

														{semanas.map(semana => {
															let metaTotalSemanal = 0;
															for (let dia = 1; dia <= diasMes; dia++) {
																//Se está na semana e não é domingo
																if (this.getSemana(`${this.props.formularioValues.exercicio}/${this.state.mes}/${dia}`) + 1 == semana
																	&& new Date(`${this.props.formularioValues.exercicio}/${this.state.mes}/${dia} 12:00`).getDay() != 0) {
																	metaTotalSemanal += mediaDiariaA1;
																}
																															
															}

															let realizadoTotalSemanal = 0;
															
															return (
																<Tr key={semana}>
																	<Th alignCenter>{semana}</Th>
																	{dias.map(dia => {
																		let volume = 0;
																		let mes = this.props.formularioValues.meses.filter(item => item.mes == this.state.mes)[0];
																		if (mes) {
																			let itemSemana = (mes.realizado_volume_semana_a1 || []).filter(sem => sem.semana == semana - 1)[0];
																			if (itemSemana) {
																				let itemDia = itemSemana.dias.filter(d => d.dia == dia - 1)[0];
																				volume = itemDia ? itemDia.volume : 0;
																			}
																		}

																		realizadoTotalSemanal += volume;

																		return (
																			<Td key={`${semana}-${dia}`} alignCenter>
																				<NumberFormat
																					style={{
																						textAlign: 'center', 
																						color: volume == 0 ? null : (volume < mediaDiariaA1 ? '#fff' : '#fff'),
																						backgroundColor: volume == 0 ? null : (volume < mediaDiariaA1 ? '#de3131' : '#22ba43')
																					}}
																					className='form-control'
																					readOnly={'readOnly'}
																					decimalSeparator={","} 
																					thousandSeparator={'.'}
																					decimalScale={0} 
																					fixedDecimalScale={0} 
																					value={volume}
																					onChange={data => {
																							
																					}} />
																			</Td>
																		);
																	})}
																	<Td alignCenter>
																		<NumberFormat
																			style={{ 
																				textAlign: 'center', 
																				color: (realizadoTotalSemanal - metaTotalSemanal) < 0 ? '#fff' : '#fff',
																				backgroundColor: (realizadoTotalSemanal - metaTotalSemanal) < 0 ? '#de3131' : '#22ba43'
																			}}
																			className='form-control'
																			readOnly={'readOnly'}
																			decimalSeparator={","} 
																			thousandSeparator={'.'}
																			decimalScale={0} 
																			fixedDecimalScale={0} 
																			value={FormatUtils.formatarValorTela(realizadoTotalSemanal - metaTotalSemanal, 0)}
																			onChange={data => {
																					
																			}} />
																	</Td>
																</Tr>
															)
														})}
													</TBody>
												</Table>
											</Td>
										</Tr>
										<Tr backgroundColor='#fff'>
											<Td>Margem</Td>
											{this.props.formularioValues.meses.filter(item => item.mes == this.state.mes).map(item => (
												<Td key={item.mes} alignCenter>
													<NumberFormat
														style={{ textAlign: 'center' }}
														className='form-control'
														readOnly={'readOnly'}
														decimalSeparator={","} 
														thousandSeparator={'.'}
														decimalScale={3} 
														fixedDecimalScale={3} 
														value={item.margem_a1}
														onChange={data => {
															
														}} />
												</Td>
											))}
											{this.props.formularioValues.meses.filter(item => item.mes == this.state.mes).map(item => (
												<Td key={item.mes} alignCenter>
													<NumberFormat
														style={{ textAlign: 'center' }}
														className='form-control'
														readOnly={'readOnly'}
														decimalSeparator={","} 
														thousandSeparator={'.'}
														decimalScale={3} 
														fixedDecimalScale={3} 
														value={item.realizado_margem_a1}
														onChange={data => {
															
														}} />
												</Td>
											))}
											{this.props.formularioValues.meses.filter(item => item.mes == this.state.mes).map(item => (
												<Td key={item.mes} alignCenter>
													<NumberFormat
														style={{ textAlign: 'center' }}
														className='form-control'
														readOnly={'readOnly'}
														decimalSeparator={","} 
														thousandSeparator={'.'}
														decimalScale={3} 
														fixedDecimalScale={3} 
														value={item.realizado_margem_a1}
														onChange={data => {
															
														}} />
												</Td>
											))}
											{this.props.formularioValues.meses.filter(item => item.mes == this.state.mes).map(item => (
												<Td key={item.mes} alignCenter>
													<LabelAndInput
														textAlign='center'
														className='form-control'
														readOnly={'readOnly'} 
														value={`${parseInt(
															parseFloat(FormatUtils.formatarValorBanco(item.margem_a1, 4)) > 0 ?
																(parseFloat(FormatUtils.formatarValorBanco(item.realizado_margem_a1, 4)) * 100) 
																/ parseFloat(FormatUtils.formatarValorBanco(item.margem_a1, 4))
															: 0
														)}%`}
														onChange={data => {
															
														}} />
												</Td>
											))}
										</Tr>
										<Tr backgroundColor='#fff' title={titleInadimplenciaA1}>
											<Td>Inadimplência</Td>
											{this.props.formularioValues.meses.filter(item => item.mes == this.state.mes).map(item => (
												<Td key={item.mes} alignCenter>
													<NumberFormat
														style={{ textAlign: 'center' }}
														className='form-control'
														readOnly={'readOnly'}
														decimalSeparator={","} 
														thousandSeparator={'.'}
														decimalScale={3} 
														fixedDecimalScale={3} 
														value={item.inadimplencia_a1}
														onChange={data => {
															
														}} />
												</Td>
											))}
											{this.props.formularioValues.meses.filter(item => item.mes == this.state.mes).map(item => (
												<Td key={item.mes} alignCenter>
													<NumberFormat
														style={{ textAlign: 'center' }}
														className='form-control'
														readOnly={'readOnly'}
														decimalSeparator={","} 
														thousandSeparator={'.'}
														decimalScale={3} 
														fixedDecimalScale={3} 
														value={item.realizado_inadimplencia_a1}
														onChange={data => {
															
														}} />
												</Td>
											))}
											{this.props.formularioValues.meses.filter(item => item.mes == this.state.mes).map(item => (
												<Td key={item.mes} alignCenter>
													<NumberFormat
														style={{ textAlign: 'center' }}
														className='form-control'
														readOnly={'readOnly'}
														decimalSeparator={","} 
														thousandSeparator={'.'}
														decimalScale={3} 
														fixedDecimalScale={3} 
														value={item.realizado_inadimplencia_a1}
														onChange={data => {
															
														}} />
												</Td>
											))}
											{this.props.formularioValues.meses.filter(item => item.mes == this.state.mes).map(item => (
												<Td key={item.mes} alignCenter>
													<LabelAndInput
														textAlign='center'
														className='form-control'
														readOnly={'readOnly'} 
														value={`${parseInt(
															percentualInadimplenciaA1
														)}%`}
														onChange={data => {
															
														}} />
												</Td>
											))}
										</Tr>
										<Tr backgroundColor='#fff'>
											<Td>Prazo Médio</Td>
											{this.props.formularioValues.meses.filter(item => item.mes == this.state.mes).map(item => (
												<Td key={item.mes} alignCenter>
													<NumberFormat
														style={{ textAlign: 'center' }}
														className='form-control'
														readOnly={'readOnly'}
														decimalSeparator={","} 
														thousandSeparator={'.'}
														decimalScale={1} 
														fixedDecimalScale={1} 
														value={item.prazo_a1}
														onChange={data => {
															
														}} />
												</Td>
											))}
											{this.props.formularioValues.meses.filter(item => item.mes == this.state.mes).map(item => (
												<Td key={item.mes} alignCenter>
													<NumberFormat
														style={{ textAlign: 'center' }}
														className='form-control'
														readOnly={'readOnly'}
														decimalSeparator={","} 
														thousandSeparator={'.'}
														decimalScale={1} 
														fixedDecimalScale={1} 
														value={item.realizado_prazo_a1}
														onChange={data => {
															
														}} />
												</Td>
											))}
											{this.props.formularioValues.meses.filter(item => item.mes == this.state.mes).map(item => (
												<Td key={item.mes} alignCenter>
													<NumberFormat
														style={{ textAlign: 'center' }}
														className='form-control'
														readOnly={'readOnly'}
														decimalSeparator={","} 
														thousandSeparator={'.'}
														decimalScale={1} 
														fixedDecimalScale={1} 
														value={item.realizado_prazo_a1}
														onChange={data => {
															
														}} />
												</Td>
											))}
											{this.props.formularioValues.meses.filter(item => item.mes == this.state.mes).map(item => (
												<Td key={item.mes} alignCenter>
													<LabelAndInput
														textAlign='center'
														className='form-control'
														readOnly={'readOnly'} 
														value={`${parseInt(
															parseFloat(FormatUtils.formatarValorBanco(item.realizado_prazo_a1, 4)) > 0 ?
																(parseFloat(FormatUtils.formatarValorBanco(item.prazo_a1, 4)) * 100) 
																/ parseFloat(FormatUtils.formatarValorBanco(item.realizado_prazo_a1, 4))
															: 0
														)}%`}
														onChange={data => {
															
														}} />
												</Td>
											))}
										</Tr>

										{this.state.mes <= 3 ? (
										<Tr backgroundColor='#fff'>
											<Td bold></Td>
											<Td bold>Individual</Td>
											<Td alignCenter bold>
												<LabelAndInput
													textAlign='center'
													className='form-control'
													readOnly={'readOnly'} 
													value={`${resultadoIndividualA1}%`}
													onChange={data => {
														
													}} />
											</Td>
											<Td alignRight bold>Global</Td>
											<Td alignCenter bold>
												<LabelAndInput
													textAlign='center'
													className='form-control'
													readOnly={'readOnly'} 
													value={`${resultadoGlobalGeral}%`}
													onChange={data => {
														
													}} />
											</Td>
										</Tr>) : (
										<Tr backgroundColor='#fff'>
											<Td bold></Td>
											<Td bold>Individual</Td>
											<Td alignCenter bold>
												<LabelAndInput
													textAlign='center'
													className='form-control'
													readOnly={'readOnly'} 
													value={`${resultadoIndividualA1}%`}
													onChange={data => {
														
													}} />
											</Td>
											<Td alignRight bold>Faixa</Td>
											<Td alignCenter bold>
												<LabelAndInput
													textAlign='center'
													className='form-control'
													readOnly={'readOnly'} 
													value={`${this.getFaixa(parseInt(resultadoIndividualA1))}%`}
													onChange={data => {
														
													}} />
											</Td>
										</Tr>)}

										{this.state.mes <= 3 ? (
										<Tr backgroundColor='#fff'>
											<Td bold></Td>
											<Td bold>Global + Individual</Td>
											<Td alignCenter bold>
												<LabelAndInput
													textAlign='center'
													className='form-control'
													readOnly={'readOnly'} 
													value={`${parseInt((resultadoIndividualA1 * 0.6) + (resultadoGlobalGeral * 0.4))}%`}
													onChange={data => {
														
													}} />
											</Td>
											<Td alignRight bold>Faixa</Td>
											<Td alignCenter bold>
												<LabelAndInput
													textAlign='center'
													className='form-control'
													readOnly={'readOnly'} 
													value={`${this.getFaixa(parseInt((resultadoIndividualA1 * 0.6) + (resultadoGlobalGeral * 0.4)))}%`}
													onChange={data => {
														
													}} />
											</Td>
										</Tr>) : (
										<Tr backgroundColor='#fff'>
											<Td bold></Td>
											<Td bold></Td>
											<Td alignCenter bold>
											</Td>
											<Td alignRight bold></Td>
											<Td alignCenter bold>
											</Td>
										</Tr>)}
										
									</>}
									
									{/*
									A2
									*/}
									
									{acessoA2 &&
									<>									
										<Tr backgroundColor='#e6e6e6'>
											<Td rowspan={6} bold>A2</Td>
											<Td>Volume</Td>
											{this.props.formularioValues.meses.filter(item => item.mes == this.state.mes).map(item => (
												<Td key={item.mes} alignCenter>
													<NumberFormat
														style={{ textAlign: 'center' }}
														className='form-control'
														readOnly={'readOnly'}
														decimalSeparator={","} 
														thousandSeparator={'.'}
														decimalScale={0} 
														fixedDecimalScale={0} 
														value={item.volume_a2}
														onChange={data => {
															
														}} />
												</Td>
											))}
											{this.props.formularioValues.meses.filter(item => item.mes == this.state.mes).map(item => (
												<Td key={item.mes} alignCenter>
													<NumberFormat
														style={{ textAlign: 'center' }}
														className='form-control'
														readOnly={'readOnly'}
														decimalSeparator={","} 
														thousandSeparator={'.'}
														decimalScale={0} 
														fixedDecimalScale={0} 
														value={item.realizado_volume_a2}
														onChange={data => {
															
														}} />
												</Td>
											))}
											{this.props.formularioValues.meses.filter(item => item.mes == this.state.mes).map(item => (
												<Td key={item.mes} alignCenter>
													<NumberFormat
														style={{ textAlign: 'center' }}
														className='form-control'
														readOnly={'readOnly'}
														decimalSeparator={","} 
														thousandSeparator={'.'}
														decimalScale={0} 
														fixedDecimalScale={0} 
														value={
															parseInt((parseFloat(FormatUtils.formatarValorBanco(item.realizado_volume_a2)) * diasMes) / diaAtual)
														}
														onChange={data => {
															
														}} />
												</Td>
											))}
											{this.props.formularioValues.meses.filter(item => item.mes == this.state.mes).map(item => (
												<Td key={item.mes} alignCenter>
												<LabelAndInput
													textAlign='center'
													className='form-control'
													readOnly={'readOnly'} 
													value={`${parseInt(
														parseFloat(FormatUtils.formatarValorBanco(item.volume_a2, 0)) > 0 ?
															(parseFloat((parseFloat(FormatUtils.formatarValorBanco(item.realizado_volume_a2)) * diasMes) / diaAtual) * 100) 
															/ parseFloat(FormatUtils.formatarValorBanco(item.volume_a2, 0))
														: 0
													)}%`}
													onChange={data => {
														
													}} />
											</Td>
											))}
											<Td rowspan={6}>
												<Table responsive>
													<TBody>
														<Tr>
															<Td colspan={2} bold>Meta Diária</Td>
															<Td colspan={2} alignCenter>
																<NumberFormat
																	style={{ textAlign: 'center' }}
																	className='form-control'
																	readOnly={'readOnly'}
																	decimalSeparator={","} 
																	thousandSeparator={'.'}
																	decimalScale={0} 
																	fixedDecimalScale={0} 
																	value={FormatUtils.formatarValorTela(mediaDiariaA2, 0)}
																	onChange={data => {
																			
																}} />
															</Td>
															<Td alignCenter></Td>
															<Td alignCenter></Td>
															<Td alignCenter></Td>
															<Td alignCenter></Td>
															<Td alignCenter></Td>
														</Tr>
														<Tr>
															<Th alignCenter>Semana</Th>
															<Th alignCenter>Dom</Th>
															<Th alignCenter>Seg</Th>
															<Th alignCenter>Ter</Th>
															<Th alignCenter>Qua</Th>
															<Th alignCenter>Qui</Th>
															<Th alignCenter>Sex</Th>
															<Th alignCenter>Sab</Th>
															<Th alignCenter>Saldo</Th>
														</Tr>

														{semanas.map(semana => {
															let metaTotalSemanal = 0;
															for (let dia = 1; dia <= diasMes; dia++) {
																//Se está na semana e não é domingo
																if (this.getSemana(`${this.props.formularioValues.exercicio}/${this.state.mes}/${dia}`) + 1 == semana
																	&& new Date(`${this.props.formularioValues.exercicio}/${this.state.mes}/${dia} 12:00`).getDay() != 0) {
																	metaTotalSemanal += mediaDiariaA2;
																}
																															
															}

															let realizadoTotalSemanal = 0;
															
															return (
																<Tr key={semana}>
																	<Th alignCenter>{semana}</Th>
																	{dias.map(dia => {
																		let volume = 0;
																		let mes = this.props.formularioValues.meses.filter(item => item.mes == this.state.mes)[0];
																		if (mes) {
																			let itemSemana = (mes.realizado_volume_semana_a2 || []).filter(sem => sem.semana == semana - 1)[0];
																			if (itemSemana) {
																				let itemDia = itemSemana.dias.filter(d => d.dia == dia - 1)[0];
																				volume = itemDia ? itemDia.volume : 0;
																			}
																		}

																		realizadoTotalSemanal += volume;

																		return (
																			<Td key={`${semana}-${dia}`} alignCenter>
																				<NumberFormat
																					style={{
																						textAlign: 'center', 
																						color: volume == 0 ? null : (volume < mediaDiariaA2 ? '#fff' : '#fff'),
																						backgroundColor: volume == 0 ? null : (volume < mediaDiariaA2 ? '#de3131' : '#22ba43')
																					}}
																					className='form-control'
																					readOnly={'readOnly'}
																					decimalSeparator={","} 
																					thousandSeparator={'.'}
																					decimalScale={0} 
																					fixedDecimalScale={0} 
																					value={volume}
																					onChange={data => {
																							
																					}} />
																			</Td>
																		);
																	})}
																	<Td alignCenter>
																		<NumberFormat
																			style={{ 
																				textAlign: 'center', 
																				color: (realizadoTotalSemanal - metaTotalSemanal) < 0 ? '#fff' : '#fff',
																				backgroundColor: (realizadoTotalSemanal - metaTotalSemanal) < 0 ? '#de3131' : '#22ba43'
																			}}
																			className='form-control'
																			readOnly={'readOnly'}
																			decimalSeparator={","} 
																			thousandSeparator={'.'}
																			decimalScale={0} 
																			fixedDecimalScale={0} 
																			value={FormatUtils.formatarValorTela(realizadoTotalSemanal - metaTotalSemanal, 0)}
																			onChange={data => {

																			}} />
																	</Td>
																</Tr>
															)
														})}
													</TBody>
												</Table>
											</Td>
										</Tr>
										<Tr backgroundColor='#e6e6e6'>
											<Td>Margem</Td>
											{this.props.formularioValues.meses.filter(item => item.mes == this.state.mes).map(item => (
												<Td key={item.mes} alignCenter>
													<NumberFormat
														style={{ textAlign: 'center' }}
														className='form-control'
														readOnly={'readOnly'}
														decimalSeparator={","} 
														thousandSeparator={'.'}
														decimalScale={3} 
														fixedDecimalScale={3} 
														value={item.margem_a2}
														onChange={data => {
															
														}} />
												</Td>
											))}
											{this.props.formularioValues.meses.filter(item => item.mes == this.state.mes).map(item => (
												<Td key={item.mes} alignCenter>
													<NumberFormat
														style={{ textAlign: 'center' }}
														className='form-control'
														readOnly={'readOnly'}
														decimalSeparator={","} 
														thousandSeparator={'.'}
														decimalScale={3} 
														fixedDecimalScale={3} 
														value={item.realizado_margem_a2}
														onChange={data => {
															
														}} />
												</Td>
											))}
											{this.props.formularioValues.meses.filter(item => item.mes == this.state.mes).map(item => (
												<Td key={item.mes} alignCenter>
													<NumberFormat
														style={{ textAlign: 'center' }}
														className='form-control'
														readOnly={'readOnly'}
														decimalSeparator={","} 
														thousandSeparator={'.'}
														decimalScale={3} 
														fixedDecimalScale={3} 
														value={item.realizado_margem_a2}
														onChange={data => {
															
														}} />
												</Td>
											))}
											{this.props.formularioValues.meses.filter(item => item.mes == this.state.mes).map(item => (
												<Td key={item.mes} alignCenter>
													<LabelAndInput
														textAlign='center'
														className='form-control'
														readOnly={'readOnly'} 
														value={`${parseInt(
															parseFloat(FormatUtils.formatarValorBanco(item.margem_a2, 4)) > 0 ?
																(parseFloat(FormatUtils.formatarValorBanco(item.realizado_margem_a2, 4)) * 100) 
																/ parseFloat(FormatUtils.formatarValorBanco(item.margem_a2, 4))
															: 0
														)}%`}
														onChange={data => {
															
														}} />
												</Td>
											))}
										</Tr>
										<Tr backgroundColor='#e6e6e6' title={titleInadimplenciaA2}>
											<Td>Inadimplência</Td>
											{this.props.formularioValues.meses.filter(item => item.mes == this.state.mes).map(item => (
												<Td key={item.mes} alignCenter>
													<NumberFormat
														style={{ textAlign: 'center' }}
														className='form-control'
														readOnly={'readOnly'}
														decimalSeparator={","} 
														thousandSeparator={'.'}
														decimalScale={3} 
														fixedDecimalScale={3} 
														value={item.inadimplencia_a2}
														onChange={data => {
															
														}} />
												</Td>
											))}
											{this.props.formularioValues.meses.filter(item => item.mes == this.state.mes).map(item => (
												<Td key={item.mes} alignCenter>
													<NumberFormat
														style={{ textAlign: 'center' }}
														className='form-control'
														readOnly={'readOnly'}
														decimalSeparator={","} 
														thousandSeparator={'.'}
														decimalScale={3} 
														fixedDecimalScale={3} 
														value={item.realizado_inadimplencia_a2}
														onChange={data => {
															
														}} />
												</Td>
											))}
											{this.props.formularioValues.meses.filter(item => item.mes == this.state.mes).map(item => (
												<Td key={item.mes} alignCenter>
													<NumberFormat
														style={{ textAlign: 'center' }}
														className='form-control'
														readOnly={'readOnly'}
														decimalSeparator={","} 
														thousandSeparator={'.'}
														decimalScale={3} 
														fixedDecimalScale={3} 
														value={item.realizado_inadimplencia_a2}
														onChange={data => {
															
														}} />
												</Td>
											))}
											{this.props.formularioValues.meses.filter(item => item.mes == this.state.mes).map(item => (
												<Td key={item.mes} alignCenter>
													<LabelAndInput
														textAlign='center'
														className='form-control'
														readOnly={'readOnly'} 
														value={`${parseInt(
															percentualInadimplenciaA2
														)}%`}
														onChange={data => {
															
														}} />
												</Td>
											))}
										</Tr>
										<Tr backgroundColor='#e6e6e6'>
											<Td>Prazo Médio</Td>
											{this.props.formularioValues.meses.filter(item => item.mes == this.state.mes).map(item => (
												<Td key={item.mes} alignCenter>
													<NumberFormat
														style={{ textAlign: 'center' }}
														className='form-control'
														readOnly={'readOnly'}
														decimalSeparator={","} 
														thousandSeparator={'.'}
														decimalScale={1} 
														fixedDecimalScale={1} 
														value={item.prazo_a2}
														onChange={data => {
															
														}} />
												</Td>
											))}
											{this.props.formularioValues.meses.filter(item => item.mes == this.state.mes).map(item => (
												<Td key={item.mes} alignCenter>
													<NumberFormat
														style={{ textAlign: 'center' }}
														className='form-control'
														readOnly={'readOnly'}
														decimalSeparator={","} 
														thousandSeparator={'.'}
														decimalScale={1} 
														fixedDecimalScale={1} 
														value={item.realizado_prazo_a2}
														onChange={data => {
															
														}} />
												</Td>
											))}
											{this.props.formularioValues.meses.filter(item => item.mes == this.state.mes).map(item => (
												<Td key={item.mes} alignCenter>
													<NumberFormat
														style={{ textAlign: 'center' }}
														className='form-control'
														readOnly={'readOnly'}
														decimalSeparator={","} 
														thousandSeparator={'.'}
														decimalScale={1} 
														fixedDecimalScale={1} 
														value={item.realizado_prazo_a2}
														onChange={data => {
															
														}} />
												</Td>
											))}
											{this.props.formularioValues.meses.filter(item => item.mes == this.state.mes).map(item => (
												<Td key={item.mes} alignCenter>
													<LabelAndInput
														textAlign='center'
														className='form-control'
														readOnly={'readOnly'} 
														value={`${parseInt(
															parseFloat(FormatUtils.formatarValorBanco(item.realizado_prazo_a2, 4)) > 0 ?
																(parseFloat(FormatUtils.formatarValorBanco(item.prazo_a2, 4)) * 100) 
																/ parseFloat(FormatUtils.formatarValorBanco(item.realizado_prazo_a2, 4))
															: 0
														)}%`}
														onChange={data => {
															
														}} />
												</Td>
											))}
										</Tr>

										{this.state.mes <= 3 ? (
										<Tr backgroundColor='#e6e6e6'>
											<Td bold></Td>
											<Td bold>Individual</Td>
											<Td alignCenter>
												<LabelAndInput
													textAlign='center'
													className='form-control'
													readOnly={'readOnly'} 
													value={`${resultadoIndividualA2}%`}
													onChange={data => {
														
													}} />
											</Td>										
											<Td alignRight bold>Global</Td>
											<Td alignCenter>
												<LabelAndInput
													textAlign='center'
													className='form-control'
													readOnly={'readOnly'} 
													value={`${resultadoGlobalGeral}%`}
													onChange={data => {
														
													}} />
											</Td>
										</Tr>) : (
										<Tr backgroundColor='#e6e6e6'>
											<Td bold></Td>
											<Td bold>Individual</Td>
											<Td alignCenter>
												<LabelAndInput
													textAlign='center'
													className='form-control'
													readOnly={'readOnly'} 
													value={`${resultadoIndividualA2}%`}
													onChange={data => {
														
													}} />
											</Td>
											<Td alignRight bold>Faixa</Td>
											<Td alignCenter>
												<LabelAndInput
													textAlign='center'
													className='form-control'
													readOnly={'readOnly'} 
													value={`${this.getFaixa(parseInt(resultadoIndividualA2))}%`}
													onChange={data => {
														
													}} />
											</Td>
										</Tr>)}

										{this.state.mes <= 3 ? (
										<Tr backgroundColor='#e6e6e6'>
											<Td bold></Td>
											<Td bold>Global + Individual</Td>
											<Td alignCenter>
												<LabelAndInput
													textAlign='center'
													className='form-control'
													readOnly={'readOnly'} 
													value={`${parseInt((resultadoIndividualA2 * 0.6) + (resultadoGlobalGeral * 0.4))}%`}
													onChange={data => {
														
													}} />
											</Td>
											<Td alignRight bold>Faixa</Td>
											<Td alignCenter>
												<LabelAndInput
													textAlign='center'
													className='form-control'
													readOnly={'readOnly'} 
													value={`${this.getFaixa(parseInt((resultadoIndividualA2 * 0.6) + (resultadoGlobalGeral * 0.4)))}%`}
													onChange={data => {
														
													}} />
											</Td>
										</Tr>) : (
										<Tr backgroundColor='#e6e6e6'>
											<Td bold></Td>
											<Td bold></Td>
											<Td alignCenter>
											</Td>
											<Td alignRight bold></Td>
											<Td alignCenter>
											</Td>
										</Tr>)}
									</>}

									{/*
									A3
									*/}
										
									{acessoA3 &&
									<>
										<Tr backgroundColor='#fff'>
											<Td rowspan={6} bold>A3</Td>
											<Td>Volume</Td>
											{this.props.formularioValues.meses.filter(item => item.mes == this.state.mes).map(item => (
												<Td key={item.mes} alignCenter>
													<NumberFormat
														style={{ textAlign: 'center' }}
														className='form-control'
														readOnly={'readOnly'}
														decimalSeparator={","} 
														thousandSeparator={'.'}
														decimalScale={0} 
														fixedDecimalScale={0} 
														value={item.volume_a3}
														onChange={data => {
															
														}} />
												</Td>
											))}
											{this.props.formularioValues.meses.filter(item => item.mes == this.state.mes).map(item => (
												<Td key={item.mes} alignCenter>
													<NumberFormat
														style={{ textAlign: 'center' }}
														className='form-control'
														readOnly={'readOnly'}
														decimalSeparator={","} 
														thousandSeparator={'.'}
														decimalScale={0} 
														fixedDecimalScale={0} 
														value={item.realizado_volume_a3}
														onChange={data => {
															
														}} />
												</Td>
											))}
											{this.props.formularioValues.meses.filter(item => item.mes == this.state.mes).map(item => (
												<Td key={item.mes} alignCenter>
													<NumberFormat
														style={{ textAlign: 'center' }}
														className='form-control'
														readOnly={'readOnly'}
														decimalSeparator={","} 
														thousandSeparator={'.'}
														decimalScale={0} 
														fixedDecimalScale={0} 
														value={
															parseInt((parseFloat(FormatUtils.formatarValorBanco(item.realizado_volume_a3)) * diasMes) / diaAtual)
														}
														onChange={data => {
															
														}} />
												</Td>
											))}
											{this.props.formularioValues.meses.filter(item => item.mes == this.state.mes).map(item => (
												<Td key={item.mes} alignCenter>
												<LabelAndInput
													textAlign='center'
													className='form-control'
													readOnly={'readOnly'} 
													value={`${parseInt(
														parseFloat(FormatUtils.formatarValorBanco(item.volume_a3, 0)) > 0 ?
															(parseFloat((parseFloat(FormatUtils.formatarValorBanco(item.realizado_volume_a3)) * diasMes) / diaAtual) * 100) 
															/ parseFloat(FormatUtils.formatarValorBanco(item.volume_a3, 0))
														: 0
													)}%`}
													onChange={data => {
														
													}} />
											</Td>
											))}
											<Td rowspan={6}>
												<Table responsive>
													<TBody>
														<Tr>
															<Td colspan={2} bold>Meta Diária</Td>
															<Td colspan={2} alignCenter>
																<NumberFormat
																	style={{ textAlign: 'center' }}
																	className='form-control'
																	readOnly={'readOnly'}
																	decimalSeparator={","} 
																	thousandSeparator={'.'}
																	decimalScale={0} 
																	fixedDecimalScale={0} 
																	value={FormatUtils.formatarValorTela(mediaDiariaA3, 0)}
																	onChange={data => {
																			
																}} />
															</Td>
															<Td alignCenter></Td>
															<Td alignCenter></Td>
															<Td alignCenter></Td>
															<Td alignCenter></Td>
															<Td alignCenter></Td>
														</Tr>
														<Tr>
															<Th alignCenter>Semana</Th>
															<Th alignCenter>Dom</Th>
															<Th alignCenter>Seg</Th>
															<Th alignCenter>Ter</Th>
															<Th alignCenter>Qua</Th>
															<Th alignCenter>Qui</Th>
															<Th alignCenter>Sex</Th>
															<Th alignCenter>Sab</Th>
															<Th alignCenter>Saldo</Th>
														</Tr>
															
														{semanas.map(semana => {
															let metaTotalSemanal = 0;
															for (let dia = 1; dia <= diasMes; dia++) {
																//Se está na semana e não é domingo
																if (this.getSemana(`${this.props.formularioValues.exercicio}/${this.state.mes}/${dia}`) + 1 == semana
																	&& new Date(`${this.props.formularioValues.exercicio}/${this.state.mes}/${dia} 12:00`).getDay() != 0) {
																	metaTotalSemanal += mediaDiariaA3;
																}
																															
															}

															let realizadoTotalSemanal = 0;
															
															return (
																<Tr key={semana}>
																	<Th alignCenter>{semana}</Th>
																	{dias.map(dia => {
																		let volume = 0;
																		let mes = this.props.formularioValues.meses.filter(item => item.mes == this.state.mes)[0];
																		if (mes) {
																			let itemSemana = (mes.realizado_volume_semana_a3 || []).filter(sem => sem.semana == semana - 1)[0];
																			if (itemSemana) {
																				let itemDia = itemSemana.dias.filter(d => d.dia == dia - 1)[0];
																				volume = itemDia ? itemDia.volume : 0;
																			}
																		}

																		realizadoTotalSemanal += volume;

																		return (
																			<Td key={`${semana}-${dia}`} alignCenter>
																				<NumberFormat
																					style={{
																						textAlign: 'center', 
																						color: volume == 0 ? null : (volume < mediaDiariaA3 ? '#fff' : '#fff'),
																						backgroundColor: volume == 0 ? null : (volume < mediaDiariaA3 ? '#de3131' : '#22ba43')
																					}}
																					className='form-control'
																					readOnly={'readOnly'}
																					decimalSeparator={","} 
																					thousandSeparator={'.'}
																					decimalScale={0} 
																					fixedDecimalScale={0} 
																					value={volume}
																					onChange={data => {
																							
																					}} />
																			</Td>
																		);
																	})}
																	<Td alignCenter>
																		<NumberFormat
																			style={{ 
																				textAlign: 'center', 
																				color: (realizadoTotalSemanal - metaTotalSemanal) < 0 ? '#fff' : '#fff',
																				backgroundColor: (realizadoTotalSemanal - metaTotalSemanal) < 0 ? '#de3131' : '#22ba43'
																			}}
																			className='form-control'
																			readOnly={'readOnly'}
																			decimalSeparator={","} 
																			thousandSeparator={'.'}
																			decimalScale={0} 
																			fixedDecimalScale={0} 
																			value={FormatUtils.formatarValorTela(realizadoTotalSemanal - metaTotalSemanal, 0)}
																			onChange={data => {
																					
																			}} />
																	</Td>
																</Tr>
															)
														})}
													</TBody>
												</Table>
											</Td>
										</Tr>
										<Tr backgroundColor='#fff'>
											<Td>Margem</Td>
											{this.props.formularioValues.meses.filter(item => item.mes == this.state.mes).map(item => (
												<Td key={item.mes} alignCenter>
													<NumberFormat
														style={{ textAlign: 'center' }}
														className='form-control'
														readOnly={'readOnly'}
														decimalSeparator={","} 
														thousandSeparator={'.'}
														decimalScale={3} 
														fixedDecimalScale={3} 
														value={item.margem_a3}
														onChange={data => {
															
														}} />
												</Td>
											))}
											{this.props.formularioValues.meses.filter(item => item.mes == this.state.mes).map(item => (
												<Td key={item.mes} alignCenter>
													<NumberFormat
														style={{ textAlign: 'center' }}
														className='form-control'
														readOnly={'readOnly'}
														decimalSeparator={","} 
														thousandSeparator={'.'}
														decimalScale={3} 
														fixedDecimalScale={3} 
														value={item.realizado_margem_a3}
														onChange={data => {
															
														}} />
												</Td>
											))}
											{this.props.formularioValues.meses.filter(item => item.mes == this.state.mes).map(item => (
												<Td key={item.mes} alignCenter>
													<NumberFormat
														style={{ textAlign: 'center' }}
														className='form-control'
														readOnly={'readOnly'}
														decimalSeparator={","} 
														thousandSeparator={'.'}
														decimalScale={3} 
														fixedDecimalScale={3} 
														value={item.realizado_margem_a3}
														onChange={data => {
															
														}} />
												</Td>
											))}
											{this.props.formularioValues.meses.filter(item => item.mes == this.state.mes).map(item => (
												<Td key={item.mes} alignCenter>
													<LabelAndInput
														textAlign='center'
														className='form-control'
														readOnly={'readOnly'} 
														value={`${parseInt(
															parseFloat(FormatUtils.formatarValorBanco(item.margem_a3, 4)) > 0 ?
																(parseFloat(FormatUtils.formatarValorBanco(item.realizado_margem_a3, 4)) * 100) 
																/ parseFloat(FormatUtils.formatarValorBanco(item.margem_a3, 4))
															: 0
														)}%`}
														onChange={data => {
															
														}} />
												</Td>
											))}
										</Tr>
										<Tr backgroundColor='#fff' title={titleInadimplenciaA3}>
											<Td>Inadimplência</Td>
											{this.props.formularioValues.meses.filter(item => item.mes == this.state.mes).map(item => (
												<Td key={item.mes} alignCenter>
													<NumberFormat
														style={{ textAlign: 'center' }}
														className='form-control'
														readOnly={'readOnly'}
														decimalSeparator={","} 
														thousandSeparator={'.'}
														decimalScale={3} 
														fixedDecimalScale={3} 
														value={item.inadimplencia_a3}
														onChange={data => {
															
														}} />
												</Td>
											))}
											{this.props.formularioValues.meses.filter(item => item.mes == this.state.mes).map(item => (
												<Td key={item.mes} alignCenter>
													<NumberFormat
														style={{ textAlign: 'center' }}
														className='form-control'
														readOnly={'readOnly'}
														decimalSeparator={","} 
														thousandSeparator={'.'}
														decimalScale={3} 
														fixedDecimalScale={3} 
														value={item.realizado_inadimplencia_a3}
														onChange={data => {
															
														}} />
												</Td>
											))}
											{this.props.formularioValues.meses.filter(item => item.mes == this.state.mes).map(item => (
												<Td key={item.mes} alignCenter>
													<NumberFormat
														style={{ textAlign: 'center' }}
														className='form-control'
														readOnly={'readOnly'}
														decimalSeparator={","} 
														thousandSeparator={'.'}
														decimalScale={3} 
														fixedDecimalScale={3} 
														value={item.realizado_inadimplencia_a3}
														onChange={data => {
															
														}} />
												</Td>
											))}
											{this.props.formularioValues.meses.filter(item => item.mes == this.state.mes).map(item => (
												<Td key={item.mes} alignCenter>
													<LabelAndInput
														textAlign='center'
														className='form-control'
														readOnly={'readOnly'} 
														value={`${parseInt(
															percentualInadimplenciaA3
														)}%`}
														onChange={data => {
															
														}} />
												</Td>
											))}
										</Tr>
										<Tr backgroundColor='#fff'>
											<Td>Prazo Médio</Td>
											{this.props.formularioValues.meses.filter(item => item.mes == this.state.mes).map(item => (
												<Td key={item.mes} alignCenter>
													<NumberFormat
														style={{ textAlign: 'center' }}
														className='form-control'
														readOnly={'readOnly'}
														decimalSeparator={","} 
														thousandSeparator={'.'}
														decimalScale={1} 
														fixedDecimalScale={1} 
														value={item.prazo_a3}
														onChange={data => {
															
														}} />
												</Td>
											))}
											{this.props.formularioValues.meses.filter(item => item.mes == this.state.mes).map(item => (
												<Td key={item.mes} alignCenter>
													<NumberFormat
														style={{ textAlign: 'center' }}
														className='form-control'
														readOnly={'readOnly'}
														decimalSeparator={","} 
														thousandSeparator={'.'}
														decimalScale={1} 
														fixedDecimalScale={1} 
														value={item.realizado_prazo_a3}
														onChange={data => {
															
														}} />
												</Td>
											))}
											{this.props.formularioValues.meses.filter(item => item.mes == this.state.mes).map(item => (
												<Td key={item.mes} alignCenter>
													<NumberFormat
														style={{ textAlign: 'center' }}
														className='form-control'
														readOnly={'readOnly'}
														decimalSeparator={","} 
														thousandSeparator={'.'}
														decimalScale={1} 
														fixedDecimalScale={1} 
														value={item.realizado_prazo_a3}
														onChange={data => {
															
														}} />
												</Td>
											))}
											{this.props.formularioValues.meses.filter(item => item.mes == this.state.mes).map(item => (
												<Td key={item.mes} alignCenter>
													<LabelAndInput
														textAlign='center'
														className='form-control'
														readOnly={'readOnly'} 
														value={`${parseInt(
															parseFloat(FormatUtils.formatarValorBanco(item.realizado_prazo_a3, 4)) > 0 ?
																(parseFloat(FormatUtils.formatarValorBanco(item.prazo_a3, 4)) * 100) 
																/ parseFloat(FormatUtils.formatarValorBanco(item.realizado_prazo_a3, 4))
															: 0
														)}%`}
														onChange={data => {
															
														}} />
												</Td>
											))}
										</Tr>

										{this.state.mes <= 3 ? (
										<Tr backgroundColor='#fff'>
											<Td bold></Td>
											<Td bold>Individual</Td>
											<Td alignCenter>
												<LabelAndInput
													textAlign='center'
													className='form-control'
													readOnly={'readOnly'} 
													value={`${resultadoIndividualA3}%`}
													onChange={data => {
														
													}} />
											</Td>
											<Td alignRight bold>Global</Td>
											<Td alignCenter>
												<LabelAndInput
													textAlign='center'
													className='form-control'
													readOnly={'readOnly'} 
													value={`${resultadoGlobalGeral}%`}
													onChange={data => {
														
													}} />
											</Td>
										</Tr>) : (
										<Tr backgroundColor='#fff'>
											<Td bold></Td>
											<Td bold>Individual</Td>
											<Td alignCenter>
												<LabelAndInput
													textAlign='center'
													className='form-control'
													readOnly={'readOnly'} 
													value={`${resultadoIndividualA3}%`}
													onChange={data => {
														
													}} />
											</Td>
											<Td alignRight bold>Faixa</Td>
											<Td alignCenter>
												<LabelAndInput
													textAlign='center'
													className='form-control'
													readOnly={'readOnly'} 
													value={`${this.getFaixa(parseInt(resultadoIndividualA3))}%`}
													onChange={data => {
														
													}} />
											</Td>
										</Tr>)}
										
										{this.state.mes <= 3 ? (
										<Tr backgroundColor='#fff'>
											<Td bold></Td>
											<Td bold>Global + Individual</Td>
											<Td alignCenter>
												<LabelAndInput
													textAlign='center'
													className='form-control'
													readOnly={'readOnly'} 
													value={`${parseInt((resultadoIndividualA3 * 0.6) + (resultadoGlobalGeral * 0.4))}%`}
													onChange={data => {
														
													}} />
											</Td>
											<Td alignRight bold>Faixa</Td>
											<Td alignCenter>
												<LabelAndInput
													textAlign='center'
													className='form-control'
													readOnly={'readOnly'} 
													value={`${this.getFaixa(parseInt((resultadoIndividualA3 * 0.6) + (resultadoGlobalGeral * 0.4)))}%`}
													onChange={data => {
														
													}} />
											</Td>
										</Tr>) : (
										<Tr backgroundColor='#fff'>
											<Td bold></Td>
											<Td bold></Td>
											<Td alignCenter>
											</Td>
											<Td alignRight bold></Td>
											<Td alignCenter>
											</Td>
										</Tr>
										)}
									</>}

									{/*
									A4
									*/}
									
									{acessoA4 &&
									<>
										<Tr backgroundColor='#e6e6e6'>
											<Td rowspan={5} bold>A4</Td>
											<Td>Volume</Td>
											{this.props.formularioValues.meses.filter(item => item.mes == this.state.mes).map(item => (
												<Td key={item.mes} alignCenter>
													<NumberFormat
														style={{ textAlign: 'center' }}
														className='form-control'
														readOnly={'readOnly'}
														decimalSeparator={","} 
														thousandSeparator={'.'}
														decimalScale={0} 
														fixedDecimalScale={0} 
														value={item.volume_a4}
														onChange={data => {
															
														}} />
												</Td>
											))}
											{this.props.formularioValues.meses.filter(item => item.mes == this.state.mes).map(item => (
												<Td key={item.mes} alignCenter>
													<NumberFormat
														style={{ textAlign: 'center' }}
														className='form-control'
														readOnly={'readOnly'}
														decimalSeparator={","} 
														thousandSeparator={'.'}
														decimalScale={0} 
														fixedDecimalScale={0} 
														value={item.realizado_volume_a4}
														onChange={data => {
															
														}} />
												</Td>
											))}
											{this.props.formularioValues.meses.filter(item => item.mes == this.state.mes).map(item => (
												<Td key={item.mes} alignCenter>
													<NumberFormat
														style={{ textAlign: 'center' }}
														className='form-control'
														readOnly={'readOnly'}
														decimalSeparator={","} 
														thousandSeparator={'.'}
														decimalScale={0} 
														fixedDecimalScale={0} 
														value={
															parseInt((parseFloat(FormatUtils.formatarValorBanco(item.realizado_volume_a4)) * diasMes) / diaAtual)
														}
														onChange={data => {
															
														}} />
												</Td>
											))}
											{this.props.formularioValues.meses.filter(item => item.mes == this.state.mes).map(item => (
												<Td key={item.mes} alignCenter>
												<LabelAndInput
													textAlign='center'
													className='form-control'
													readOnly={'readOnly'} 
													value={`${parseInt(
														percentualVolumeA4
													)}%`}
													onChange={data => {
														
													}} />
											</Td>
											))}
											<Td rowspan={5}>
												<Table responsive>
													<TBody>
														<Tr>
															<Td colspan={2} bold>Meta Diária</Td>
															<Td colspan={2} alignCenter>
																<NumberFormat
																	style={{ textAlign: 'center' }}
																	className='form-control'
																	readOnly={'readOnly'}
																	decimalSeparator={","} 
																	thousandSeparator={'.'}
																	decimalScale={0} 
																	fixedDecimalScale={0} 
																	value={FormatUtils.formatarValorTela(mediaDiariaA4, 0)}
																	onChange={data => {
																			
																}} />
															</Td>
															<Td alignCenter></Td>
															<Td alignCenter></Td>
															<Td alignCenter></Td>
															<Td alignCenter></Td>
															<Td alignCenter></Td>
														</Tr>
														<Tr>
															<Th alignCenter>Semana</Th>
															<Th alignCenter>Dom</Th>
															<Th alignCenter>Seg</Th>
															<Th alignCenter>Ter</Th>
															<Th alignCenter>Qua</Th>
															<Th alignCenter>Qui</Th>
															<Th alignCenter>Sex</Th>
															<Th alignCenter>Sab</Th>
															<Th alignCenter>Saldo</Th>
														</Tr>

														{semanas.map(semana => {
															let metaTotalSemanal = 0;
															for (let dia = 1; dia <= diasMes; dia++) {
																//Se está na semana e não é domingo
																if (this.getSemana(`${this.props.formularioValues.exercicio}/${this.state.mes}/${dia}`) + 1 == semana
																	&& new Date(`${this.props.formularioValues.exercicio}/${this.state.mes}/${dia} 12:00`).getDay() != 0) {
																	metaTotalSemanal += mediaDiariaA4;
																}
																															
															}

															let realizadoTotalSemanal = 0;

															return (
																<Tr key={semana}>
																	<Th alignCenter>{semana}</Th>
																	{dias.map(dia => {
																		let volume = 0;
																		let mes = this.props.formularioValues.meses.filter(item => item.mes == this.state.mes)[0];
																		if (mes) {
																			let itemSemana = (mes.realizado_volume_semana_a4 || []).filter(sem => sem.semana == semana - 1)[0];
																			if (itemSemana) {
																				let itemDia = itemSemana.dias.filter(d => d.dia == dia - 1)[0];
																				volume = itemDia ? itemDia.volume : 0;
																			}
																		}

																		realizadoTotalSemanal += volume;

																		return (
																			<Td key={`${semana}-${dia}`} alignCenter>
																				<NumberFormat
																					style={{
																						textAlign: 'center', 
																						color: volume == 0 ? null : (volume < mediaDiariaA4 ? '#fff' : '#fff'),
																						backgroundColor: volume == 0 ? null : (volume < mediaDiariaA4 ? '#de3131' : '#22ba43')
																					}}
																					className='form-control'
																					readOnly={'readOnly'}
																					decimalSeparator={","} 
																					thousandSeparator={'.'}
																					decimalScale={0} 
																					fixedDecimalScale={0} 
																					value={volume}
																					onChange={data => {
																							
																					}} />
																			</Td>
																		);
																	})}
																	<Td alignCenter>
																		<NumberFormat
																			style={{ 
																				textAlign: 'center', 
																				color: (realizadoTotalSemanal - metaTotalSemanal) < 0 ? '#fff' : '#fff',
																				backgroundColor: (realizadoTotalSemanal - metaTotalSemanal) < 0 ? '#de3131' : '#22ba43'
																			}}
																			className='form-control'
																			readOnly={'readOnly'}
																			decimalSeparator={","} 
																			thousandSeparator={'.'}
																			decimalScale={0} 
																			fixedDecimalScale={0} 
																			value={FormatUtils.formatarValorTela(realizadoTotalSemanal - metaTotalSemanal, 0)}
																			onChange={data => {
																					
																			}} />
																	</Td>
																</Tr>
															)
														})}
													</TBody>
												</Table>
											</Td>
										</Tr>
										<Tr backgroundColor='#e6e6e6'>
											<Td>Margem</Td>
											{this.props.formularioValues.meses.filter(item => item.mes == this.state.mes).map(item => (
												<Td key={item.mes} alignCenter>
													<NumberFormat
														style={{ textAlign: 'center' }}
														className='form-control'
														readOnly={'readOnly'}
														decimalSeparator={","} 
														thousandSeparator={'.'}
														decimalScale={3} 
														fixedDecimalScale={3} 
														value={item.margem_a4}
														onChange={data => {
															
														}} />
												</Td>
											))}
											{this.props.formularioValues.meses.filter(item => item.mes == this.state.mes).map(item => (
												<Td key={item.mes} alignCenter>
													<NumberFormat
														style={{ textAlign: 'center' }}
														className='form-control'
														readOnly={'readOnly'}
														decimalSeparator={","} 
														thousandSeparator={'.'}
														decimalScale={3} 
														fixedDecimalScale={3} 
														value={item.realizado_margem_a4}
														onChange={data => {
															
														}} />
												</Td>
											))}
											{this.props.formularioValues.meses.filter(item => item.mes == this.state.mes).map(item => (
												<Td key={item.mes} alignCenter>
													<NumberFormat
														style={{ textAlign: 'center' }}
														className='form-control'
														readOnly={'readOnly'}
														decimalSeparator={","} 
														thousandSeparator={'.'}
														decimalScale={3} 
														fixedDecimalScale={3} 
														value={item.realizado_margem_a4}
														onChange={data => {
															
														}} />
												</Td>
											))}
											{this.props.formularioValues.meses.filter(item => item.mes == this.state.mes).map(item => (
												<Td key={item.mes} alignCenter>
													<LabelAndInput
														textAlign='center'
														className='form-control'
														readOnly={'readOnly'} 
														value={`${parseInt(
															percentualMargemA4
														)}%`}
														onChange={data => {
															
														}} />
												</Td>
											))}
										</Tr>
										<Tr backgroundColor='#e6e6e6' title={titleInadimplenciaA4}>
											<Td>Inadimplência</Td>
											{this.props.formularioValues.meses.filter(item => item.mes == this.state.mes).map(item => (
												<Td key={item.mes} alignCenter>
													<NumberFormat
														style={{ textAlign: 'center' }}
														className='form-control'
														readOnly={'readOnly'}
														decimalSeparator={","} 
														thousandSeparator={'.'}
														decimalScale={3} 
														fixedDecimalScale={3} 
														value={item.inadimplencia_a4}
														onChange={data => {
															
														}} />
												</Td>
											))}
											{this.props.formularioValues.meses.filter(item => item.mes == this.state.mes).map(item => (
												<Td key={item.mes} alignCenter>
													<NumberFormat
														style={{ textAlign: 'center' }}
														className='form-control'
														readOnly={'readOnly'}
														decimalSeparator={","} 
														thousandSeparator={'.'}
														decimalScale={3} 
														fixedDecimalScale={3} 
														value={item.realizado_inadimplencia_a4}
														onChange={data => {
															
														}} />
												</Td>
											))}
											{this.props.formularioValues.meses.filter(item => item.mes == this.state.mes).map(item => (
												<Td key={item.mes} alignCenter>
													<NumberFormat
														style={{ textAlign: 'center' }}
														className='form-control'
														readOnly={'readOnly'}
														decimalSeparator={","} 
														thousandSeparator={'.'}
														decimalScale={3} 
														fixedDecimalScale={3} 
														value={item.realizado_inadimplencia_a4}
														onChange={data => {
															
														}} />
												</Td>
											))}
											{this.props.formularioValues.meses.filter(item => item.mes == this.state.mes).map(item => (
												<Td key={item.mes} alignCenter>
													<LabelAndInput
														textAlign='center'
														className='form-control'
														readOnly={'readOnly'} 
														value={`${parseInt(
															percentualInadimplenciaA4
														)}%`}
														onChange={data => {
															
														}} />
												</Td>
											))}
										</Tr>
										<Tr backgroundColor='#e6e6e6'>
											<Td>Prazo Médio</Td>
											{this.props.formularioValues.meses.filter(item => item.mes == this.state.mes).map(item => (
												<Td key={item.mes} alignCenter>
													<NumberFormat
														style={{ textAlign: 'center' }}
														className='form-control'
														readOnly={'readOnly'}
														decimalSeparator={","} 
														thousandSeparator={'.'}
														decimalScale={1} 
														fixedDecimalScale={1} 
														value={item.prazo_a4}
														onChange={data => {
															
														}} />
												</Td>
											))}
											{this.props.formularioValues.meses.filter(item => item.mes == this.state.mes).map(item => (
												<Td key={item.mes} alignCenter>
													<NumberFormat
														style={{ textAlign: 'center' }}
														className='form-control'
														readOnly={'readOnly'}
														decimalSeparator={","} 
														thousandSeparator={'.'}
														decimalScale={1} 
														fixedDecimalScale={1} 
														value={item.realizado_prazo_a4}
														onChange={data => {
															
														}} />
												</Td>
											))}
											{this.props.formularioValues.meses.filter(item => item.mes == this.state.mes).map(item => (
												<Td key={item.mes} alignCenter>
													<NumberFormat
														style={{ textAlign: 'center' }}
														className='form-control'
														readOnly={'readOnly'}
														decimalSeparator={","} 
														thousandSeparator={'.'}
														decimalScale={1} 
														fixedDecimalScale={1} 
														value={item.realizado_prazo_a4}
														onChange={data => {
															
														}} />
												</Td>
											))}
											{this.props.formularioValues.meses.filter(item => item.mes == this.state.mes).map(item => (
												<Td key={item.mes} alignCenter>
													<LabelAndInput
														textAlign='center'
														className='form-control'
														readOnly={'readOnly'} 
														value={`${parseInt(
															percentualPrazoA4
														)}%`}
														onChange={data => {
															
														}} />
												</Td>
											))}
										</Tr>

										<Tr backgroundColor='#e6e6e6'>
											<Td bold></Td>
											<Td bold>Individual</Td>
											<Td alignCenter>
												<LabelAndInput
													textAlign='center'
													className='form-control'
													readOnly={'readOnly'} 
													value={`${resultadoIndividualA4}%`}
													onChange={data => {
														
													}} />
											</Td>
											<Td alignRight bold>
												{this.state.mes <= 3 ? null : 'Faixa'}
											</Td>
											<Td bold>
												{this.state.mes <= 3 ? null : (
												<LabelAndInput
													textAlign='center'
													className='form-control'
													readOnly={'readOnly'} 
													value={`${this.getFaixa(parseInt(resultadoIndividualA4))}%`}
													onChange={data => {
														
													}} />)}
											</Td>
										</Tr>
									</>}

								</TBody>
							</Table>
						</Row>
					</ContentCardBody>
					
					<ContentCardFooter>
						<Row alignCenter>
							<Grid cols='6 6 6 6'>
								<Button
									text='Voltar'
									type={'warning'}
									icon={'fa fa-chevron-left'}
									event={() => this.props.setModoTela('lista')} />
							</Grid>
						</Row>
					</ContentCardFooter>
					
				</Form>
			</ContentCard>
        )
    }


}

MetaVendaForm = reduxForm({form: 'metaVendaForm', destroyOnUnmount: false})(MetaVendaForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	usuarioCarregado: state.auth.usuarioCarregado,
	formularioValues: getFormValues('metaVendaForm')(state),
	registro: state.metaVenda.registro,
	listaVenda: state.metaVenda.listaVenda,
	listaCliente: state.metaVenda.listaCliente
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, initForm, importarVenda, importarInadimplencia }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(MetaVendaForm);
