import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import Form from './clienteForm';
import ProspeccaoForm from '../prospeccao/prospeccaoForm';
import { setError } from '../common/toast/toast';
import readXlsxFile from 'read-excel-file/web-worker'
import Aguardando from '../common/template/aguardando';

import { getProspeccaoAgendaHistorico, getListaUsuario, getListaPedidoHistorico, imprimirCrmHistorico } from './clienteActions';

import DateFormat from '../common/dateFormat/DateFormat';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import LabelAndTextarea from '../common/form/labelAndTextarea';
import Select from '../common/form/select';
import FormatUtils from '../common/formatUtils/FormatUtils';

class ClienteCRMHistorico extends Component {

	state = {
		dataInicial: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAtual()),
		dataFinal: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAtual()),
		id_usuario: this.props.sessao.id
	}

	componentDidMount() {

		const fileSelector = document.createElement('input');
		fileSelector.setAttribute('type', 'file');
		fileSelector.addEventListener("change", async (data) => {

			let reader = new FileReader();
			let file = data && data.path && data.path[0] && data.path[0].files && data.path[0].files[0] ? data.path[0].files[0] : null;
			if (!file) {
				file = data && data.target && data.target.files && data.target.files[0] ? data.target.files[0] : null;
			}

			if (file) {

				if (!file.type.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
					|| (file.type.includes('application/excel') || file.type.includes('application/vnd.ms-excel'))) {
					setError('Selecione apenas (.XLSX)');
					return;
				}

				let tabela = [];
				await readXlsxFile(file, { sheet: 1 }).then((rows) => {
					tabela = rows;
				});

				this.props.importar(tabela);

				reader.readAsDataURL(file);

			}
		});

		this.fileSelector = fileSelector;

	}

    componentWillMount() {
		this.props.getProspeccaoAgendaHistorico(this.state.dataInicial, this.state.dataFinal);
		this.props.getListaPedidoHistorico(this.state.dataInicial, this.state.dataFinal);
		this.props.getListaUsuario();		
	}

    render() {

		let lista = this.props.listaEventoHistorico.map(item => ({ ...item }));

		this.props.listaPedidoHistorico.forEach(item => {
			lista.push({
				...item,
				descricao: ``			
					+ (item.venda_perdida ? 'Venda Perdida' : (
						item.id_status == 1 || item.id_status == 2 ? 'Giro Aberto' : ( //Digitando, Aguardando Aprovação
							item.id_status == 3 ? 'Giro não Aprovado' : ( //Rejeitado
								item.id_status == 4 || item.id_status == 5 || item.id_status == 6 || item.id_status == 7 || item.id_status == 8 ? 'Venda Fechada' : '' //Aprovado, Logística Parcial. Logística Finalizada, Finalizado Base
							)
						)
					)) + `\n`
					+ `Giro: ${item.giro}\n`
					+ `Produto: ${item.nome_produto}\n`
					+ `Quantidade: ${FormatUtils.formatarValorTelaDecimal(item.quantidade, 0)} L\n`
					+ (item.concorrente ? `Concorrente: ${item.concorrente}\n` : '')
					+ (item.preco_concorrente ? `Preço Concorrente: ${item.preco_concorrente}\n` : ''),
				nome_tipo_retorno: (
					item.venda_perdida ? 'Venda Perdida' : (
						item.id_status == 1 || item.id_status == 2 ? 'Giro Aberto' : ( //Digitando, Aguardando Aprovação
							item.id_status == 3 ? 'Giro não Aprovado' : ( //Rejeitado
								item.id_status == 4 || item.id_status == 5 || item.id_status == 6 || item.id_status == 7 || item.id_status == 8 ? 'Venda Fechada' : '' //Aprovado, Logística Parcial. Logística Finalizada, Finalizado Base
							)
						)
					)
				),
				tipoRegistro: 'PEDIDO'
			});
		});

		lista.sort((a, b) => a.datahora_inclusao > b.datahora_inclusao ? -1 : 1);

		lista = lista.filter(item => {
			// if (this.props.usuarioCarregado && this.props.usuarioCarregado.codigo 
			// 	&& !(item.vendedores || []).includes(this.props.usuarioCarregado.codigo)
			// 	&& item.id_usuario_inclusao != this.props.usuarioCarregado.id) {
			// 	return false;
			// }
			let usuario = this.props.listaUsuario.filter(usu => usu.id == this.state.id_usuario)[0];
						
			if (usuario
				&& item.id_usuario_inclusao != usuario.id) {
				return false;
			}
			if (this.state.pesquisar
				&& !(`${item.id_petroshow} ${item.razao_social} ${item.nome_fantasia || ''} ${item.cnpj || ''} ${item.cpf || ''} ${item.nome_usuario_inclusao || ''}`).toUpperCase().includes(this.state.pesquisar.toUpperCase())) {
				return false;
			}

			if (this.state.id_tipo_retorno) {
				if (this.state.id_tipo_retorno == 999 && item.id_tipo_retorno) {
					return false;
				} else if (item.id_tipo_retorno != this.state.id_tipo_retorno) {
					return false;
				}
			}

			return true;
		});

		let listaAgrupador = [];
		lista.forEach(item => {
			if (listaAgrupador.filter(agrupador => agrupador.id == item.id_tipo_contato).length) {
				listaAgrupador = listaAgrupador.map(agrupador => ({
					...agrupador,
					quantidade: agrupador.quantidade + 1
				}));
			} else {
				listaAgrupador.push({
					id: item.id_tipo_contato,
					nome: item.nome_tipo_contato,
					quantidade: 1
				});
			}
		});

		let cabecalho = {
			usuario: this.props.listaUsuario.filter(item => item.id == this.state.id_usuario)[0] ? this.props.listaUsuario.filter(item => item.id == this.state.id_usuario)[0].valor : '',
			tipoRetorno: this.props.listaTipoRetorno.filter(item => item.id == this.state.id_tipo_retorno)[0] ? this.props.listaTipoRetorno.filter(item => item.id == this.state.id_tipo_retorno)[0].valor : '',
			dataInicial: this.state.dataInicial,
			dataFinal: this.state.dataFinal,
			dataEmissao: DateFormat.formatarDataHoraSqlParaTela(DateFormat.getDataHoraAtual())
		};
		let paginas = [{ lista: [] }];
		let altura = 0;

        return (
            <div>
                <Content>
					<Aguardando aguardando={this.props.aguardando} />

					<ContentCard>
						<ContentCardHeader>
							<Row>
								{/*<Grid cols='6 6 4 2'>
									<Button
										text='Adicionar'
										type={'success'}
										icon={'fa fa-plus'}
										event={() => {
											this.props.setModoTela('cadastro', {});
											this.props.initForm({ id_tipo: 2 });
										}} />
								</Grid>
								{this.props.listaCidade.length > 0 &&
								<Grid cols='6 6 3 2'>
									<Button
										text='Importar'
										type={'primary'}
										icon={'fa fa-chevron-left'}
										event={() => {
											if (window.confirm('Remova a linha do cabeçalho do arquivo Excel (primeira linha) para depois importar')) {
												this.fileSelector.click()
											}
										}} />
								</Grid>}*/}

								<LabelAndInputMask
									name='data_inicial'
									label='Data Inicial' placeholder='Informe a data'
									cols='12 6 2 2'
									mask='99/99/9999'
									value={this.state.dataInicial}
									onChange={data => {
										this.setState({
											...this.state,
											dataInicial: data.target.value
										});
										this.props.getProspeccaoAgendaHistorico(data.target.value, this.state.dataFinal);
										this.props.getListaPedidoHistorico(data.target.value, this.state.dataFinal);
									}} />

								<LabelAndInputMask
									name='data_final'
									label='Data Final' placeholder='Informe a data'
									cols='12 6 2 2'
									mask='99/99/9999'
									value={this.state.dataFinal}
									onChange={data => {
										this.setState({
											...this.state,
											dataFinal: data.target.value
										});

										this.props.getProspeccaoAgendaHistorico(this.state.dataInicial, data.target.value);
										this.props.getListaPedidoHistorico(this.state.dataInicial, data.target.value);
									}} />
								
								{(this.props.usuarioCarregado && this.props.listaUsuario.length > 0) &&
								<Select
									options={this.props.listaUsuario}
									label='Vendedor'
									cols='12 6 3 3'
									placeholder='Selecione o usuário' 
									value={this.state.id_usuario} 
									onChange={value => this.setState({ ...this.state, id_usuario: value })} />}
									
								<Select
									options={this.props.listaTipoRetorno}
									label='Retorno'
									cols='12 6 3 3'
									placeholder='Selecione o retorno' 
									value={this.state.id_tipo_retorno} 
									onChange={value => this.setState({ ...this.state, id_tipo_retorno: value })} />

								<div className=""></div>
								<Grid cols='6 6 3 2' style={{ marginTop: 28 }}>
							<Button
								text='Imprimir'
								type={'primary'}
								icon={'fa fa-plus'}
								event={() => {						

									this.props.imprimirCrmHistorico(cabecalho, paginas);
								}} />
						</Grid>
							</Row>
						</ContentCardHeader>
						<ContentCardHeader>
							{/*<div class='row'>
								{listaAgrupador.map(agrupador => (
									<div key={agrupador.id} class='col-sm-3 col-3'>
										<div class='description-block border-right'>
											<span class='description-percentage text-success'></span>
											<h3 class='description-header'>{agrupador.quantidade}</h3>
											<span class='description-text'>{agrupador.nome}</span>
										</div>
									</div>
								))}
							</div>*/}
						</ContentCardHeader>
						<ContentCardBody>
												
							<input className='form-control'
								placeholder='Pesquisar'
								type='text'
								value={this.state.pesquisar}
								onChange={e => {
									this.setState({ ...this.state, pesquisar: e.target.value });
								}} />

							<Table responsive>
								<THead>
									<Tr>
										<Th alignCenter>Data</Th>
										<Th alignCenter>Razão Social</Th>
										<Th alignCenter>Nome Fantasia</Th>
										<Th alignCenter>Vendedor/Representante</Th>
										<Th alignCenter>Tipo</Th>
										<Th alignCenter width={500}>Descrição</Th>
									</Tr>
								</THead>
								<TBody>
									{lista.map(item => ({ ...item, formulario_venda_perdida: item.formulario_venda_perdida ? JSON.parse(item.formulario_venda_perdida) : null })).map((item, i) => {

										paginas[paginas.length - 1].lista.push({
											data: { valor: DateFormat.formatarDataSqlParaTela(item.datahora_inclusao), backgroundColor: i % 2  == 0 ? '#f5f2f2' : '#ffffff' },
											razaoSocial: { valor: item.razao_social, backgroundColor: i % 2  == 0 ? '#f5f2f2' : '#ffffff' },
											nomeFantasia: { valor: item.nome_fantasia, backgroundColor: i % 2  == 0 ? '#f5f2f2' : '#ffffff' },
											usuario: { valor: item.nome_usuario_inclusao, backgroundColor: i % 2  == 0 ? '#f5f2f2' : '#ffffff' },
											tipo: { valor: item.nome_tipo_contato, backgroundColor: i % 2  == 0 ? '#f5f2f2' : '#ffffff' },
											descricao: { valor: item.descricao, backgroundColor: i % 2  == 0 ? '#f5f2f2' : '#ffffff' }
										});

										if (((item.descricao || '').split('\n').length || 1) == 1) {
											altura += (item.descricao || '').length < 50 ? 22 : 40;
										} else {
											altura += ((item.descricao || '').split('\n').length || 1) * 18;
										}

										if (altura > 500) {
											paginas.push({ lista: [] });
											altura = 0;
										}


										return (
											<Tr key={`${i}-${item.id}-${item.datahora_inclusao}-${item.datahora_alteracao}-${item.descricao}`} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
												onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
												onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
												title={item.formulario_venda_perdida ? 
													`Produto: ${item.formulario_venda_perdida.id_produto == 1 ? 'DIESEL S10 ADITIVADO' : (item.formulario_venda_perdida.id_produto == 3 ? 'DIESEL S500 ADITIVADO' : 'Não Informado')} \n ` +
													`Quantidade: ${FormatUtils.formatarValorTelaDecimal(item.formulario_venda_perdida.quantidade, 0)} \n ` +
													`Proposta: ${FormatUtils.formatarValorTela(item.formulario_venda_perdida.proposta, 4)} \n ` +
													`Prazo: ${FormatUtils.formatarValorTela(item.formulario_venda_perdida.prazo, 0)} \n ` +
													`Fechado: ${FormatUtils.formatarValorTela(item.formulario_venda_perdida.valor_fechado, 4)} \n ` +
													`Fornecedor: ${item.formulario_venda_perdida.fornecedor || 'Não Informado'} \n ` +
													`Observação: ${item.formulario_venda_perdida.observacao || 'Não Informada'} `
												: null} >
												<Td alignCenter>{DateFormat.formatarDataSqlParaTela(item.datahora_inclusao)}</Td>
												<Td alignCenter>{item.razao_social}</Td>
												<Td alignCenter>{item.nome_fantasia}</Td>
												<Td alignCenter>{item.nome_usuario_inclusao}</Td>
												<Td alignCenter>{item.nome_tipo_contato}</Td>											
												<Td alignCenter minWidth={500}>
													<LabelAndTextarea
														label={null} placeholder='Informe a descrição'
														cols='12 12 12 12'
														readOnly={'readOnly'}
														rows={this.state.linhaSelecionada == item.id ? 5 : 1}
														value={item.descricao}
														onChange={() => {}} />
												</Td>
											</Tr>
										);
									})}
								</TBody>
							</Table>
						</ContentCardBody>
					</ContentCard>
                </Content>
            </div>
        )
	}

}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	usuarioCarregado: state.auth.usuarioCarregado,
	modoTela: state.cliente.modoTela,
	aguardando: state.cliente.aguardando,
	listaEventoHistorico: state.cliente.listaEventoHistorico,
	listaPedidoHistorico: state.cliente.listaPedidoHistorico,
	listaUsuario: state.cliente.listaUsuario,
	listaTipoRetorno: state.cliente.listaTipoRetorno
});
const mapDispatchToProps = dispatch => bindActionCreators({ 
	getProspeccaoAgendaHistorico, getListaUsuario, getListaPedidoHistorico, imprimirCrmHistorico
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ClienteCRMHistorico);
