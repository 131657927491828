import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import Select from '../common/form/select';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndInputDate from '../common/form/labelAndInputDate';
import ButtonTable from '../common/table/buttonTable';
import { setError } from '../common/toast/toast';
import imagemAdd from '../assets/images/icone_add_imagem.png';
import pdfAdd from '../assets/images/icone_add_pdf.png';
import pdfAdicionado from '../assets/images/icone_pdf_adicionado.png';

import { setModoTela, initForm } from './metaLogisticaConfiguracaoActions';

class MetaLogisticaConfiguracaoForm extends Component {

    componentWillMount() {

    }

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

        return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>
					<ContentCardBody>
						<Row>

							<Field
								name='ano'
								component={Select}
								options={[{ id: 2024, valor: 2024 }, { id: 2025, valor: 2025 }, { id: 2026, valor: 2026 }]}
								label='Ano'
								cols='12 4 3 2'
								placeholder='Selecione o ano'
								readOnly={readOnly} />
								
							<Field
								name='trimestre'
								component={Select}
								options={[{ id: 1, valor: '1º trimestre' }, { id: 2, valor: '2º trimestre' }, { id: 3, valor: '3º trimestre' }, { id: 4, valor: '4º trimestre' } ]}
								label='Trimestre'
								cols='12 4 3 2'
								placeholder='Selecione o trimestre'
								readOnly={readOnly} />

							<Field
								name='id_usuario'
								component={Select}
								options={this.props.listaUsuario}
								label='Usuário'
								cols='12 12 12 12'
								placeholder='Selecione o usuário'
								readOnly={readOnly} />

							<Grid cols='12 12 12 12'>
								<label>Pesos</label>
							</Grid>

							<Field
								name='peso_folgas'
								component={LabelAndInputNumber}
								label='Folgas (%)' placeholder='Informe'
								cols='12 6 4 3'
								casas={2}
								readOnly={readOnly}
								title='EXECUTAR 4 FOLGAS MÍNIMAS NO MÊS EM CASA, DE PREFERENCIA UMA NO FIM DE SEMANA' />
							
							<Field
								name='peso_fechamento_motoristas'
								component={LabelAndInputNumber}
								label='Fechamento PRM (%)' placeholder='Informe'
								cols='12 6 4 3'
								casas={2}
								readOnly={readOnly}
								title='ENTREGA DE FECHAMENTO MENSAL ATÉ DIA 25 AJUSTADO (ABASTECIMENTOS, DIÁRIAS E JORNADA)' />
							
							<Field
								name='peso_jornada'
								component={LabelAndInputNumber}
								label='Jornada (%)' placeholder='Informe'
								cols='12 6 4 3'
								casas={2}
								readOnly={readOnly}
								title='JORNADA DOS MOTORISTAS DIÁRIAS ATÉ 12 HORAS COM UMA EXCEÇÃO POR MOTORISTA NO PERÍODO DE FECHAMENTO' />
							
							<Field
								name='peso_faixa_km'
								component={LabelAndInputNumber}
								label='Faixas de Km (%)' placeholder='Informe'
								cols='12 6 4 3'
								casas={2}
								readOnly={readOnly}
								title='ATINGIR A FAIXA DE KMs RODADOS' />
							
							<Field
								name='peso_documentos'
								component={LabelAndInputNumber}
								label='Documentos (%)' placeholder='Informe'
								cols='12 6 4 3'
								casas={2}
								readOnly={readOnly}
								title='ACURACIA DE DOCUMENTOS FISCAIS (MDF-e, MINUTA, CTE)' />
							
							<Field
								name='peso_performance_km'
								component={LabelAndInputNumber}
								label='Performance de Km (%)' placeholder='Informe'
								cols='12 6 4 3'
								casas={2}
								readOnly={readOnly}
								title='PERFORMACE DE KMs PLANEJADOS X PERCORRIDOS' />

							<Grid cols='12 12 12 12'>
								<label>Parâmetros</label>
							</Grid>
							
							<Field
								name='parametro_faixa_km_inicio'
								component={LabelAndInputNumber}
								label='Faixa Inicial (km)' placeholder='Informe'
								cols='12 6 4 3'
								casas={0}
								readOnly={readOnly} />
							
							<Field
								name='parametro_faixa_km_fim'
								component={LabelAndInputNumber}
								label='Faixa Final (km)' placeholder='Informe'
								cols='12 6 4 3'
								casas={0}
								readOnly={readOnly} />
							
							<Field
								name='parametro_percentual_km_exceder'
								component={LabelAndInputNumber}
								label='Percentual Km excedido (%)' placeholder='Informe'
								cols='12 6 4 3'
								casas={0}
								readOnly={readOnly} />
						
						</Row>

					</ContentCardBody>
					<ContentCardFooter>
						<Row alignCenter>
							<Grid cols='6 6 4 3'>
								{this.props.excluir ? (
									<Button
										text='Excluir'
										submit type={'danger'}
										icon={'fa fa-trash'} />
								) : (
								<Button
									text='Salvar'
									submit
									type={'success'}
									icon={'fa fa-check'} />
								)}
							</Grid>
							<Grid cols='6 6 4 3'>
								<Button
									text='Voltar'
									type={'warning'}
									icon={'fa fa-chevron-left'}
									event={() => this.props.setModoTela('lista')} />
							</Grid>
						</Row>
					</ContentCardFooter>

				</Form>
			</ContentCard>
        )
    }


}

MetaLogisticaConfiguracaoForm = reduxForm({form: 'metaLogisticaConfiguracaoForm', destroyOnUnmount: false})(MetaLogisticaConfiguracaoForm);
const mapStateToProps = state => ({
	sessao: state.auth.metaLogisticaConfiguracao,
	formularioValues: getFormValues('metaLogisticaConfiguracaoForm')(state),
	registro: state.metaLogisticaConfiguracao.registro,
	listaUsuario: state.metaLogisticaConfiguracao.listaUsuario.filter(item => item.administrador)
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, initForm }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(MetaLogisticaConfiguracaoForm);
