const INITIAL_STATE = { 
    registro: {}, 
    aguardando: false,
    modoTela: 'lista', 
    lista: [], 
    listaUsuario: [],
    listaApuracao: [],
    listaApuracaoGeral: [],
    listaApuracaoGeralDiario: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'META_VENDA_TRIMESTRE_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

        case 'META_VENDA_TRIMESTRE_LISTADO':
            return { ...state, lista: action.payload.data };

        case 'META_VENDA_TRIMESTRE_SET_AGUARDANDO':
            return { ...state, aguardando: action.payload };

        case 'META_VENDA_TRIMESTRE_USUARIO_SELECT_LISTADO':
			return {
				...state,
				listaUsuario: action.payload.data
            };
        
        case 'META_VENDA_TRIMESTRE_APURACAO_LISTADO':
			return {
				...state,
				listaApuracao: action.payload.data
            };

        case 'META_VENDA_TRIMESTRE_APURACAO_GERAL_LISTADO':
			return {
				...state,
				listaApuracaoGeral: action.payload.data
            };

        case 'META_VENDA_TRIMESTRE_APURACAO_GERAL_DIARIO_LISTADO':
			return {
				...state,
				listaApuracaoGeralDiario: action.payload.data
            };
                      
        default:
            return state;
    }
}
