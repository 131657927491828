import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';
import { useLayoutEffect } from 'react';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'PEDIDO_MODO_TELA',
        payload: {
			modo: modo,
			registro: registro
		}
    };
}

export function setAguardando(aguardando) {
    return {
        type: 'PEDIDO_SET_AGUARDANDO',
        payload: aguardando
    };
}

export function setDialogCodigoSM(registro) {
    return {
        type: 'PEDIDO_SET_DIALOG_SM',
        payload: registro
    };
}

export function initForm(registro = {}, recalculo = false) {

    if (recalculo) {
        registro = {
            ...registro,
            itens: (registro.itens || []).map(item => {

                let prazo = parseFloat(FormatUtils.formatarValorBanco(item.prazo, 0) || 0);
                let quantidade = parseFloat(FormatUtils.formatarValorBanco(item.quantidade, 2));
                let precoBasePrazo = parseFloat(item.preco_base_prazo || 0);
                let precoUnitarioBase = parseFloat(FormatUtils.formatarValorBanco(item.preco_unitario_base, 4));
                let precoUnitario = parseFloat(FormatUtils.formatarValorBanco(item.preco_unitario, 4));
                let precoPrazo = precoUnitarioBase + precoBasePrazo;
                let total = precoUnitario * quantidade;

                return {
                    ...item,
                    preco_unitario: FormatUtils.formatarValorTela(precoUnitario, 4),
                    preco_base_prazo: precoBasePrazo,
                    preco_prazo: FormatUtils.formatarValorTela(precoPrazo, 4),
                    valor_total: FormatUtils.formatarValorTela(total, 2)
                }
            })
        }
    }

    return [
        initialize('pedidoForm', registro)
    ];
}

export function initFormAtualizaPreco(registro) {

    return (dispatch, getState) => {

        let listaPoliticaPreco = getState().pedido.listaPoliticaPreco;
        let listaUnidade = getState().pedido.listaUnidade;
        let listaDistribuidora = getState().pedido.listaDistribuidora;

        //1 - OLEO DIESEL B S10 ADITIVA
        let politicaS10 = listaPoliticaPreco.filter(item => item.id_unidade == registro.id_unidade && item.id_produto == 1)[0];
        let distribuidoraS10 = politicaS10 ? listaDistribuidora.filter(item => item.id == politicaS10.id_distribuidora)[0] : null;

        //3 - OLEO DIESEL B S500 ADITIVADO
        let politicaS500 = listaPoliticaPreco.filter(item => item.id_unidade == registro.id_unidade && item.id_produto == 3)[0];
        let distribuidoraS500 = politicaS500 ? listaDistribuidora.filter(item => item.id == politicaS500.id_distribuidora)[0] : null;
        
        let itens = (registro.itens || []).filter(item => item.ativo).map((item, i) => {

            if (registro.id_status != 1) {
                return item;
            } else {            
                let politicaSelecionada = listaPoliticaPreco.filter(politica => 
                    politica.id_unidade == registro.id_unidade
                    && politica.id_produto == item.id_produto)[0];

                let precoBasePrazo = parseFloat(item.preco_base_prazo || 0);
                let precoUnitarioBase = politicaSelecionada ? parseFloat(politicaSelecionada.preco) - parseFloat(politicaSelecionada.preco_frete) : 0;
                let precoPrazo = precoUnitarioBase + precoBasePrazo;
                    
                return {
                    ...item,
                    preco_unitario_bruto: politicaSelecionada ? FormatUtils.formatarValorTela(politicaSelecionada.preco_bruto, 4) : null,
                    preco_unitario_base: FormatUtils.formatarValorTela(precoUnitarioBase, 4),
                    preco_unitario_frete: politicaSelecionada ? FormatUtils.formatarValorTela(politicaSelecionada.preco_frete, 4) : null,
                    preco_prazo: FormatUtils.formatarValorTela(precoPrazo, 4)
                };
            }
        });

        registro = {
            ...registro,
            id_distribuidora_s10: distribuidoraS10 ? distribuidoraS10.id : null,
            id_distribuidora_s500: distribuidoraS500 ? distribuidoraS500.id : null,
            custo_operacao: listaUnidade.filter(item => item.id == registro.id_unidade)[0] ? listaUnidade.filter(item => item.id == registro.id_unidade)[0].custo_operacao || 0 : 0,
            itens: itens
        }
        
        dispatch(setModoTela('cadastro', registro));
        dispatch(initForm(registro));
        dispatch(salvar(registro, true));
    };

}

export function getLista() {

    return (dispatch, getState) => {
        dispatch(setAguardando(true));
        axios.get(`${consts.API_URL}/pedido?aberto=true`).then(resp => {
            dispatch({
                type: 'PEDIDO_LISTADO',
                payload: resp
            });
            dispatch(setAguardando(false));
        }).catch(e => {
            setErroAPI(e);
            dispatch(setAguardando(false));
        });
    }
}

export function getListaLogistica() {

    return (dispatch, getState) => {
        dispatch(setAguardando(true));
        axios.get(`${consts.API_URL}/pedido?logistica=true`).then(resp => {
            dispatch({
                type: 'PEDIDO_LOGISTICA_LISTADO',
                payload: resp
            });
            dispatch(setAguardando(false));
        }).catch(e => {
            setErroAPI(e);
            dispatch(setAguardando(false));
        });
    }
}

export function getListaFinalizado(dataInicial, dataFinal) {

    return (dispatch, getState) => {
        dispatch(setAguardando(true));
        axios.get(`${consts.API_URL}/pedido?finalizado=true${dataInicial ? `&data_inicial=${DateFormat.formatarDataTelaParaParametro(dataInicial)}` : ''}${dataFinal ? `&data_final=${DateFormat.formatarDataTelaParaParametro(dataFinal)}` : ''}`).then(resp => {
            dispatch({
                type: 'PEDIDO_FINALIZADO_LISTADO',
                payload: resp
            });
            dispatch(setAguardando(false));
        }).catch(e => {
            setErroAPI(e);
            dispatch(setAguardando(false));
        });
    }
}

export function getListaEntrega(dataInicial, dataFinal) {

    return (dispatch, getState) => {
        dispatch(setAguardando(true));
        axios.get(`${consts.API_URL}/pedido?apenasEntrega=true${dataInicial ? `&data_inicial=${DateFormat.formatarDataTelaParaParametro(dataInicial)}` : ''}${dataFinal ? `&data_final=${DateFormat.formatarDataTelaParaParametro(dataFinal)}` : ''}`).then(resp => {
            dispatch({
                type: 'PEDIDO_ENTREGA_LISTADO',
                payload: resp
            });
            dispatch(setAguardando(false));
        }).catch(e => {
            setErroAPI(e);
            dispatch(setAguardando(false));
        });
    }
}

export function getListaEstoque(dataInicial, dataFinal) {

    return (dispatch, getState) => {
        dispatch(setAguardando(true));
        axios.get(`${consts.API_URL}/pedido/estoque?${dataInicial ? `&data_inicial=${DateFormat.formatarDataTelaParaParametro(dataInicial)}` : ''}${dataFinal ? `&data_final=${DateFormat.formatarDataTelaParaParametro(dataFinal)}` : ''}`).then(resp => {
            dispatch({
                type: 'PEDIDO_ESTOQUE_LISTADO',
                payload: resp
            });
            dispatch(setAguardando(false));
        }).catch(e => {
            setErroAPI(e);
            dispatch(setAguardando(false));
        });
    }
}

export function salvar(registro, manterTela = false) {
    return (dispatch, getState) => {
        dispatch(setAguardando(true));
		if (!registro.id) {
	        axios.post(`${consts.API_URL}/pedido`, {
				...registro,
				id_usuario_inclusao: getState().auth.usuario.id,
				id_usuario_alteracao: getState().auth.usuario.id,
				itens: (registro.itens || []).map(item => ({
					...item,
					quantidade: FormatUtils.formatarValorBanco(item.quantidade, 2),
                    preco_unitario_bruto: FormatUtils.formatarValorBanco(item.preco_unitario_bruto, 4),
                    preco_unitario_base: FormatUtils.formatarValorBanco(item.preco_unitario_base, 4),
                    preco_unitario_frete: FormatUtils.formatarValorBanco(item.preco_unitario_frete, 4),
					preco_unitario: FormatUtils.formatarValorBanco(item.preco_unitario, 4),
                    preco_prazo: FormatUtils.formatarValorBanco(item.preco_prazo, 4),
                    valor_total: FormatUtils.formatarValorBanco(item.valor_total, 2)
				}))
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
                dispatch(getListaLogistica());
                dispatch(getListaFinalizado(
                    DateFormat.formatarDataSqlParaTela(DateFormat.getTimestampParaData(new Date().getTime() - 2592000000)),
                    DateFormat.formatarDataSqlParaTela(DateFormat.getDataAtual())
                ));
                dispatch(setModoTela('lista'));
                dispatch(setAguardando(false));
			}).catch(e => {
                setErroAPI(e);
                dispatch(setAguardando(false));
			});
		} else {
			axios.put(`${consts.API_URL}/pedido`, {
				...registro,
				id_usuario_alteracao: getState().auth.usuario.id,
                codigo_sm_entrega: parseInt(FormatUtils.formatarValorBanco(registro.codigo_sm_entrega, 0)) || null,
                codigo_sm_retorno: parseInt(FormatUtils.formatarValorBanco(registro.codigo_sm_retorno, 0)) || null,                
				itens: (registro.itens || []).map(item => ({
					...item,
                    quantidade: FormatUtils.formatarValorBanco(item.quantidade, 2),
                    preco_unitario_bruto: FormatUtils.formatarValorBanco(item.preco_unitario_bruto, 4),
                    preco_unitario_base: FormatUtils.formatarValorBanco(item.preco_unitario_base, 4),
                    preco_unitario_frete: FormatUtils.formatarValorBanco(item.preco_unitario_frete, 4),
					preco_unitario: FormatUtils.formatarValorBanco(item.preco_unitario, 4),
                    preco_prazo: FormatUtils.formatarValorBanco(item.preco_prazo, 4),
                    valor_total: FormatUtils.formatarValorBanco(item.valor_total, 2)
				}))
			}).then(resp => {                
                if (!manterTela) {
                    setSuccess('Operação Realizada com sucesso.');
                }
                dispatch(getLista());
                dispatch(getListaLogistica());
                dispatch(getListaFinalizado(
                    DateFormat.formatarDataSqlParaTela(DateFormat.getTimestampParaData(new Date().getTime() - 2592000000)),
                    DateFormat.formatarDataSqlParaTela(DateFormat.getDataAtual())
                ));
                if (!manterTela) {
                    dispatch(setModoTela('lista'));
                }
                dispatch(setAguardando(false));
			}).catch(e => {
                setErroAPI(e);
                dispatch(setAguardando(false));
			});
		}
    };
}

export function salvarCodigoSM(registro) {
    return (dispatch, getState) => {
        dispatch(setAguardando(true));
		axios.put(`${consts.API_URL}/pedido/codigoSM`, {
			...registro,
			id_usuario_alteracao: getState().auth.usuario.id
		}).then(resp => {                
            setSuccess('Operação Realizada com sucesso.');
            dispatch(getListaEntrega());
            dispatch(setAguardando(false));
        }).catch(e => {
            setErroAPI(e);
            dispatch(setAguardando(false));
        });
    };
}

export function excluir(registro) {
    return (dispatch, getState) => {
        axios.delete(`${consts.API_URL}/pedido?id=${registro.id}&id_usuario_alteracao=${getState().auth.usuario.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista());
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function imprimir(registro, agrupar = false) {
    return (dispatch, getState) => {

        let unidade = [
            { id : 1, nome: 'ÁGUA BOA', cnpj: '15.024.193/0007-83', ie: '13.880.354-4', endereco: 'Av. Industrial, Nº 66, Bairro Industrial I \n Água Boa / MT - CEP: 78635-000' },
            { id : 3, nome: 'BARRA DO GARÇAS', cnpj: '15.024.193/0005-11', ie: '13.708.774-8', endereco: 'Rua 10 0 Quadraind 1/3 Lote 1, Distrito Industrial \n Barra do Garças / MT - CEP 78605-778' },
            { id : 9, nome: 'PORTO ALEGRE DO NORTE', cnpj: '15.024.193/0006-00', ie: '13.880.368-4', endereco: 'Av. Dionel Martins de Almeida, SN, Zona Rural \n Porto Alegre do Norte / MT - CEP 78655-000' }
        ].filter(item => item.id == registro.id_unidade)[0];

        if (agrupar) {

            let listaCliente = [];
            for (let i = 0; i < (registro.itens || []).length; i++) {
                const registroItem = (registro.itens || [])[i];
                if (!listaCliente.filter(id_cliente => registroItem.id_cliente && id_cliente == registroItem.id_cliente).length) {
                    listaCliente.push(registroItem.id_cliente);
                }
            }

            for (let i = 0; i < listaCliente.length; i++) {
                const idCliente = listaCliente[i];

                let registroEnviar = {
                    ...registro,
                    posicao: i,
                    unidade_nome: unidade ? unidade.nome : null,
                    unidade_cnpj:  unidade ? unidade.cnpj : null,
                    unidade_ie: unidade ? unidade.ie : null,
                    unidade_endereco: unidade ? unidade.endereco : null,
                    data_pedido: registro.data_pedido ? DateFormat.formatarDataSqlParaTela(registro.data_pedido) : DateFormat.formatarDataSqlParaTela(DateFormat.getDataAtual()),
                    nome_usuario_inclusao: registro.nome_usuario_inclusao || getState().auth.usuario.nome,
                    itens: (registro.itens || []).filter(item => item.id_cliente == idCliente).map(item => {

                        let cliente = getState().pedido.listaCliente.filter(itemCliente => item.id_cliente == itemCliente.id)[0];
                        let endereco = getState().pedido.listaEndereco.filter(itemEndereco => item.id_endereco == itemEndereco.id)[0];
                        let produto = getState().pedido.listaProduto.filter(itemProduto => item.id_produto == itemProduto.id)[0];
                        let usuario = getState().pedido.listaProduto.filter(itemProduto => item.id_produto == itemProduto.id)[0];

                        return {
                            ...item,
                            nome_cliente: cliente ? cliente.valor : '',
                            nome_endereco: endereco ? endereco.valor : '',
                            nome_produto: produto ? produto.valor : ''
                        }
                    })
                };
                
                axios.post(`${consts.API_URL}/pedido/imprimir`, {
                    ...registroEnviar
                }).then(resp => {
                    window.open(`${consts.URL}/${resp.data}`, '_blank');
                }).catch(e => {
                    setErroAPI(e);
                });
            }
        } else {

            let listaEndereco = [];
            for (let i = 0; i < (registro.itens || []).length; i++) {
                const registroItem = (registro.itens || [])[i];
                if (!listaEndereco.filter(id_endereco => registroItem.id_endereco && id_endereco == registroItem.id_endereco).length) {
                    listaEndereco.push(registroItem.id_endereco);
                }
            }

            for (let i = 0; i < listaEndereco.length; i++) {
                const idEndereco = listaEndereco[i];

                let registroEnviar = {
                    ...registro,
                    posicao: i,
                    unidade_nome: unidade ? unidade.nome : null,
                    unidade_cnpj:  unidade ? unidade.cnpj : null,
                    unidade_ie: unidade ? unidade.ie : null,
                    unidade_endereco: unidade ? unidade.endereco : null,
                    data_pedido: registro.data_pedido ? DateFormat.formatarDataSqlParaTela(registro.data_pedido) : DateFormat.formatarDataSqlParaTela(DateFormat.getDataAtual()),
                    nome_usuario_inclusao: registro.nome_usuario_inclusao || getState().auth.usuario.nome,
                    itens: (registro.itens || []).filter(item => item.id_endereco == idEndereco).map(item => {

                        let cliente = getState().pedido.listaCliente.filter(itemCliente => item.id_cliente == itemCliente.id)[0];
                        let endereco = getState().pedido.listaEndereco.filter(itemEndereco => item.id_endereco == itemEndereco.id)[0];
                        let produto = getState().pedido.listaProduto.filter(itemProduto => item.id_produto == itemProduto.id)[0];
                        let usuario = getState().pedido.listaProduto.filter(itemProduto => item.id_produto == itemProduto.id)[0];

                        return {
                            ...item,
                            nome_cliente: cliente ? cliente.valor : '',
                            nome_endereco: endereco ? endereco.valor : '',
                            nome_produto: produto ? produto.valor : ''
                        }
                    })
                };
                        
                axios.post(`${consts.API_URL}/pedido/imprimir`, {
                    ...registroEnviar
                }).then(resp => {
                    window.open(`${consts.URL}/${resp.data}`, '_blank');
                }).catch(e => {
                    setErroAPI(e);
                });
            }
        }
    };
}

export function getListaDistribuidora() {
    const request = axios.get(`${consts.API_URL}/distribuidora/listarSelect`);
    return {
        type: 'PEDIDO_DISTRIBUIDORA_SELECT_LISTADO',
        payload: request
    };
}

export function getListaCustoPrazo() {
    const request = axios.get(`${consts.API_URL}/custoPrazo`);
    return {
        type: 'PEDIDO_CUSTO_PRAZO_SELECT_LISTADO',
        payload: request
    };
}


export function getListaPoliticaPreco() {
    const request = axios.get(`${consts.API_URL}/politicaPreco/listarUltimaPolitica`);
    return {
        type: 'PEDIDO_POLITICA_PRECO_SELECT_LISTADO',
        payload: request
    };
}

export function getListaUnidade() {
    const request = axios.get(`${consts.API_URL}/unidade/listarSelect`);
    return {
        type: 'PEDIDO_UNIDADE_SELECT_LISTADO',
        payload: request
    };
}

export function getListaMotorista() {
    const request = axios.get(`${consts.API_URL}/motorista/listarSelect`);
    return {
        type: 'PEDIDO_MOTORISTA_SELECT_LISTADO',
        payload: request
    };
}

export function getListaVeiculo() {
    const request = axios.get(`${consts.API_URL}/veiculo/listarSelect`);
    return {
        type: 'PEDIDO_VEICULO_SELECT_LISTADO',
        payload: request
    };
}

export function getListaCliente() {
    const request = axios.get(`${consts.API_URL}/cliente/listarSelect`);
    return {
        type: 'PEDIDO_CLIENTE_SELECT_LISTADO',
        payload: request
    };
}

export function getListaEndereco() {
    const request = axios.get(`${consts.API_URL}/endereco/listarSelect`);
    return {
        type: 'PEDIDO_ENDERECO_SELECT_LISTADO',
        payload: request
    };
}

export function getListaProduto() {
    const request = axios.get(`${consts.API_URL}/produto/listarSelect?todos=true`);
    return {
        type: 'PEDIDO_PRODUTO_SELECT_LISTADO',
        payload: request
    };
}

export function gerarOrcamento(registro) {

	return (dispatch, getState) => {

		axios.post(`${consts.API_URL}/pedido/imprimirOrcamento`, {
            ...registro,
            data_pedido: DateFormat.formatarDataSqlParaTela(registro.data_pedido),
            quantidade: FormatUtils.formatarValorTela(registro.quantidade, 2)
        })
		.then(resp => {
			window.open(`${consts.URL}/${resp.data}`, '_blank');
		}).catch(ex => {
			console.log(ex);
		});

	}
}

export function verificaEstoqueManual() {
 
    return (dispatch, getState) => {
        axios.get(`${consts.API_URL}/estoqueManual/verificaEstoqueManual`).then(resp => {
            dispatch({
                type: 'PEDIDO_ESTOQUE_MANUAL_LANCADO',
                payload: resp.data || false
            });
        }).catch(e => {
            setErroAPI(e);
        });
    }
}

export function verificaAbastecida() {
 
    return (dispatch, getState) => {
        axios.get(`${consts.API_URL}/saida/verificaLancada`).then(resp => {
            dispatch({
                type: 'PEDIDO_ABASTECIDA_LANCADA',
                payload: resp.data || false
            });
        }).catch(e => {
            setErroAPI(e);
        });
    }
}

export function getListaPedidoMotorista() {

    return (dispatch, getState) => {
        dispatch(setAguardando(true));
        axios.get(`${consts.API_URL}/pedido?id_motorista=${getState().auth.usuario.id_motorista}`).then(resp => {
            dispatch({
                type: 'PEDIDO_MOTORISTA_LISTADO',
                payload: resp
            });
            dispatch(setAguardando(false));
        }).catch(e => {
            setErroAPI(e);
            dispatch(setAguardando(false));
        });
    }
}

export function alterarViagemMotorista(registro, evento) {
    return (dispatch, getState) => {
        dispatch(setAguardando(true));
		axios.put(`${consts.API_URL}/pedido/alterarViagemMotorista`, {
			...registro,
            km_inicio_viagem: FormatUtils.formatarValorBanco(registro.km_inicio_viagem, 0),
            km_fim_viagem: FormatUtils.formatarValorBanco(registro.km_fim_viagem, 0)
		}).then(resp => {                
            setSuccess('Operação Realizada com sucesso.');
            dispatch(getListaPedidoMotorista());
            dispatch(setAguardando(false));
            evento();
        }).catch(e => {
            setErroAPI(e);
            dispatch(setAguardando(false));
        });
    };
}

export function alterarViagemKm(registro, evento) {
    return (dispatch, getState) => {
        dispatch(setAguardando(true));
		axios.put(`${consts.API_URL}/pedido/alterarViagemMotorista`, {
			...registro,
            km_inicio_viagem: FormatUtils.formatarValorBanco(registro.km_inicio_viagem, 0),
            km_fim_viagem: FormatUtils.formatarValorBanco(registro.km_fim_viagem, 0)
		}).then(resp => {                
            setSuccess('Operação Realizada com sucesso.');
            dispatch(setAguardando(false));
            evento();
        }).catch(e => {
            setErroAPI(e);
            dispatch(setAguardando(false));
        });
    };
}