import DateFormat from "../common/dateFormat/DateFormat";

const INITIAL_STATE = { 
    registro: {}, 
    modoTela: 'lista', 
    lista: [], 
    listaTipo: [],
    listaMotorista: [],
    listaVeiculo: [],
    filtro: {
        data: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAtual())
    }
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'MOTORISTA_DIARIA_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

        case 'MOTORISTA_DIARIA_FILTRO':
            return { 
                ...state,
				filtro: action.payload.filtro
			};

        case 'MOTORISTA_DIARIA_LISTADO':
            return { ...state, lista: action.payload.data };

        case 'MOTORISTA_DIARIA_TIPO_SELECT_LISTADO':
			return {
				...state,
				listaTipo: action.payload.data
			};

        case 'MOTORISTA_DIARIA_MOTORISTA_SELECT_LISTADO':
			return {
				...state,
				listaMotorista: action.payload.data
			};

        case 'MOTORISTA_DIARIA_VEICULO_SELECT_LISTADO':
			return {
				...state,
				listaVeiculo: action.payload.data
			};

        default:
            return state;
    }
}
