import React from 'react';
import Menu from './menu';
import imagemLogo from '../../assets/images/sologo.png';
import MenuCliente from './menuCliente';
import MenuMotorista from './menuMotorista';

export default props => (
	<aside className="main-sidebar elevation-4 sidebar-light-indigo">
		<a href="../../" className="brand-link navbar-light">
			<img src={imagemLogo} alt="Vale da Serra Logo" className="brand-image" style={{ marginTop: -7 }} />
			<div style={{ display: 'flex', flexDirection: 'column', marginTop: 0 }}>
				<span className="brand-text font-weight-light"><b>Vale da Serra</b></span>
			</div>
		</a>
		<div className="sidebar">
			{props.id_motorista ? (
				<MenuMotorista />
			) : (
				props.id_cliente ? (
					<MenuCliente />
				) : (
					<Menu />
				)
			)}
		</div>
	</aside>
)
