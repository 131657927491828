import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import { setError } from '../common/toast/toast';
import readXlsxFile from 'read-excel-file/web-worker'
import ReactExport from 'react-export-excel-fixed-xlsx';

import {
	setModoTela, initForm, salvar, excluir, getListaMetaApuracao
} from './metaLogisticaConfiguracaoActions';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import Select from '../common/form/select';
import FormatUtils from '../common/formatUtils/FormatUtils';
import DateFormat from '../common/dateFormat/DateFormat';

class MetaLogisticaApuracao extends Component {

	state = {
		linhaSelecionada: null,
		pesquisar: '',
		ano: 2024,
		trimestre: 4
	}

    componentWillMount() {
        this.props.getListaMetaApuracao();
    }


    render() {
        return (
            <div>
                <Content>
					{this.lista()}
                </Content>
            </div>
        )
    }

	lista() {

		const ExcelFile = ReactExport.ExcelFile;
		const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
		const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
		
		return (
			<ContentCard>
				<ContentCardHeader>
					
				</ContentCardHeader>
				<ContentCardBody>
					<Row>

						<Select
							options={[{ id: 2024, valor: 2024 }, { id: 2025, valor: 2025 }, { id: 2026, valor: 2026 }]}
							label='Ano'
							cols='12 4 3 2'
							placeholder='Selecione o ano'
							value={this.state.ano}
							onChange={value => {
								this.setState({
									...this.state,
									ano: value
								});
							}} />
								
						<Select
							options={[{ id: 1, valor: '1º trimestre' }, { id: 2, valor: '2º trimestre' }, { id: 3, valor: '3º trimestre' }, { id: 4, valor: '4º trimestre' } ]}
							label='Trimestre'
							cols='12 4 3 2'
							placeholder='Selecione o trimestre'
							value={this.state.trimestre}
							onChange={value => {
								this.setState({
									...this.state,
									trimestre: value
								});
							}} />
					</Row>
					 
					{this.props.lista.filter(item => {
						if (this.state.ano && this.state.trimestre) {
							return item.ano == this.state.ano && item.trimestre == this.state.trimestre;
						}
						return false;
					}).map(item => {

						let pesoFolgas = parseFloat(item.peso_folgas || 0);
						let realizadoFolgas = parseFloat(item.realizado_folgas || 0);
						let sucessoFolgas = (pesoFolgas > 0 ? (realizadoFolgas * 100) / pesoFolgas : 100) >= 80
						
						let pesoFechamentoMotoristas = parseFloat(item.peso_fechamento_motoristas || 0);
						let realizadoFechamentoMotoristas = parseFloat(item.realizado_fechamento_motoristas || 0);
						let sucessoFechamentoMotoristas = (pesoFechamentoMotoristas > 0 ? (realizadoFechamentoMotoristas * 100) / pesoFechamentoMotoristas : 100) >= 80
						
						let pesoJornada = parseFloat(item.peso_jornada || 0);
						let realizadoJornada = parseFloat(item.realizado_jornada || 0);
						let sucessoJornada = (pesoJornada > 0 ? (realizadoJornada * 100) / pesoJornada : 100) >= 80
						
						let pesoFaixaKm = parseFloat(item.peso_faixa_km || 0);
						let realizadoFaixaKm = parseFloat(item.realizado_faixa_km || 0);
						let sucessoFaixaKm = (pesoFaixaKm > 0 ? (realizadoFaixaKm * 100) / pesoFaixaKm : 100) >= 80
						
						let pesoDocumentos = parseFloat(item.peso_documentos || 0);
						let realizadoDocumentos = parseFloat(item.realizado_documentos || 0);
						let sucessoDocumentos = (pesoDocumentos > 0 ? (realizadoDocumentos * 100) / pesoDocumentos : 100) >= 80

						let pesoPerformanceKm = parseFloat(item.peso_performance_km || 0);
						let realizadoPerformanceKm = parseFloat(item.realizado_performance_km || 0);
						let sucessoPerformanceKm = (pesoPerformanceKm > 0 ? (realizadoPerformanceKm * 100) / pesoPerformanceKm : 100) >= 80

						

						return (
							<Table responsive key={item.id}>
								<THead>
									<Tr backgroundColor={'#dfe2f5'}>
										<Th fontSize={18}>{item.nome_usuario}</Th>
										<Th alignCenter fontSize={18}>Peso</Th>
										<Th alignCenter fontSize={18}>Resultado</Th>
									</Tr>
								</THead>
								<TBody>								
									<Tr>
										<Td width={500}>EXECUTAR 4 FOLGAS MÍNIMAS NO MÊS EM CASA, DE PREFERENCIA 01 NO FIM DE SEMANA</Td>
										<Td width={100} alignCenter fontSize={24}>{FormatUtils.formatarValorTela(item.peso_folgas, 2)}%</Td>
										<Td width={100} alignCenter fontSize={24} color={'#ffffff'} backgroundColor={sucessoFolgas ? '#15a100' : '#ed0202'}>{FormatUtils.formatarValorTela(item.realizado_folgas, 2)}%</Td>
									</Tr>
									<Tr>
										<Td width={500}>ENTREGA DE FECHAMENTO MENSAL ATÉ DIA 25 AJUSTADO (ABASTECIMENTO, DIÁRIAS E JORNADA)</Td>
										<Td width={100} alignCenter fontSize={24}>{FormatUtils.formatarValorTela(item.peso_fechamento_motoristas, 2)}%</Td>
										<Td width={100} alignCenter fontSize={24} color={'#ffffff'} backgroundColor={sucessoFechamentoMotoristas ? '#15a100' : '#ed0202'}>{FormatUtils.formatarValorTela(item.realizado_fechamento_motoristas, 2)}%</Td>
									</Tr>
									<Tr>
										<Td width={500}>JORNADA DOS MOTORISTAS DIÁRIAS ATÉ 12 HORAS COM 01 EXCESSÃO POR MOTORISTA NO PERIODO DE FECHAMENTO</Td>
										<Td width={100} alignCenter fontSize={24}>{FormatUtils.formatarValorTela(item.peso_jornada, 2)}%</Td>
										<Td width={100} alignCenter fontSize={24} color={'#ffffff'} backgroundColor={sucessoJornada ? '#15a100' : '#ed0202'}>{FormatUtils.formatarValorTela(item.realizado_jornada, 2)}%</Td>
									</Tr>
									<Tr>
										<Td width={500}>FAIXAS DE KM</Td>
										<Td width={100} alignCenter fontSize={24}>{FormatUtils.formatarValorTela(item.peso_faixa_km, 2)}%</Td>
										<Td width={100} alignCenter fontSize={24} color={'#ffffff'} backgroundColor={sucessoFaixaKm ? '#15a100' : '#ed0202'}>{FormatUtils.formatarValorTela(item.realizado_faixa_km, 2)}%</Td>
									</Tr>
									<Tr>
										<Td width={500}>ACURACIA DE DOCUMENTOS FISCAIS (MDF-e, MINUTA, CTE)</Td>
										<Td width={100} alignCenter fontSize={24}>{FormatUtils.formatarValorTela(item.peso_documentos, 2)}%</Td>
										<Td width={100} alignCenter fontSize={24} color={'#ffffff'} backgroundColor={sucessoDocumentos ? '#15a100' : '#ed0202'}>{FormatUtils.formatarValorTela(item.realizado_documentos, 2)}%</Td>
									</Tr>
									<Tr>
										<Td width={500}>PERFORMACE NÃO EXCEDENCIA DE KM</Td>
										<Td width={100} alignCenter fontSize={24}>{FormatUtils.formatarValorTela(item.peso_performance_km, 2)}%</Td>
										<Td width={100} alignCenter fontSize={24} color={'#ffffff'} backgroundColor={sucessoPerformanceKm ? '#15a100' : '#ed0202'}>{FormatUtils.formatarValorTela(item.realizado_performance_km, 2)}%</Td>
									</Tr>
									<Tr>
										<Td width={500} bold fontSize={24}></Td>
										<Td width={100} alignCenter bold fontSize={24}>{
											FormatUtils.formatarValorTela(
												parseFloat(item.peso_folgas)
												+ parseFloat(item.peso_fechamento_motoristas)
												+ parseFloat(item.peso_jornada)
												+ parseFloat(item.peso_faixa_km)
												+ parseFloat(item.peso_documentos)
												+ parseFloat(item.peso_performance_km)
											, 2)}%
										</Td>
										<Td width={100} alignCenter bold fontSize={24}>{
											FormatUtils.formatarValorTela(
												parseFloat(item.realizado_folgas)
												+ parseFloat(item.realizado_fechamento_motoristas)
												+ parseFloat(item.realizado_jornada)
												+ parseFloat(item.realizado_faixa_km)
												+ parseFloat(item.realizado_documentos)
												+ parseFloat(item.realizado_performance_km)
											, 2)}%
										</Td>
									</Tr>
								</TBody>
							</Table>
						);
					})}
				</ContentCardBody>
			</ContentCard>
		);
	}

}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	modoTela: state.metaLogisticaConfiguracao.modoTela,
	filtro: state.metaLogisticaConfiguracao.filtro,
	lista: state.metaLogisticaConfiguracao.listaMetaApuracao,
	listaApuracao: state.metaLogisticaConfiguracao.listaApuracao
});
const mapDispatchToProps = dispatch => bindActionCreators({ 
	setModoTela, initForm, salvar, excluir,
	getListaMetaApuracao
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(MetaLogisticaApuracao);
