import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import Form from './metaHeitorSemestreForm';
import ReactExport from 'react-export-excel-fixed-xlsx';
import LabelAndInputMask from '../common/form/labelAndInputMask';

import { 
	setModoTela, initForm, salvar, excluir, 
	getLista, getListaUsuario
} from './metaHeitorSemestreActions';
import FormatUtils from '../common/formatUtils/FormatUtils';
import DateFormat from '../common/dateFormat/DateFormat';
import Select from '../common/form/select';
import consts from '../consts';

class MetaHeitorSemestre extends Component {

	state = {
		linhaSelecionada: null,
		pesquisar: ''
	}

    componentWillMount() {
        this.props.getLista();
		this.props.getListaUsuario();
	}
	
	componentWillUnmount() {
	
	}

    render() {
        return (
            <div>
                <Content>
					{this.props.modoTela == 'cadastro' ? (
						<Form onSubmit={this.props.salvar} />
					) : null}
					{this.props.modoTela == 'exclusao' ? (
						<Form excluir onSubmit={this.props.excluir} />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
                </Content>
            </div>
        )
    }

	lista() {
	
		return (
			<ContentCard>
				<ContentCardHeader>
					<Row>
						<Grid cols='6 6 4 2'>
							<Button
								text='Adicionar'
								type={'success'}
								icon={'fa fa-plus'}
								event={() => {
									this.props.setModoTela('cadastro', {});
									this.props.initForm({});
								}} />
						</Grid>
					</Row>

				</ContentCardHeader>
				<ContentCardBody>

					<input className='form-control'
						 placeholder='Pesquisar'
						 type='text'
						 value={this.state.pesquisar}
						 onChange={e => {
							 this.setState({ ...this.state, pesquisar: e.target.value });
						 }} />
					<Table responsive>
						<THead>
							<Tr>
								<Th alignCenter>Ano</Th>
								<Th alignCenter>Semestre</Th>
								<Th alignCenter>Nome</Th>
								<Th></Th>
							</Tr>
						</THead>
						<TBody>
							{(this.props.lista).filter(item => {
								if (this.state.pesquisar
									&& !(`${DateFormat.formatarDataSqlParaTela(item.ano)}  ${item.semestre || ''} ${item.nome_usuario || ''}`).toUpperCase().includes(this.state.pesquisar.toUpperCase())) {
									return false;
								}
								return true;
							}).map(item => (
								<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
									onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
									onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
									<Td alignCenter>{item.ano}</Td>
									<Td alignCenter>{item.semestre}</Td>
									<Td alignCenter>{item.nome}</Td>
									<Td alignRight minWidth={100}>
										<ButtonTable
											type={'warning'}
											icon={'fas fa-pencil-alt'}
											visible={this.state.linhaSelecionada == item.id}
											event={() => {
												this.props.setModoTela('cadastro', {
													...item,
													volume_1: FormatUtils.formatarValorTela(item.volume_1 || 0, 2),
													volume_2: FormatUtils.formatarValorTela(item.volume_2 || 0, 2),
													volume_3: FormatUtils.formatarValorTela(item.volume_3 || 0, 2),
													volume_4: FormatUtils.formatarValorTela(item.volume_4 || 0, 2),
													margem_1: FormatUtils.formatarValorTela(item.margem_1 || 0, 2),
													margem_2: FormatUtils.formatarValorTela(item.margem_2 || 0, 2),
													margem_3: FormatUtils.formatarValorTela(item.margem_3 || 0, 2),
													margem_4: FormatUtils.formatarValorTela(item.margem_4 || 0, 2),
													prazo_1: FormatUtils.formatarValorTela(item.prazo_1 || 0, 2),
													prazo_2: FormatUtils.formatarValorTela(item.prazo_2 || 0, 2),
													prazo_3: FormatUtils.formatarValorTela(item.prazo_3 || 0, 2),
													prazo_4: FormatUtils.formatarValorTela(item.prazo_4 || 0, 2),
													inadimplencia_1: FormatUtils.formatarValorTela(item.inadimplencia_1 || 0, 2),
													inadimplencia_2: FormatUtils.formatarValorTela(item.inadimplencia_2 || 0, 2),
													inadimplencia_3: FormatUtils.formatarValorTela(item.inadimplencia_3 || 0, 2),
													inadimplencia_4: FormatUtils.formatarValorTela(item.inadimplencia_4 || 0, 2),
													valor_ganho_cliente_novo: FormatUtils.formatarValorTela(item.valor_ganho_cliente_novo || 0, 2),
													valor_ganho_cliente_retomada: FormatUtils.formatarValorTela(item.valor_ganho_cliente_retomada || 0, 2),
													quantidade_minima_fidelizacao: FormatUtils.formatarValorTela(item.quantidade_minima_fidelizacao || 0, 0)
												});
												this.props.initForm({
													...item,
													volume_1: FormatUtils.formatarValorTela(item.volume_1 || 0, 2),
													volume_2: FormatUtils.formatarValorTela(item.volume_2 || 0, 2),
													volume_3: FormatUtils.formatarValorTela(item.volume_3 || 0, 2),
													volume_4: FormatUtils.formatarValorTela(item.volume_4 || 0, 2),
													margem_1: FormatUtils.formatarValorTela(item.margem_1 || 0, 2),
													margem_2: FormatUtils.formatarValorTela(item.margem_2 || 0, 2),
													margem_3: FormatUtils.formatarValorTela(item.margem_3 || 0, 2),
													margem_4: FormatUtils.formatarValorTela(item.margem_4 || 0, 2),
													prazo_1: FormatUtils.formatarValorTela(item.prazo_1 || 0, 2),
													prazo_2: FormatUtils.formatarValorTela(item.prazo_2 || 0, 2),
													prazo_3: FormatUtils.formatarValorTela(item.prazo_3 || 0, 2),
													prazo_4: FormatUtils.formatarValorTela(item.prazo_4 || 0, 2),
													inadimplencia_1: FormatUtils.formatarValorTela(item.inadimplencia_1 || 0, 2),
													inadimplencia_2: FormatUtils.formatarValorTela(item.inadimplencia_2 || 0, 2),
													inadimplencia_3: FormatUtils.formatarValorTela(item.inadimplencia_3 || 0, 2),
													inadimplencia_4: FormatUtils.formatarValorTela(item.inadimplencia_4 || 0, 2),
													valor_ganho_cliente_novo: FormatUtils.formatarValorTela(item.valor_ganho_cliente_novo || 0, 2),
													valor_ganho_cliente_retomada: FormatUtils.formatarValorTela(item.valor_ganho_cliente_retomada || 0, 2),
													quantidade_minima_fidelizacao: FormatUtils.formatarValorTela(item.quantidade_minima_fidelizacao || 0, 0)
												});
											}} />

										<ButtonTable
											type={'danger'}
											icon={'fas fa-trash-alt'}
											visible={this.state.linhaSelecionada == item.id}
											event={() => {
												this.props.setModoTela('exclusao', {
													...item,
													volume_1: FormatUtils.formatarValorTela(item.volume_1 || 0, 2),
													volume_2: FormatUtils.formatarValorTela(item.volume_2 || 0, 2),
													volume_3: FormatUtils.formatarValorTela(item.volume_3 || 0, 2),
													volume_4: FormatUtils.formatarValorTela(item.volume_4 || 0, 2),
													margem_1: FormatUtils.formatarValorTela(item.margem_1 || 0, 2),
													margem_2: FormatUtils.formatarValorTela(item.margem_2 || 0, 2),
													margem_3: FormatUtils.formatarValorTela(item.margem_3 || 0, 2),
													margem_4: FormatUtils.formatarValorTela(item.margem_4 || 0, 2),
													prazo_1: FormatUtils.formatarValorTela(item.prazo_1 || 0, 2),
													prazo_2: FormatUtils.formatarValorTela(item.prazo_2 || 0, 2),
													prazo_3: FormatUtils.formatarValorTela(item.prazo_3 || 0, 2),
													prazo_4: FormatUtils.formatarValorTela(item.prazo_4 || 0, 2),
													inadimplencia_1: FormatUtils.formatarValorTela(item.inadimplencia_1 || 0, 2),
													inadimplencia_2: FormatUtils.formatarValorTela(item.inadimplencia_2 || 0, 2),
													inadimplencia_3: FormatUtils.formatarValorTela(item.inadimplencia_3 || 0, 2),
													inadimplencia_4: FormatUtils.formatarValorTela(item.inadimplencia_4 || 0, 2),
													valor_ganho_cliente_novo: FormatUtils.formatarValorTela(item.valor_ganho_cliente_novo || 0, 2),
													valor_ganho_cliente_retomada: FormatUtils.formatarValorTela(item.valor_ganho_cliente_retomada || 0, 2),
													quantidade_minima_fidelizacao: FormatUtils.formatarValorTela(item.quantidade_minima_fidelizacao || 0, 0)
												});
												this.props.initForm({
													...item,
													volume_1: FormatUtils.formatarValorTela(item.volume_1 || 0, 2),
													volume_2: FormatUtils.formatarValorTela(item.volume_2 || 0, 2),
													volume_3: FormatUtils.formatarValorTela(item.volume_3 || 0, 2),
													volume_4: FormatUtils.formatarValorTela(item.volume_4 || 0, 2),
													margem_1: FormatUtils.formatarValorTela(item.margem_1 || 0, 2),
													margem_2: FormatUtils.formatarValorTela(item.margem_2 || 0, 2),
													margem_3: FormatUtils.formatarValorTela(item.margem_3 || 0, 2),
													margem_4: FormatUtils.formatarValorTela(item.margem_4 || 0, 2),
													prazo_1: FormatUtils.formatarValorTela(item.prazo_1 || 0, 2),
													prazo_2: FormatUtils.formatarValorTela(item.prazo_2 || 0, 2),
													prazo_3: FormatUtils.formatarValorTela(item.prazo_3 || 0, 2),
													prazo_4: FormatUtils.formatarValorTela(item.prazo_4 || 0, 2),
													inadimplencia_1: FormatUtils.formatarValorTela(item.inadimplencia_1 || 0, 2),
													inadimplencia_2: FormatUtils.formatarValorTela(item.inadimplencia_2 || 0, 2),
													inadimplencia_3: FormatUtils.formatarValorTela(item.inadimplencia_3 || 0, 2),
													inadimplencia_4: FormatUtils.formatarValorTela(item.inadimplencia_4 || 0, 2),
													valor_ganho_cliente_novo: FormatUtils.formatarValorTela(item.valor_ganho_cliente_novo || 0, 2),
													valor_ganho_cliente_retomada: FormatUtils.formatarValorTela(item.valor_ganho_cliente_retomada || 0, 2),
													quantidade_minima_fidelizacao: FormatUtils.formatarValorTela(item.quantidade_minima_fidelizacao || 0, 0)
												});
											}} />
									</Td>
								</Tr>
							))}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}


}

const mapStateToProps = state => ({
	sessao: state.auth.metaHeitorSemestre,
	modoTela: state.metaHeitorSemestre.modoTela,
	lista: state.metaHeitorSemestre.lista,
	listaUsuario: state.metaHeitorSemestre.listaUsuario.filter(item => item.administrador)
});
const mapDispatchToProps = dispatch => bindActionCreators({ 
	setModoTela, initForm, salvar, excluir, 
	getLista, getListaUsuario
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(MetaHeitorSemestre);
