import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import FormatUtils from '../common/formatUtils/FormatUtils';
import DateFormat from '../common/dateFormat/DateFormat';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'META_LOGISTICA_CONFIGURACAO_MODO_TELA',
        payload: {
			modo: modo,
			registro: registro
		}
    };
}
export function initForm(registro = {}) {
    return [
        initialize('metaLogisticaConfiguracaoForm', registro)
    ];
}

export function getLista() {
    const request = axios.get(`${consts.API_URL}/metaLogisticaConfiguracao`);
    return {
        type: 'META_LOGISTICA_CONFIGURACAO_LISTADO',
        payload: request
    };
}

export function getListaMetaApuracao() {
    const request = axios.get(`${consts.API_URL}/metaLogisticaConfiguracao/apuracao`);
    return {
        type: 'META_LOGISTICA_CONFIGURACAO_APURACAO_LISTADO',
        payload: request
    };
}

export function salvar(registro) {
    return (dispatch, getState) => {

		if (!registro.id) {
	        axios.post(`${consts.API_URL}/metaLogisticaConfiguracao`, {
                ...registro,
                peso_folgas: FormatUtils.formatarValorBanco(registro.peso_folgas || 0, 2),
				peso_fechamento_motoristas: FormatUtils.formatarValorBanco(registro.peso_fechamento_motoristas || 0, 2),
				peso_jornada: FormatUtils.formatarValorBanco(registro.peso_jornada || 0, 2),
				peso_faixa_km: FormatUtils.formatarValorBanco(registro.peso_faixa_km || 0, 2),
				peso_documentos: FormatUtils.formatarValorBanco(registro.peso_documentos || 0, 2),
				peso_performance_km: FormatUtils.formatarValorBanco(registro.peso_performance_km || 0, 2),
				parametro_faixa_km_inicio: FormatUtils.formatarValorBanco(registro.parametro_faixa_km_inicio || 0, 0),
				parametro_faixa_km_fim: FormatUtils.formatarValorBanco(registro.parametro_faixa_km_fim || 0, 0),
				parametro_percentual_km_exceder: FormatUtils.formatarValorBanco(registro.parametro_percentual_km_exceder || 0, 0),
				id_usuario_inclusao: getState().auth.usuario.id,
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		} else {
			axios.put(`${consts.API_URL}/metaLogisticaConfiguracao`, {
                ...registro,
				peso_folgas: FormatUtils.formatarValorBanco(registro.peso_folgas || 0, 2),
				peso_fechamento_motoristas: FormatUtils.formatarValorBanco(registro.peso_fechamento_motoristas || 0, 2),
				peso_jornada: FormatUtils.formatarValorBanco(registro.peso_jornada || 0, 2),
				peso_faixa_km: FormatUtils.formatarValorBanco(registro.peso_faixa_km || 0, 2),
				peso_documentos: FormatUtils.formatarValorBanco(registro.peso_documentos || 0, 2),
				peso_performance_km: FormatUtils.formatarValorBanco(registro.peso_performance_km || 0, 2),
				parametro_faixa_km_inicio: FormatUtils.formatarValorBanco(registro.parametro_faixa_km_inicio || 0, 0),
				parametro_faixa_km_fim: FormatUtils.formatarValorBanco(registro.parametro_faixa_km_fim || 0, 0),
				parametro_percentual_km_exceder: FormatUtils.formatarValorBanco(registro.parametro_percentual_km_exceder || 0, 0),
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}

export function excluir(registro) {
    return (dispatch, getState) => {
        axios.delete(`${consts.API_URL}/metaLogisticaConfiguracao?id=${registro.id}&id_usuario_alteracao=${getState().auth.usuario.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista());
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function getListaUsuario() {
    const request = axios.get(`${consts.API_URL}/usuario/listarSelect`);
    return {
        type: 'META_LOGISTICA_CONFIGURACAO_USUARIO_SELECT_LISTADO',
        payload: request
    };
}