import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import Select from '../common/form/select';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import NumberFormat from 'react-number-format';
import { setError } from '../common/toast/toast';
import readXlsxFile from 'read-excel-file/web-worker'

import { setModoTela, initForm, importarVenda, importarInadimplencia, getRegistroDashboard, imprimirVendedor, imprimirGerencia } from './metaVendaActions';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import FormatUtils from '../common/formatUtils/FormatUtils';
import imagemLogo from '../assets/images/logo.png';

class MetaVendaAcompanhamentoDashboardGerencia extends Component {

	state = {
		mes: 1
	}

    componentWillMount() {
		this.props.getRegistroDashboard();
		setInterval(() => {
			this.props.getRegistroDashboard();
		}, 60000);
	}

	getSemana(data) {
		let d = new Date(data);
		let date = d.getDate();
		let day = d.getDay();

		return Math.ceil((date - 1 - day) / 7) < 0 ? 0 : Math.ceil((date - 1 - day) / 7);
	}

	getFaixa(valor) {
		let faixas = [
			{ inicio: 90 , fim: 95, valor: 15 },
			{ inicio: 95 , fim: 105, valor: 30 },
			{ inicio: 105, fim: 115, valor: 50 },
			{ inicio: 115, fim: 100000, valor: 60 }
		];
		
		let faixa = faixas.filter(item => (valor || 0) >= item.inicio && (valor || 0) < item.fim)[0];
		
		return faixa ? faixa.valor : 0;
	}

    render() {

		if (!this.props.registro || !this.props.usuarioCarregado) {
			return <div />;
		}

		let readOnly = this.props.excluir ? 'readOnly' : '';

		let listaExecicio = [];
		for (let i = 2023; i <= new Date().getFullYear() + 1; i++) {
			listaExecicio.push({
				id: i,
				valor: i
			});		
		}

		let listaCompetencia = [];
		for (let i = 2023; i <= new Date().getFullYear() + 1; i++) {
			for (let j = 1; j <= 12; j++) {
				listaCompetencia.push({
					id: `${i}${String(j).padStart(2, '0')}`,
					valor: `${String(j).padStart(2, '0')}/${i}`
				});		
			}			
		}

		let semanas = [];
		for (let i = 1; i <= 5; i++) {
			semanas.push(i);
		}

		let dias = [];
		for (let i = 1; i <= 7; i++) {
			dias.push(i);
		}

		let dataMes = new Date(`${this.props.registro.exercicio}/${String(this.state.mes).padStart(2, '0')}/01 12:00`);
		dataMes.setMonth(dataMes.getMonth() + 1);
		dataMes.setDate(dataMes.getDate() - 1);
		let diasMes = dataMes.getDate();
		let diasMesMediaDiaria = dataMes.getDate();
		let dataInicioMes = new Date(`${this.props.registro.exercicio}/${String(this.state.mes).padStart(2, '0')}/01 12:00`);
		while (this.state.mes == (dataInicioMes.getMonth() + 1)) {
			//Domingo - remove domingos
			if (dataInicioMes.getDay() == 0) {
				diasMesMediaDiaria--;
			}
			dataInicioMes.setDate(dataInicioMes.getDate() + 1);
		}

		let diaAtual = dataMes.getMonth() == new Date().getMonth() && dataMes.getFullYear() == new Date().getFullYear() ? new Date().getDate() : diasMes;
		
		// let volumeTotal = 0;
		// let volumeTotalA1 = 0;
		// let volumeTotalA2 = 0;
		// let volumeTotalA3 = 0;
		// let volumeTotalA4 = 0;
		// let mes = this.props.registro.meses.filter(item => item.mes == this.state.mes)[0];
		// if (mes) {		
		// 	(mes.realizado_volume_semana_a1 || []).forEach(itemSemana => {
		// 		itemSemana.dias.forEach(itemDia => {					
		// 			volumeTotal += itemDia ? itemDia.volume : 0;				
		// 			volumeTotalA1 += itemDia ? itemDia.volume : 0;				
		// 		});
		// 	});
		// 	(mes.realizado_volume_semana_a2 || []).forEach(itemSemana => {
		// 		itemSemana.dias.forEach(itemDia => {
		// 			volumeTotal += itemDia ? itemDia.volume : 0;
		// 			volumeTotalA2 += itemDia ? itemDia.volume : 0;
		// 		});
		// 	});
		// 	(mes.realizado_volume_semana_a3 || []).forEach(itemSemana => {
		// 		itemSemana.dias.forEach(itemDia => {
		// 			volumeTotal += itemDia ? itemDia.volume : 0;
		// 			volumeTotalA3 += itemDia ? itemDia.volume : 0;
		// 		});
		// 	});
		// 	(mes.realizado_volume_semana_a4 || []).forEach(itemSemana => {
		// 		itemSemana.dias.forEach(itemDia => {
		// 			volumeTotal += itemDia ? itemDia.volume : 0;
		// 			volumeTotalA4 += itemDia ? itemDia.volume : 0;
		// 		});
		// 	});
		// }

		

		let acessoGeral = this.props.usuarioCarregado && (this.props.usuarioCarregado.id_perfil == 2 || this.props.usuarioCarregado.acesso_meta_motorista); //ADMINISTRADOR
		let acessoA1 = this.props.registro.nome_a1 == this.props.usuarioCarregado.nome || acessoGeral;
		let acessoA2 = this.props.registro.nome_a2 == this.props.usuarioCarregado.nome || acessoGeral;
		let acessoA3 = this.props.registro.nome_a3 == this.props.usuarioCarregado.nome || acessoGeral;
		let acessoA4 = this.props.registro.nome_a4 == this.props.usuarioCarregado.nome || acessoGeral;

		let resultadoGlobalGeral = 0;
		// let resultadoGlobalMeta = 0;
		let resultadoIndividualA1 = 0
		let resultadoIndividualA2 = 0;
		let resultadoIndividualA3 = 0;
		let resultadoIndividualA4 = 0;

		let mediaDiaria = 0;
		let mediaDiariaA1 = 0;
		let mediaDiariaA2 = 0;
		let mediaDiariaA3 = 0;
		let mediaDiariaA4 = 0;

		let percentualGlobalInadimplencia = 0;
		let percentualInadimplenciaA1 = 0;
		let percentualInadimplenciaA2 = 0;
		let percentualInadimplenciaA3 = 0;
		let percentualInadimplenciaA4 = 0;

		let percentualVolumeA4 = 0;
		let percentualMargemA4 = 0;
		let percentualPrazoA4 = 0;

		let titleInadimplenciaA1 = '';
		let titleInadimplenciaA2 = '';
		let titleInadimplenciaA3 = '';
		let titleInadimplenciaA4 = '';

		this.props.registro.meses.filter(item => item.mes == this.state.mes).forEach(item => {

			mediaDiaria = parseFloat(FormatUtils.formatarValorBanco(item.volume_total, 0)) / diasMesMediaDiaria;
			mediaDiariaA1 = parseFloat(FormatUtils.formatarValorBanco(item.volume_a1, 0)) / diasMesMediaDiaria;
			mediaDiariaA2 = parseFloat(FormatUtils.formatarValorBanco(item.volume_a2, 0)) / diasMesMediaDiaria;
			mediaDiariaA3 = parseFloat(FormatUtils.formatarValorBanco(item.volume_a3, 0)) / diasMesMediaDiaria;
			mediaDiariaA4 = parseFloat(FormatUtils.formatarValorBanco(item.volume_a4, 0)) / diasMesMediaDiaria;	
				
			percentualGlobalInadimplencia = parseFloat(item.realizado_inadimplencia_global) > 0 ? (
				parseFloat(FormatUtils.formatarValorBanco(item.inadimplencia_global, 4)) > parseFloat(item.realizado_inadimplencia_global) ? (
					(((parseFloat(FormatUtils.formatarValorBanco(item.inadimplencia_global, 4)) + parseFloat(item.realizado_inadimplencia_global)) / parseFloat(FormatUtils.formatarValorBanco(item.inadimplencia_global, 4))) + 1) * 100
				) : (
					(parseFloat(FormatUtils.formatarValorBanco(item.inadimplencia_global, 4)) / parseFloat(item.realizado_inadimplencia_global)) * 100
				)
			) : 115;
			percentualGlobalInadimplencia = parseInt(percentualGlobalInadimplencia < 85 ? 85 : (percentualGlobalInadimplencia > 115 ? 115 : percentualGlobalInadimplencia));

			/*Abril - PESOS
			- Volume: 30
			- Margem: 30
			- Inadimplência: 15
			- Prazo: 25*/

			if (this.state.mes <= 3) {
				resultadoGlobalGeral = parseInt(
					(parseInt(parseFloat(FormatUtils.formatarValorBanco(item.volume_total, 0)) > 0 ?
							(parseFloat(item.realizado_volume_total) * 100) / parseFloat(FormatUtils.formatarValorBanco(item.volume_total, 0)) : 0)
					+ parseInt(parseFloat(FormatUtils.formatarValorBanco(item.margem_global, 4)) > 0 ?
							(parseFloat(item.realizado_margem_global) * 100) / parseFloat(FormatUtils.formatarValorBanco(item.margem_global, 4)) : 0)
					+ percentualGlobalInadimplencia
					+ parseInt(parseFloat(item.realizado_prazo_global) > 0 ?
							(parseFloat(FormatUtils.formatarValorBanco(item.prazo_global, 4)) * 100) / parseFloat(item.realizado_prazo_global) : 0)
					) / 4
				);
			} else {
				resultadoGlobalGeral = parseInt(
					((parseInt(parseFloat(FormatUtils.formatarValorBanco(item.volume_total, 0)) > 0 ?
							(parseFloat(item.realizado_volume_total) * 100) / parseFloat(FormatUtils.formatarValorBanco(item.volume_total, 0)) : 0) * 0.3)
					+ parseInt((parseFloat(FormatUtils.formatarValorBanco(item.margem_global, 4)) > 0 ?
							(parseFloat(item.realizado_margem_global) * 100) / parseFloat(FormatUtils.formatarValorBanco(item.margem_global, 4)) : 0) * 0.3)
					+ parseInt(percentualGlobalInadimplencia * 0.15)
					+ parseInt((parseFloat(item.realizado_prazo_global) > 0 ?
							(parseFloat(FormatUtils.formatarValorBanco(item.prazo_global, 4)) * 100) / parseFloat(item.realizado_prazo_global) : 0) * 0.25)
					)
				);
			}
			// resultadoGlobalMeta = parseInt(
			// 	(parseInt(parseFloat(FormatUtils.formatarValorBanco(item.volume_total, 0)) > 0 ?
			// 			(parseFloat(item.realizado_volume_total_meta) * 100) / parseFloat(FormatUtils.formatarValorBanco(item.volume_total, 0)) : 0)
			// 	+ parseInt(parseFloat(FormatUtils.formatarValorBanco(item.margem_global, 4)) > 0 ?
			// 			(parseFloat(item.realizado_margem_global_meta) * 100) / parseFloat(FormatUtils.formatarValorBanco(item.margem_global, 4)) : 0)
			// 	+ percentualGlobalInadimplencia
			// 	+ parseInt(parseFloat(item.realizado_prazo_global_meta) > 0 ?
			// 			(parseFloat(FormatUtils.formatarValorBanco(item.prazo_global, 4)) * 100) / parseFloat(item.realizado_prazo_global_meta) : 0)
			// 	) / 3
			// );
			
			percentualInadimplenciaA1 = parseFloat(FormatUtils.formatarValorBanco(item.realizado_inadimplencia_a1, 4)) > 0 ? (
				parseFloat(FormatUtils.formatarValorBanco(item.inadimplencia_a1, 4)) > parseFloat(FormatUtils.formatarValorBanco(item.realizado_inadimplencia_a1, 4)) ? (
					(((parseFloat(FormatUtils.formatarValorBanco(item.inadimplencia_a1, 4)) + parseFloat(FormatUtils.formatarValorBanco(item.realizado_inadimplencia_a1, 4))) / parseFloat(FormatUtils.formatarValorBanco(item.inadimplencia_a1, 4))) + 1) * 100
				) : (
					(parseFloat(FormatUtils.formatarValorBanco(item.inadimplencia_a1, 4)) / parseFloat(FormatUtils.formatarValorBanco(item.realizado_inadimplencia_a1, 4))) * 100
				)
			) : 115;
			percentualInadimplenciaA1 = parseInt(percentualInadimplenciaA1 < 85 ? 85 : (percentualInadimplenciaA1 > 115 ? 115 : percentualInadimplenciaA1));

			if (this.state.mes <= 3) {
				resultadoIndividualA1 = parseInt(
					(parseInt(parseFloat(FormatUtils.formatarValorBanco(item.volume_a1, 0)) > 0 ?
							(parseFloat(item.realizado_volume_a1) * 100) / parseFloat(FormatUtils.formatarValorBanco(item.volume_a1, 0)) : 0)
					+ parseInt(parseFloat(FormatUtils.formatarValorBanco(item.margem_a1, 4)) > 0 ?
							(parseFloat(FormatUtils.formatarValorBanco(item.realizado_margem_a1, 4)) * 100) / parseFloat(FormatUtils.formatarValorBanco(item.margem_a1, 4)) : 0)
					+ percentualInadimplenciaA1
					+ parseInt(parseFloat(item.realizado_prazo_a1) > 0 ?
							(parseFloat(FormatUtils.formatarValorBanco(item.prazo_a1, 4)) * 100) / parseFloat(item.realizado_prazo_a1) : 0)
					) / 4
				);
			} else {
				resultadoIndividualA1 = parseInt(
					(parseInt((parseFloat(FormatUtils.formatarValorBanco(item.volume_a1, 0)) > 0 ?
							(parseFloat(item.realizado_volume_a1) * 100) / parseFloat(FormatUtils.formatarValorBanco(item.volume_a1, 0)) : 0) * 0.3)
					+ parseInt((parseFloat(FormatUtils.formatarValorBanco(item.margem_a1, 4)) > 0 ?
							(parseFloat(FormatUtils.formatarValorBanco(item.realizado_margem_a1, 4)) * 100) / parseFloat(FormatUtils.formatarValorBanco(item.margem_a1, 4)) : 0) * 0.3)
					+ parseInt(percentualInadimplenciaA1 * 0.15)
					+ parseInt((parseFloat(item.realizado_prazo_a1) > 0 ?
							(parseFloat(FormatUtils.formatarValorBanco(item.prazo_a1, 4)) * 100) / parseFloat(item.realizado_prazo_a1) : 0) * 0.25)
					)
				);
			}

			percentualInadimplenciaA2 = parseFloat(FormatUtils.formatarValorBanco(item.realizado_inadimplencia_a2, 4)) > 0 ? (
				parseFloat(FormatUtils.formatarValorBanco(item.inadimplencia_a2, 4)) > parseFloat(FormatUtils.formatarValorBanco(item.realizado_inadimplencia_a2, 4)) ? (
					(((parseFloat(FormatUtils.formatarValorBanco(item.inadimplencia_a2, 4)) + parseFloat(FormatUtils.formatarValorBanco(item.realizado_inadimplencia_a2, 4))) / parseFloat(FormatUtils.formatarValorBanco(item.inadimplencia_a2, 4))) + 1) * 100
				) : (
					(parseFloat(FormatUtils.formatarValorBanco(item.inadimplencia_a2, 4)) / parseFloat(FormatUtils.formatarValorBanco(item.realizado_inadimplencia_a2, 4))) * 100
				)
			) : 115;
			percentualInadimplenciaA2 = parseInt(percentualInadimplenciaA2 < 85 ? 85 : (percentualInadimplenciaA2 > 115 ? 115 : percentualInadimplenciaA2));

			if (this.state.mes <= 3) {
				resultadoIndividualA2 = parseInt(
					(parseInt(parseFloat(FormatUtils.formatarValorBanco(item.volume_a2, 0)) > 0 ?
							(parseFloat(item.realizado_volume_a2) * 100) / parseFloat(FormatUtils.formatarValorBanco(item.volume_a2, 0)) : 0)
					+ parseInt(parseFloat(FormatUtils.formatarValorBanco(item.margem_a2, 4)) > 0 ?
							(parseFloat(FormatUtils.formatarValorBanco(item.realizado_margem_a2, 4)) * 100) / parseFloat(FormatUtils.formatarValorBanco(item.margem_a2, 4)) : 0)
					+ percentualInadimplenciaA2
					+ parseInt(parseFloat(item.realizado_prazo_a2) > 0 ?
							(parseFloat(FormatUtils.formatarValorBanco(item.prazo_a2, 4)) * 100) / parseFloat(item.realizado_prazo_a2) : 0)
					) / 4
				);
			} else {
				resultadoIndividualA2 = parseInt(
					(parseInt((parseFloat(FormatUtils.formatarValorBanco(item.volume_a2, 0)) > 0 ?
							(parseFloat(item.realizado_volume_a2) * 100) / parseFloat(FormatUtils.formatarValorBanco(item.volume_a2, 0)) : 0) * 0.3)
					+ parseInt((parseFloat(FormatUtils.formatarValorBanco(item.margem_a2, 4)) > 0 ?
							(parseFloat(FormatUtils.formatarValorBanco(item.realizado_margem_a2, 4)) * 100) / parseFloat(FormatUtils.formatarValorBanco(item.margem_a2, 4)) : 0) * 0.3)
					+ parseInt(percentualInadimplenciaA2 * 0.15)
					+ parseInt((parseFloat(item.realizado_prazo_a2) > 0 ?
							(parseFloat(FormatUtils.formatarValorBanco(item.prazo_a2, 4)) * 100) / parseFloat(item.realizado_prazo_a2) : 0) * 0.25)
					)
				);
			}

			percentualInadimplenciaA3 = parseFloat(FormatUtils.formatarValorBanco(item.realizado_inadimplencia_a3, 4)) > 0 ? (
				parseFloat(FormatUtils.formatarValorBanco(item.inadimplencia_a3, 4)) > parseFloat(FormatUtils.formatarValorBanco(item.realizado_inadimplencia_a3, 4)) ? (
					(((parseFloat(FormatUtils.formatarValorBanco(item.inadimplencia_a3, 4)) + parseFloat(FormatUtils.formatarValorBanco(item.realizado_inadimplencia_a3, 4))) / parseFloat(FormatUtils.formatarValorBanco(item.inadimplencia_a3, 4))) + 1) * 100
				) : (
					(parseFloat(FormatUtils.formatarValorBanco(item.inadimplencia_a3, 4)) / parseFloat(FormatUtils.formatarValorBanco(item.realizado_inadimplencia_a3, 4))) * 100
				)
			) : 115;
			percentualInadimplenciaA3 = parseInt(percentualInadimplenciaA3 < 85 ? 85 : (percentualInadimplenciaA3 > 115 ? 115 : percentualInadimplenciaA3));
			
			if (this.state.mes <= 3) {
				resultadoIndividualA3 = parseInt(
					(parseInt(parseFloat(FormatUtils.formatarValorBanco(item.volume_a3, 0)) > 0 ?
							(parseFloat(item.realizado_volume_a3) * 100) / parseFloat(FormatUtils.formatarValorBanco(item.volume_a3, 0)) : 0)
					+ parseInt(parseFloat(FormatUtils.formatarValorBanco(item.margem_a3, 4)) > 0 ?
							(parseFloat(FormatUtils.formatarValorBanco(item.realizado_margem_a3, 4)) * 100) / parseFloat(FormatUtils.formatarValorBanco(item.margem_a3, 4)) : 0)
					+ percentualInadimplenciaA3
					+ parseInt(parseFloat(item.realizado_prazo_a3) > 0 ?
							(parseFloat(FormatUtils.formatarValorBanco(item.prazo_a3, 4)) * 100) / parseFloat(item.realizado_prazo_a3) : 0)
					) / 4
				);
			} else {
				resultadoIndividualA3 = parseInt(
					((parseInt(parseFloat(FormatUtils.formatarValorBanco(item.volume_a3, 0)) > 0 ?
							(parseFloat(item.realizado_volume_a3) * 100) / parseFloat(FormatUtils.formatarValorBanco(item.volume_a3, 0)) : 0) * 0.3)
					+ parseInt((parseFloat(FormatUtils.formatarValorBanco(item.margem_a3, 4)) > 0 ?
							(parseFloat(FormatUtils.formatarValorBanco(item.realizado_margem_a3, 4)) * 100) / parseFloat(FormatUtils.formatarValorBanco(item.margem_a3, 4)) : 0) * 0.3)
					+ parseInt(percentualInadimplenciaA3 * 0.15)
					+ parseInt((parseFloat(item.realizado_prazo_a3) > 0 ?
							(parseFloat(FormatUtils.formatarValorBanco(item.prazo_a3, 4)) * 100) / parseFloat(item.realizado_prazo_a3) : 0) * 0.25)
					)
				);
			}

			percentualInadimplenciaA4 = parseFloat(FormatUtils.formatarValorBanco(item.realizado_inadimplencia_a4, 4)) > 0 ? (
				parseFloat(FormatUtils.formatarValorBanco(item.inadimplencia_a4, 4)) > parseFloat(FormatUtils.formatarValorBanco(item.realizado_inadimplencia_a4, 4)) ? (
					(((parseFloat(FormatUtils.formatarValorBanco(item.inadimplencia_a4, 4)) + parseFloat(FormatUtils.formatarValorBanco(item.realizado_inadimplencia_a4, 4))) / parseFloat(FormatUtils.formatarValorBanco(item.inadimplencia_a4, 4))) + 1) * 100
				) : (
					(parseFloat(FormatUtils.formatarValorBanco(item.inadimplencia_a4, 4)) / parseFloat(FormatUtils.formatarValorBanco(item.realizado_inadimplencia_a4, 4))) * 100
				)
			) : 115;
			percentualInadimplenciaA4 = parseInt(percentualInadimplenciaA4 < 85 ? 85 : (percentualInadimplenciaA4 > 115 ? 115 : percentualInadimplenciaA4));
			percentualVolumeA4 = (parseFloat(FormatUtils.formatarValorBanco(item.volume_a4, 0)) > 0 ?
				(parseFloat(item.realizado_volume_a4) * 100) / parseFloat(FormatUtils.formatarValorBanco(item.volume_a4, 0)) : 0);
			percentualMargemA4 = (parseFloat(FormatUtils.formatarValorBanco(item.margem_a4, 4)) > 0 ?
				(parseFloat(FormatUtils.formatarValorBanco(item.realizado_margem_a4, 4)) * 100) / parseFloat(FormatUtils.formatarValorBanco(item.margem_a4, 4)) : 0);
			percentualPrazoA4 = (parseFloat(item.realizado_prazo_a4) > 0 ?
				(parseFloat(FormatUtils.formatarValorBanco(item.prazo_a4, 4)) * 100) / parseFloat(item.realizado_prazo_a4) : 0);


			if (this.state.mes <= 3) {
				resultadoIndividualA4 = parseInt(
					(percentualVolumeA4
					+ percentualMargemA4
					+ percentualInadimplenciaA4
					+ percentualPrazoA4
					) / 4
				);
			} else {

				percentualVolumeA4 = percentualVolumeA4 < 90 ? 0 : percentualVolumeA4;
				percentualMargemA4 = percentualMargemA4 < 90 ? 0 : percentualMargemA4;
				percentualInadimplenciaA4 = percentualInadimplenciaA4 < 90 ? 0 : percentualInadimplenciaA4;
				percentualPrazoA4 = percentualPrazoA4 < 90 ? 0 : percentualPrazoA4;
				
				resultadoIndividualA4 = parseInt(
					(percentualVolumeA4 * 0.3)
					+ (percentualMargemA4 * 0.3)
					+ (percentualInadimplenciaA4 * 0.1)
					+ (percentualPrazoA4 * 0.3)
				);
			}

			titleInadimplenciaA1 = (item.realizado_inadimplencia_clientes_a1 || []).map(cli => `${cli.id_petroshow} - ${cli.razao_social}: R$ ${FormatUtils.formatarValorTela(cli.valor, 2)}`).join('\n');
			titleInadimplenciaA2 = (item.realizado_inadimplencia_clientes_a2 || []).map(cli => `${cli.id_petroshow} - ${cli.razao_social}: R$ ${FormatUtils.formatarValorTela(cli.valor, 2)}`).join('\n');
			titleInadimplenciaA3 = (item.realizado_inadimplencia_clientes_a3 || []).map(cli => `${cli.id_petroshow} - ${cli.razao_social}: R$ ${FormatUtils.formatarValorTela(cli.valor, 2)}`).join('\n');
			titleInadimplenciaA4 =  (item.realizado_inadimplencia_clientes_a4 || []).map(cli => `${cli.id_petroshow} - ${cli.razao_social}: R$ ${FormatUtils.formatarValorTela(cli.valor, 2)}`).join('\n');
		});
		
		let semestreVolumeMeta = 0;
		let semestreVolumeRealizado = 0;
		let semestreVolumeProjetado = 0;
		let semestreVolumeResultado = 0;

		let semestreMargemMeta = 0;
		let semestreMargemRealizado = 0;
		let semestreMargemProjetado = 0;
		let semestreMargemResultado = 0;

		let semestreInadimplenciaMeta = 0;
		let semestreInadimplenciaRealizado = 0;
		let semestreInadimplenciaProjetado = 0;
		let semestreInadimplenciaResultado = 0;

		let semestrePrazoMeta = 0;
		let semestrePrazoRealizado = 0;
		let semestrePrazoProjetado = 0;
		let semestrePrazoResultado = 0;

		let qtdMeses = 0;

		//Semestral
		this.props.registro.meses.filter(item => 
			this.state.mes <= 6 
			? item.mes <= this.state.mes
			: item.mes > 6 && item.mes <= this.state.mes
		).forEach(item => {

			qtdMeses++;

			let dataMes = new Date(`${this.props.registro.exercicio}/${String(item.mes).padStart(2, '0')}/01 12:00`);
			dataMes.setMonth(dataMes.getMonth() + 1);
			dataMes.setDate(dataMes.getDate() - 1);
			let diasMes = dataMes.getDate();
			let diaAtual = dataMes.getMonth() == new Date().getMonth() && dataMes.getFullYear() == new Date().getFullYear() ? new Date().getDate() : diasMes;

			let mesMeta = parseFloat(FormatUtils.formatarValorBanco(item.volume_total, 0));
			semestreVolumeMeta += mesMeta;
			let mesRealizado = parseFloat(item.realizado_volume_total);
			semestreVolumeRealizado += mesRealizado;
			let mesProjetado = parseInt((mesRealizado * diasMes) / diaAtual);
			semestreVolumeProjetado += mesProjetado;			

			semestreMargemMeta += parseFloat(FormatUtils.formatarValorBanco(item.margem_global, 4)) * mesRealizado;
			semestreMargemRealizado += parseFloat(item.realizado_margem_global) * mesRealizado;
			semestreMargemProjetado += parseFloat(item.realizado_margem_global) * mesRealizado;

			semestreInadimplenciaMeta += parseFloat(FormatUtils.formatarValorBanco(item.inadimplencia_global, 4));
			semestreInadimplenciaRealizado += parseFloat(item.realizado_inadimplencia_global);
			semestreInadimplenciaProjetado += parseFloat(item.realizado_inadimplencia_global);

			semestrePrazoMeta += parseFloat(FormatUtils.formatarValorBanco(item.prazo_global, 4)) * mesRealizado;
			semestrePrazoRealizado += parseFloat(item.realizado_prazo_global) * mesRealizado;
			semestrePrazoProjetado += parseFloat(item.realizado_prazo_global) * mesRealizado;
		});

		semestreVolumeResultado = parseInt(semestreVolumeMeta > 0 ? (semestreVolumeProjetado * 100) / semestreVolumeMeta : 0);

		//Faz a média ponderada
		semestreMargemMeta = semestreMargemMeta / semestreVolumeRealizado;
		semestreMargemRealizado = semestreMargemRealizado / semestreVolumeRealizado;
		semestreMargemProjetado = semestreMargemProjetado / semestreVolumeRealizado;
		semestreMargemResultado = semestreMargemMeta > 0 ? (semestreMargemProjetado * 100) / semestreMargemMeta : 0;

		//Faz a média simples
		semestreInadimplenciaMeta = semestreInadimplenciaMeta / qtdMeses;
		semestreInadimplenciaRealizado = semestreInadimplenciaRealizado / qtdMeses;
		semestreInadimplenciaProjetado = semestreInadimplenciaProjetado / qtdMeses;
		semestreInadimplenciaResultado = semestreInadimplenciaProjetado > 0 ? (semestreInadimplenciaMeta * 100) / semestreInadimplenciaProjetado : 0;

		semestreInadimplenciaResultado = semestreInadimplenciaRealizado > 0 ? (
			semestreInadimplenciaMeta > semestreInadimplenciaRealizado ? (
				(((semestreInadimplenciaMeta + semestreInadimplenciaRealizado) / semestreInadimplenciaMeta) + 1) * 100
			) : (
				(semestreInadimplenciaMeta / semestreInadimplenciaRealizado) * 100
			)
		) : 115;
		semestreInadimplenciaResultado = parseInt(semestreInadimplenciaResultado < 85 
			? 85 : (semestreInadimplenciaResultado > 115 ? 115 : semestreInadimplenciaResultado));
		
		//Faz a média ponderada
		semestrePrazoMeta = semestrePrazoMeta / semestreVolumeRealizado;
		semestrePrazoRealizado = semestrePrazoRealizado / semestreVolumeRealizado;
		semestrePrazoProjetado = semestrePrazoProjetado / semestreVolumeRealizado;
		semestrePrazoResultado = semestrePrazoProjetado > 0 ? (semestrePrazoMeta * 100) / semestrePrazoProjetado : 0;

        return (
			<Content>
				<ContentCard>
					<Form event={this.props.handleSubmit}>
						<ContentCardBody>

							{(this.props.usuarioCarregado && this.props.usuarioCarregado.id == 51 /*TV*/) ? (
								<>
									<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginBottom: 20 }}>
										<img src={imagemLogo} style={{ width: 500, height: 160 }}/>
									</div>
									<div style={{ textAlign: 'center', marginBottom: 40 }}>
										<h4 style={{ fontSize: 24, fontWeight: 'bold', color: '#1b61ac' }}>META GERÊNCIA - TRR VALE DA SERRA</h4>
										<h5 style={{ fontSize: 18, fontWeight: 'bold', color: '#00a859' }}>NO VALE DO ARAGUAIA, COMBUSTÍVEL É VALE DA SERRA!</h5>
									</div>
								</>
							) : (
								<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginBottom: 24 }}>
									<img src={imagemLogo} style={{ width: 250, height: 79 }}/>

									<div style={{ display: 'flex', height: 79, flexDirection: 'column', marginLeft:24, justifyContent: 'center', paddingTop: 25 }}>
										<h4 style={{ fontSize: 18, fontWeight: 'bold', color: '#194682' }}>META GERÊNCIA - TRR VALE DA SERRA</h4>
										<h5 style={{ fontSize: 14, fontWeight: 'bold', color: '#194682' }}>NO VALE DO ARAGUAIA, COMBUSTÍVEL É VALE DA SERRA!</h5>
									</div>
								</div>
							)}
							
							
							<Row>
								<Grid cols='6 6 6 6'>
									<button 
										type='button'
										style={{ width: '100%' }}
										class={`btn btn-${this.state.mes == 1 ? 'info' : 'default'}`}
										onClick={() => this.setState({ ...this.state, mes: 1 })}>
										1º Semestre
									</button>
								</Grid>

								<Grid cols='6 6 6 6'>
									<button 
										type='button'
										style={{ width: '100%' }}
										class={`btn btn-${this.state.mes == 7 ? 'info' : 'default'}`}
										onClick={() => this.setState({ ...this.state, mes: 7 })}>
										2º Semestre
									</button>
								</Grid>		
							</Row>
						</ContentCardBody>						
					</Form>
				</ContentCard>

				{(this.props.sessao.id == 1 || this.props.sessao.id == 4 || this.props.sessao.id == 7) ?
					this.metaGerencial()
				: null}
			</Content>
        )
    }

	getFaixaGerencial(valor, tipo) {

		let faixas = [];

		switch (tipo) {
			case 'volume':
				if (this.state.mes == 1) {
					//PRIMEIRO SEMESTRE
					faixas = [
						{ inicio: 3500, fim: 3900, faixa: 1 },
						{ inicio: 3900, fim: 4250, faixa: 2 },
						{ inicio: 4250, fim: 4700, faixa: 3 },
						{ inicio: 4700, fim: 99999999, faixa: 4 }
					];
				} else {				
					//SEGUNDO SEMESTRE
					faixas = [
						{ inicio: 2900, fim: 3200, faixa: 1 },
						{ inicio: 3200, fim: 3450, faixa: 2 },
						{ inicio: 3450, fim: 3800, faixa: 3 },
						{ inicio: 3800, fim: 99999999, faixa: 4 }
					];
				}
				break;

			case 'margem':

				if (this.state.mes == 1) {
					//PRIMEIRO SEMESTRE
					faixas = [
						{ inicio: 0.38, fim: 0.41, faixa: 1 },
						{ inicio: 0.41, fim: 0.45, faixa: 2 },
						{ inicio: 0.45, fim: 0.49, faixa: 3 },
						{ inicio: 0.49, fim: 999999, faixa: 4 }
					];
				} else {
					//SEGUNDO SEMESTRE
					faixas = [
						{ inicio: 0.38, fim: 0.41, faixa: 1 },
						{ inicio: 0.41, fim: 0.45, faixa: 2 },
						{ inicio: 0.45, fim: 0.49, faixa: 3 },
						{ inicio: 0.49, fim: 99999999, faixa: 4 }
					];
				}

				break;

			case 'inadimplencia':

				if (this.state.mes == 1) {
					//PRIMEIRO SEMESTRE
					faixas = [
						{ inicio: 0.00, fim: 0.40, faixa: 4 },
						{ inicio: 0.40, fim: 0.45, faixa: 3 },
						{ inicio: 0.45, fim: 0.55, faixa: 2 },
						{ inicio: 0.55, fim: 0.70, faixa: 1 }
					];
				} else {
					//SEGUNDO SEMESTRE
					faixas = [
						{ inicio: 0.00, fim: 0.40, faixa: 4 },
						{ inicio: 0.40, fim: 0.45, faixa: 3 },
						{ inicio: 0.45, fim: 0.55, faixa: 2 },
						{ inicio: 0.55, fim: 0.70, faixa: 1 }
					];
				}
				break;

			case 'prazo':
				if (this.state.mes == 1) {
					//PRIMEIRO SEMESTRE
					faixas = [				
						{ inicio:  0.00, fim: 11.00, faixa: 4 },
						{ inicio: 11.00, fim: 12.00, faixa: 3 },
						{ inicio: 12.00, fim: 13.50, faixa: 2 },
						{ inicio: 13.50, fim: 15.00, faixa: 1 }
					];
				} else {
					//SEGUNDO SEMESTRE
					faixas = [				
						{ inicio:  0.00, fim: 11.00, faixa: 4 },
						{ inicio: 11.00, fim: 12.00, faixa: 3 },
						{ inicio: 12.00, fim: 13.50, faixa: 2 },
						{ inicio: 13.50, fim: 15.00, faixa: 1 }
					];
				}
				break;

			case 'ebitda':
				if (this.state.mes == 1) {
					//PRIMEIRO SEMESTRE
					faixas = [
						{ inicio: 1.5, fim: 2.0, faixa: 1 },
						{ inicio: 2.0, fim: 2.5, faixa: 2 },
						{ inicio: 2.5, fim: 3.0, faixa: 3 },
						{ inicio: 3.0, fim: 99999999, faixa: 4 }
					];
				} else {
					//SEGUNDO SEMESTRE
					faixas = [
						{ inicio: 2.0, fim: 2.5, faixa: 1 },
						{ inicio: 2.5, fim: 3.0, faixa: 2 },
						{ inicio: 3.0, fim: 3.5, faixa: 3 },
						{ inicio: 3.5, fim: 99999999, faixa: 4 }
					];
				}
				break;

			default:
				break;
		}

		let faixa = faixas.filter(item => (valor || 0) > item.inicio && (valor || 0) <= item.fim)[0];

		// console.log(valor);
		// console.log(tipo);
		// console.log(faixa);
		
		return faixa ? faixa.faixa : 0;
	}

	metaGerencial() {
		
		let volumeTotal = 0;
		let margemTotal = 0;
		let inadimplenciaTotal = 0;
		let prazoTotal = 0;
		let ebitdaTotal = 0;
		let faturamentoTotal = 0;
		let dias = 0;

		this.props.registro.meses.filter(item => this.state.mes <= 6 ? parseInt(item.mes) <= 6 : parseInt(item.mes) > 6).forEach(item => {
			// if (item.mes <= (new Date().getMonth() + 1)) {
				volumeTotal += parseFloat(item.realizado_volume_total);
				margemTotal += parseFloat(item.realizado_margem_global) * parseFloat(item.realizado_volume_total);
				inadimplenciaTotal += parseFloat(item.realizado_inadimplencia_global) * parseFloat(item.realizado_volume_total);
				prazoTotal += parseFloat(item.realizado_prazo_global) * parseFloat(item.realizado_volume_total);
				ebitdaTotal += parseFloat(item.ebitda) * parseFloat(item.faturamento);
				faturamentoTotal += parseFloat(item.faturamento);
			// }
			// if (item.mes < (new Date().getMonth() + 1)) {
				dias += 30;
			// } else if (item.mes == (new Date().getMonth() + 1)) {
			// 	dias += new Date().getDate() + 1 > 30 ? 30 : new Date().getDate() + 1;
			// }
		});

		let volumeProvisao = (volumeTotal * 180) / (6 * dias * 1000);
		let margemProvisao = margemTotal / volumeTotal;
		let inadimplenciaProvisao = inadimplenciaTotal / volumeTotal;
		let prazoProvisao = prazoTotal / volumeTotal;
		let ebitdaProvisao = ebitdaTotal / faturamentoTotal;
		
		return (			
			<ContentCard>
				<ContentCardBody>
					<Row>
						<Table responsive>
							<THead>
								<Tr>
									<Th>Metas Gerência</Th>
									{this.props.registro.meses.filter(item => this.state.mes <= 6 ? parseInt(item.mes) <= 6 : parseInt(item.mes) > 6).map(item => (
										<Th alignCenter>{String(item.mes).padStart(2, '0')}</Th>
									))}
									<Th alignCenter>Provisão</Th>
									<Th alignCenter>Faixa</Th>
								</Tr>
							</THead>
							<TBody>
								<Tr>
									<Td>Volume</Td>
									{this.props.registro.meses.filter(item => this.state.mes <= 6 ? parseInt(item.mes) <= 6 : parseInt(item.mes) > 6).map(item => (									
										<Td key={item.mes} alignCenter>
											<LabelAndInput
												textAlign='center'
												className='form-control'
												readOnly={'readOnly'} 
												value={true || item.mes <= (new Date().getMonth() + 1) ? FormatUtils.formatarValorTelaDecimal(item.realizado_volume_total, 0) : ''}
												onChange={data => {
																	
												}} />
										</Td>
									))}
									<Td alignCenter>
										<LabelAndInput
											textAlign='center'
											className='form-control'
											readOnly={'readOnly'} 
											value={FormatUtils.formatarValorTelaDecimal(volumeProvisao, 0)}
											onChange={data => {
																
											}} />
									</Td>
									<Td alignCenter>
										<LabelAndInput
											textAlign='center'
											className='form-control'
											readOnly={'readOnly'} 
											value={FormatUtils.formatarValorTelaDecimal(this.getFaixaGerencial(volumeProvisao, 'volume'), 0)}
											onChange={data => {
																
											}} />
									</Td>
								</Tr>
								<Tr>
									<Td>Margem</Td>
									{this.props.registro.meses.filter(item => this.state.mes <= 6 ? parseInt(item.mes) <= 6 : parseInt(item.mes) > 6).map(item => (									
										<Td key={item.mes} alignCenter>
											<LabelAndInput
												textAlign='center'
												className='form-control'
												readOnly={'readOnly'} 
												value={true || item.mes <= (new Date().getMonth() + 1) ? FormatUtils.formatarValorTelaDecimal(item.realizado_margem_global, 3) : ''}
												onChange={data => {
																	
												}} />
										</Td>
									))}
									<Td alignCenter>
										<LabelAndInput
											textAlign='center'
											className='form-control'
											readOnly={'readOnly'} 
											value={FormatUtils.formatarValorTelaDecimal(margemProvisao, 3)}
											onChange={data => {
																
											}} />
									</Td>
									<Td alignCenter>
										<LabelAndInput
											textAlign='center'
											className='form-control'
											readOnly={'readOnly'} 
											value={FormatUtils.formatarValorTelaDecimal(this.getFaixaGerencial(margemProvisao, 'margem'), 0)}
											onChange={data => {
																
											}} />
									</Td>
								</Tr>
								<Tr>
									<Td>Inadimplência</Td>
									{this.props.registro.meses.filter(item => this.state.mes <= 6 ? parseInt(item.mes) <= 6 : parseInt(item.mes) > 6).map(item => (									
										<Td key={item.mes} alignCenter>
											<LabelAndInput
												textAlign='center'
												className='form-control'
												readOnly={'readOnly'} 
												value={true || item.mes <= (new Date().getMonth() + 1) ? FormatUtils.formatarValorTelaDecimal(item.realizado_inadimplencia_global, 3) : ''}
												onChange={data => {
																	
												}} />
										</Td>
									))}
									<Td alignCenter>
										<LabelAndInput
											textAlign='center'
											className='form-control'
											readOnly={'readOnly'} 
											value={FormatUtils.formatarValorTelaDecimal(inadimplenciaProvisao, 3)}
											onChange={data => {
																
											}} />
									</Td>
									<Td alignCenter>
										<LabelAndInput
											textAlign='center'
											className='form-control'
											readOnly={'readOnly'} 
											value={FormatUtils.formatarValorTelaDecimal(this.getFaixaGerencial(inadimplenciaProvisao, 'inadimplencia'), 0)}
											onChange={data => {
																
											}} />
									</Td>
								</Tr>
								<Tr>
									<Td>Prazo</Td>
									{this.props.registro.meses.filter(item => this.state.mes <= 6 ? parseInt(item.mes) <= 6 : parseInt(item.mes) > 6).map(item => (									
										<Td key={item.mes} alignCenter>
											<LabelAndInput
												textAlign='center'
												className='form-control'
												readOnly={'readOnly'} 
												value={true || item.mes <= (new Date().getMonth() + 1) ? FormatUtils.formatarValorTelaDecimal(item.realizado_prazo_global, 2) : ''}
												onChange={data => {
																	
												}} />
										</Td>
									))}
									<Td alignCenter>
										<LabelAndInput
											textAlign='center'
											className='form-control'
											readOnly={'readOnly'} 
											value={FormatUtils.formatarValorTelaDecimal(prazoProvisao, 1)}
											onChange={data => {
																
											}} />
									</Td>
									<Td alignCenter>
										<LabelAndInput
											textAlign='center'
											className='form-control'
											readOnly={'readOnly'} 
											value={FormatUtils.formatarValorTelaDecimal(this.getFaixaGerencial(prazoProvisao, 'prazo'), 0)}
											onChange={data => {
																
											}} />
									</Td>
								</Tr>
								<Tr>
									<Td>Ebitda</Td>
									{this.props.registro.meses.filter(item => this.state.mes <= 6 ? parseInt(item.mes) <= 6 : parseInt(item.mes) > 6).map(item => (									
										<Td key={item.mes} alignCenter>
											<LabelAndInput
												textAlign='center'
												className='form-control'
												readOnly={'readOnly'} 
												value={true || item.mes <= (new Date().getMonth() + 1) ? FormatUtils.formatarValorTelaDecimal(item.ebitda, 2) : ''}
												onChange={data => {
																	
												}} />
										</Td>
									))}
									<Td alignCenter>
										<LabelAndInput
											textAlign='center'
											className='form-control'
											readOnly={'readOnly'} 
											value={FormatUtils.formatarValorTelaDecimal(ebitdaProvisao, 2)}
											onChange={data => {
																
											}} />
									</Td>
									<Td alignCenter>
										<LabelAndInput
											textAlign='center'
											className='form-control'
											readOnly={'readOnly'} 
											value={FormatUtils.formatarValorTelaDecimal(this.getFaixaGerencial(ebitdaProvisao, 'ebitda'), 0)}
											onChange={data => {
																
											}} />
									</Td>
								</Tr>
								<Tr>
									<Td>Faturamento</Td>
									{this.props.registro.meses.filter(item => this.state.mes <= 6 ? parseInt(item.mes) <= 6 : parseInt(item.mes) > 6).map(item => (									
										<Td key={item.mes} alignCenter>
											<LabelAndInput
												textAlign='center'
												className='form-control'
												readOnly={'readOnly'} 
												value={true || item.mes <= (new Date().getMonth() + 1) ? FormatUtils.formatarValorTelaDecimal(item.faturamento, 2) : ''}
												onChange={data => {
																	
												}} />
										</Td>
									))}
									<Td alignCenter>
										
									</Td>
									<Td alignCenter>
										
									</Td>
								</Tr>
							</TBody>
						</Table>
					</Row>
					<Row alignCenter>
						<Grid cols='12 6 4 3'>
							<Button
								text='Imprimir Resultado'
								type={'primary'}
								icon={'fa fa-plus'}
								event={() => {

									let listaVolume = [];
									let listaMargem = [];
									let listaInadimplencia = [];
									let listaPrazo = [];
									let listaEbitda = [];
									let listaFaturamento = [];

									this.props.registro.meses.filter(item => this.state.mes <= 6 ? parseInt(item.mes) <= 6 : parseInt(item.mes) > 6).map(item => {

										listaVolume.push(true || item.mes <= (new Date().getMonth() + 1) ? FormatUtils.formatarValorTelaDecimal(item.realizado_volume_total, 0) : '');
										listaMargem.push(true || item.mes <= (new Date().getMonth() + 1) ? FormatUtils.formatarValorTelaDecimal(item.realizado_margem_global, 3) : '');
										listaInadimplencia.push(true || item.mes <= (new Date().getMonth() + 1) ? FormatUtils.formatarValorTelaDecimal(item.realizado_inadimplencia_global, 3) : '');
										listaPrazo.push(true || item.mes <= (new Date().getMonth() + 1) ? FormatUtils.formatarValorTelaDecimal(item.realizado_prazo_global, 2) : '');
										listaEbitda.push(true || item.mes <= (new Date().getMonth() + 1) ? FormatUtils.formatarValorTelaDecimal(item.ebitda, 2) : '');
										listaFaturamento.push(true || item.mes <= (new Date().getMonth() + 1) ? FormatUtils.formatarValorTelaDecimal(item.faturamento, 0) : '');

									});

									listaVolume.push(FormatUtils.formatarValorTelaDecimal(volumeProvisao, 0));
									listaVolume.push(FormatUtils.formatarValorTelaDecimal(this.getFaixaGerencial(volumeProvisao, 'volume'), 0));

									listaMargem.push(FormatUtils.formatarValorTelaDecimal(margemProvisao, 3));
									listaMargem.push(FormatUtils.formatarValorTelaDecimal(this.getFaixaGerencial(margemProvisao, 'margem'), 0));

									listaInadimplencia.push(FormatUtils.formatarValorTelaDecimal(inadimplenciaProvisao, 3));
									listaInadimplencia.push(FormatUtils.formatarValorTelaDecimal(this.getFaixaGerencial(inadimplenciaProvisao, 'inadimplencia'), 0));

									listaPrazo.push(FormatUtils.formatarValorTelaDecimal(prazoProvisao, 1));
									listaPrazo.push(FormatUtils.formatarValorTelaDecimal(this.getFaixaGerencial(prazoProvisao, 'prazo'), 0));

									listaEbitda.push(FormatUtils.formatarValorTelaDecimal(ebitdaProvisao, 2));
									listaEbitda.push(FormatUtils.formatarValorTelaDecimal(this.getFaixaGerencial(ebitdaProvisao, 'ebitda'), 0));

									let lista = [{
										nome: 'Volume',
										lista: listaVolume
									}, {
										nome: 'Margem',
										lista: listaMargem
									}, {
										nome: 'Inadimplência',
										lista: listaInadimplencia
									}, {
										nome: 'Prazo',
										lista: listaPrazo
									}, {
										nome: 'Ebitda',
										lista: listaEbitda
									}, {
										nome: 'Faturamento',
										lista: listaFaturamento
									}];
									
									this.props.imprimirGerencia({
										semestre: String(this.state.mes <= 6 ? 1 : 2),
										ano: '2024',
										lista: lista
									});
								}} />
						</Grid>
					</Row>
				</ContentCardBody>
			</ContentCard>
		);
	}


}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	usuarioCarregado: state.auth.usuarioCarregado,
	registro: state.metaVenda.registroDashboard,
	listaCliente: state.metaVenda.listaCliente
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, initForm, importarVenda, importarInadimplencia, getRegistroDashboard, imprimirVendedor, imprimirGerencia }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(MetaVendaAcompanhamentoDashboardGerencia);
