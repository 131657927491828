import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import Form from './pedidoForm';
import FormatUtils from '../common/formatUtils/FormatUtils';
import Aguardando from '../common/template/aguardando';

import { 
	getListaPedidoMotorista, alterarViagemMotorista
} from './pedidoActions';
import DateFormat from '../common/dateFormat/DateFormat';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import consts from '../consts';

class PedidoMotorista extends Component {

	state = {
		linhaSelecionada: null,
		modal: null
	}

    componentWillMount() {
		this.props.getListaPedidoMotorista();

		let idInterval = setInterval(() => {
			this.props.getListaPedidoMotorista();
		}, 60000);
		
		this.setState({
			...this.state,
			idInterval: idInterval
		});
	}
	
	componentWillUnmount() {

		if (this.state.idInterval) {
			clearInterval(this.state.idInterval);
		}
	
    }

    render() {

        return (
            <div>
                <Content>
					
					<Aguardando aguardando={this.props.aguardando} />

					{this.lista()}
					
                </Content>
            </div>
        )
    }

	lista() {
		return (
			<>
			
				{this.modal()}

				{!this.props.lista.length &&
				<p style={{ fontWeight: 'bold', textAlign: 'center', marginTop: 40 }}>Nenhuma viagem encontrada</p>}
				{this.props.lista.map(item => {

					return (
						<ContentCard key={item.id} style={{ backgroundColor: '#ffffff' }}>
							<ContentCardHeader>
								<Row>
									<Grid cols='6 6 6 6'>
										<strong>Giro:</strong>
									</Grid>
									<Grid cols='6 6 6 6' style={{ textAlign: 'right' }}>
										<strong>Data/Hora Carga:</strong>
									</Grid>
								</Row>
								<Row>
									<Grid cols='6 6 6 6'>
										{item.giro}
									</Grid>
									<Grid cols='6 6 6 6' style={{ textAlign: 'right' }}>
										{DateFormat.formatarDataHoraSqlParaTela(item.datahora_entrega || item.datahora_logistica)}
									</Grid>
								</Row>
								<Row>
									<Grid cols='6 6 6 6'>
										<strong>Placa:</strong>
									</Grid>
									<Grid cols='6 6 6 6' style={{ textAlign: 'right' }}>
										<strong>Vendedor:</strong>
									</Grid>
								</Row>
								<Row>
									<Grid cols='6 6 6 6'>
										{item.placa_veiculo}
									</Grid>
									<Grid cols='6 6 6 6' style={{ textAlign: 'right' }}>
										{item.nome_usuario_inclusao}
									</Grid>
								</Row>
								{item.rota_link &&
								<Row>
									<Grid cols='6 6 6 6'>
										<strong>Link da Rota:</strong>
									</Grid>
									<Grid cols='6 6 6 6' style={{ textAlign: 'right' }}>
										<strong>Imagem da Rota:</strong>
									</Grid>
								</Row>}
								{(item.arquivos || [])[0] &&
								<Row>
									<Grid cols='6 6 6 6'>
										<button 
											type='button'
											class='btn btn-block btn-outline-secondary btn-xs'
											style={{ marginBottom: 4 }}
											onClick={() => {
												window.open(item.rota_link, '_blank');
											}}>
											Abrir no Mapa
										</button>
									</Grid>							
									<Grid cols='6 6 6 6' style={{ paddingRight: 16 }}>
										<button 
											type='button'
											class='btn btn-block btn-outline-secondary btn-xs'
											style={{ marginBottom: 4 }}
											onClick={() => {
												window.open(`${consts.URL}/${(item.arquivos || [])[0].arquivo}`, '_blank');
											}}>
											Abrir Imagem
										</button>
									</Grid>
								</Row>}
								<Row>
									<Grid cols='12 12 12 12'>
										<strong>Entregas:</strong>
									</Grid>
								</Row>
								<Row>
									{(item.itens || []).map((pedidoItem, i) => (										
										<Grid cols='12 12 12 12' key={pedidoItem.id} style={{ marginTop: 4, marginBottom: 0}}>
											<div class='card' style={{ backgroundColor: '#194682' }}>
												<div class='card-header' style={{ padding: 4 }}>
													<p style={{ fontWeight: 'bold', color: '#ffffff' }} class='card-title'>Entrega {i + 1}</p>
												</div>
												<div class='card-body' style={{ padding: 4, backgroundColor: '#cbdef7' }}>
													<p style={{ padding: 0, margin: 0, color: '#183b6b' }}><b>Cliente:</b> {pedidoItem.nome_cliente}</p>
													<p style={{ padding: 0, margin: 0, color: '#183b6b' }}><b>Endereço:</b> {pedidoItem.nome_endereco}</p>
													<p style={{ padding: 0, margin: 0, color: '#183b6b' }}><b>Produto:</b> {pedidoItem.nome_produto}</p>
													<p style={{ padding: 0, margin: 0, color: '#183b6b' }}><b>Quantidade:</b> {FormatUtils.formatarValorTela(pedidoItem.quantidade, 2)}</p>
													<p style={{ padding: 0, margin: 0, color: '#183b6b' }}><b>Obs:</b> {pedidoItem.observacao}</p>
												</div>
											</div>
										</Grid>
									))}
								</Row>
							</ContentCardHeader>

							<ContentCardBody>
								{!item.datahora_inicio_viagem ? (
									<Row alignCenter>
										<Grid cols='12 6 4 4'>
											<button 
												type='button'
												class='btn btn-block btn-success btn-lg'
												onClick={() => {
													this.setState({
														...this.state,
														modal: {
															...item,
															km_inicio_viagem: item.km_inicio_viagem ? FormatUtils.formatarValorTela(item.km_inicio_viagem, 0) : '',
															km_fim_viagem: item.km_fim_viagem ? FormatUtils.formatarValorTela(item.km_fim_viagem, 0) : '',
															tipoTela: 'inicio'
														}
													});
												}}>
												Iniciar Viagem
											</button>
										</Grid>
									</Row>
								) : (
									<div>
										<Row>
											<Grid cols='6 6 6 6'>
												<strong>Início de Viagem:</strong>
											</Grid>
											<Grid cols='6 6 6 6' style={{ textAlign: 'right' }}>
												<strong>Km de Início:</strong>
											</Grid>
										</Row>
										<Row>
											<Grid cols='6 6 6 6'>
												{DateFormat.formatarDataHoraSqlParaTela(item.datahora_inicio_viagem)}
											</Grid>
											<Grid cols='6 6 6 6' style={{ textAlign: 'right' }}>
												{FormatUtils.formatarValorTelaDecimal(item.km_inicio_viagem, 0)}
											</Grid>
										</Row>							
									</div>
								)}
								
								{(item.datahora_inicio_viagem && !item.datahora_fim_viagem) ? (
									<Row alignCenter style={{ marginTop: 16 }}>
										<Grid cols='12 6 4 4'>
											<button 
												type='button'
												class='btn btn-block btn-success btn-lg'
												onClick={() => {
													this.setState({
														...this.state,
														modal: {
															...item,
															km_fim_viagem: item.km_fim_viagem ? FormatUtils.formatarValorTela(item.km_fim_viagem, 0) : '',
															km_fim_viagem: item.km_fim_viagem ? FormatUtils.formatarValorTela(item.km_fim_viagem, 0) : '',
															tipoTela: 'fim'
														}
													});
												}}>
												Finalizar Viagem
											</button>
										</Grid>
									</Row>
								) : (item.datahora_fim_viagem ? (
									<div>
										<Row>
											<Grid cols='6 6 6 6'>
												<strong>Fim de Viagem:</strong>
											</Grid>
											<Grid cols='6 6 6 6' style={{ textAlign: 'right' }}>
												<strong>Km de Fim:</strong>
											</Grid>
										</Row>
										<Row>
											<Grid cols='6 6 6 6'>
												{DateFormat.formatarDataHoraSqlParaTela(item.datahora_fim_viagem)}
											</Grid>
											<Grid cols='6 6 6 6' style={{ textAlign: 'right' }}>
												{FormatUtils.formatarValorTelaDecimal(item.km_fim_viagem, 0)}
											</Grid>
										</Row>							
									</div>
								) : null)}
								
							</ContentCardBody>
						</ContentCard>
					);
				})}
			</>
		);
	}

	modal() {

		if (!this.state.modal) {
			return null;
		}

        return (
			<div class='modal fade show' id='modal-xl' style={{ display: 'block' }} aria-modal='true'>
				<div class='modal-dialog modal-xl'>
					<div class='modal-content'>
						<div class='modal-header'>
							<h4 class='modal-title'>Viagem</h4>
							<button type='button' class='close' data-dismiss='modal' aria-label='Close' onClick={() => this.setState({ ...this.state, modal: null })}>
								<span aria-hidden='true'>×</span>
							</button>
						</div>
						<div class='modal-body' style={{ maxHeight: window.innerHeight - 200, overflowY: 'scroll' }}>
							<Row>
								<LabelAndInputNumber
									label={this.state.modal.tipoTela == 'inicio' ? 'Km Inicial' : 'Km Final'}
									placeholder=''
									cols='12 4 4 4'
									casas={0}
									value={this.state.modal.tipoTela == 'inicio' ? this.state.modal.km_inicio_viagem : this.state.modal.km_fim_viagem}
									onChange={data => {
										if (this.state.modal.tipoTela == 'inicio') {
											this.setState({
												...this.state,
												modal: {
													...this.state.modal,
													km_inicio_viagem: data.target.value
												}
											});	
										} else {
											this.setState({
												...this.state,
												modal: {
													...this.state.modal,
													km_fim_viagem: data.target.value
												}
											});	
										}
									}} />
							</Row>

							<Row alignCenter>
								<Grid cols='12 6 4 4'>
									<button 
										type='button'
										class='btn btn-block btn-success btn-lg'
										onClick={() => {

											let registro = {
												...this.state.modal
											};

											if (this.state.modal.tipoTela == 'inicio') {
												registro = {
													...registro,
													datahora_inicio_viagem: DateFormat.getDataHoraAtual(),
													id_usuario_inicio_viagem: this.props.sessao.id
												};
											} else {
												registro = {
													...registro,
													datahora_fim_viagem: DateFormat.getDataHoraAtual(),
													id_usuario_fim_viagem: this.props.sessao.id
												};
											}

											if (window.confirm(`Você confirma que o km ${this.state.modal.tipoTela == 'inicio' ? 'inicial' : 'final'} é ${this.state.modal.tipoTela == 'inicio' ? this.state.modal.km_inicio_viagem : this.state.modal.km_fim_viagem}?`)) {
												this.props.alterarViagemMotorista(registro, () => {
													this.setState({
														...this.state,
														modal: null
													});	
												});
											}
										}}>
										Salvar
									</button>
								</Grid>
							</Row>
						</div>
						<div class='modal-footer justify-content-between'>
							<button type='button' class='btn btn-default' data-dismiss='modal' style={{ visibility: 'hidden' }}>Close</button>
						</div>
					</div>
				</div>
			</div>
        );
    }
	
}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	usuarioCarregado: state.auth.usuarioCarregado,
	aguardando: state.pedido.aguardando,
	modoTela: state.pedido.modoTela,
	lista: state.pedido.listaPedidoMotorista
});
const mapDispatchToProps = dispatch => bindActionCreators({ 
	getListaPedidoMotorista, alterarViagemMotorista
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(PedidoMotorista);
