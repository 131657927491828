import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import { setError } from '../common/toast/toast';
import readXlsxFile from 'read-excel-file/web-worker'
import ReactExport from 'react-export-excel-fixed-xlsx';

import {
	setModoTela, initForm, salvar, excluir, getListaMetaApuracao, importarCustos
} from './metaManutencaoConfiguracaoActions';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import Select from '../common/form/select';
import FormatUtils from '../common/formatUtils/FormatUtils';
import DateFormat from '../common/dateFormat/DateFormat';

class MetaManutencaoApuracao extends Component {

	state = {
		linhaSelecionada: null,
		pesquisar: '',
		ano: 2025,
		trimestre: 1
	}

	componentDidMount() {

		const fileSelector = document.createElement('input');
		fileSelector.setAttribute('type', 'file');
		fileSelector.addEventListener("change", async (data) => {

			let reader = new FileReader();
			let file = data && data.path && data.path[0] && data.path[0].files && data.path[0].files[0] ? data.path[0].files[0] : null;
			if (!file) {
				file = data && data.target && data.target.files && data.target.files[0] ? data.target.files[0] : null;
			}

			if (file) {

				if (!file.type.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
					|| (file.type.includes('application/excel') || file.type.includes('application/vnd.ms-excel'))) {
					setError('Selecione apenas (.XLSX)');
					return;
				}

				let tabela = [];
				await readXlsxFile(file, { sheet: 1 }).then((rows) => {
					tabela = rows;
				});

				this.props.importarCustos(tabela);

				reader.readAsDataURL(file);

			}
		});

		this.fileSelector = fileSelector;

	}

    componentWillMount() {
        this.props.getListaMetaApuracao();
    }


    render() {
        return (
            <div>
                <Content>
					{this.lista()}
                </Content>
            </div>
        )
    }

	lista() {

		const ExcelFile = ReactExport.ExcelFile;
		const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
		const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
		
		return (
			<ContentCard>
				<ContentCardHeader>
					<Grid cols='6 6 3 2'>
						<Button
							text='Importar Custos'
							type={'primary'}
							icon={'fa fa-chevron-left'}
							event={() => {
								this.fileSelector.click();
							}} />
					</Grid>
				</ContentCardHeader>
				<ContentCardBody>
					<Row>

						<Select
							options={[{ id: 2024, valor: 2024 }, { id: 2025, valor: 2025 }, { id: 2026, valor: 2026 }]}
							label='Ano'
							cols='12 4 3 2'
							placeholder='Selecione o ano'
							value={this.state.ano}
							onChange={value => {
								this.setState({
									...this.state,
									ano: value
								});
							}} />
								
						<Select
							options={[{ id: 1, valor: '1º trimestre' }, { id: 2, valor: '2º trimestre' }, { id: 3, valor: '3º trimestre' }, { id: 4, valor: '4º trimestre' } ]}
							label='Trimestre'
							cols='12 4 3 2'
							placeholder='Selecione o trimestre'
							value={this.state.trimestre}
							onChange={value => {
								this.setState({
									...this.state,
									trimestre: value
								});
							}} />
					</Row>
					 
					{this.props.lista.filter(item => {
						if (this.state.ano && this.state.trimestre) {
							return item.ano == this.state.ano && item.trimestre == this.state.trimestre;
						}
						return false;
					}).map(item => {
						
						return (
							<Table responsive key={item.id}>
								<THead>
									<Tr backgroundColor={'#dfe2f5'}>
										<Th fontSize={18}>{item.nome_usuario}</Th>
										<Th alignCenter fontSize={18}>Meta</Th>
										<Th alignCenter fontSize={18}>Realizado</Th>
										<Th alignCenter fontSize={18}>Resultado</Th>
									</Tr>
								</THead>
								<TBody>								
									<Tr>
										<Td width={500}>Redução do Custo da Frota</Td>
										<Td width={100} alignCenter fontSize={24} 
											title={
												`Faixa 1: ${FormatUtils.formatarValorTelaDecimal(item.percentual_reducao_custo_frota_faixa_1, 2)}%\n`
												+ `Faixa 2: ${FormatUtils.formatarValorTelaDecimal(item.percentual_reducao_custo_frota_faixa_2, 2)}%`
											}>{FormatUtils.formatarValorTelaDecimal(item.percentual_reducao_custo_frota_faixa_2, 2)}%</Td>
										<Td width={100} alignCenter fontSize={24}>{FormatUtils.formatarValorTelaDecimal(item.custo_km, 2)} R$/km</Td>
										<Td width={100} alignCenter fontSize={24}>{item.faixa_percentual_reducao_custo}</Td>
									</Tr>

									<Tr>
										<Td width={500}>Produtividade de Km Rodados</Td>
										<Td width={100} alignCenter fontSize={24}>{FormatUtils.formatarValorTelaDecimal(item.media_produtividade_km, 0)} km</Td>
										<Td width={100} alignCenter fontSize={24}>{FormatUtils.formatarValorTelaDecimal(item.projecao_produtividade_km, 0)} km</Td>
										<Td width={100} alignCenter fontSize={24}>{item.faixa_produtividade_km}</Td>
									</Tr>

									<Tr>
										<Td width={500}>Feedback dos motoristas</Td>
										<Td width={100} alignCenter fontSize={24}>{FormatUtils.formatarValorTelaDecimal(item.percentual_feedback_motorista, 2)}%</Td>
										<Td width={100} alignCenter fontSize={24}>{FormatUtils.formatarValorTelaDecimal(item.realizado_percentual_feedback, 2)}%</Td>
										<Td width={100} alignCenter fontSize={24}>{item.faixa_feedback_motorista}</Td>
									</Tr>

									<Tr>
										<Td width={500}>Execução dos Processos</Td>
										<Td width={100} alignCenter fontSize={24}></Td>
										<Td width={100} alignCenter fontSize={24}></Td>
										<Td width={100} alignCenter fontSize={24}>{item.faixa_executou_processos}</Td>
									</Tr>
								</TBody>
							</Table>
						);
					})}
				</ContentCardBody>
			</ContentCard>
		);
	}

}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	modoTela: state.metaManutencaoConfiguracao.modoTela,
	filtro: state.metaManutencaoConfiguracao.filtro,
	lista: state.metaManutencaoConfiguracao.listaMetaApuracao,
	listaApuracao: state.metaManutencaoConfiguracao.listaApuracao
});
const mapDispatchToProps = dispatch => bindActionCreators({ 
	setModoTela, initForm, salvar, excluir,
	getListaMetaApuracao, importarCustos
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(MetaManutencaoApuracao);
