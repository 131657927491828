import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import Select from '../common/form/select';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndInputDate from '../common/form/labelAndInputDate';
import LabelAndCheckbox from '../common/form/labelAndCheckbox';
import ButtonTable from '../common/table/buttonTable';
import { setError } from '../common/toast/toast';
import imagemAdd from '../assets/images/icone_add_imagem.png';
import pdfAdd from '../assets/images/icone_add_pdf.png';
import pdfAdicionado from '../assets/images/icone_pdf_adicionado.png';

import { setModoTela, initForm } from './metaManutencaoConfiguracaoActions';

class MetaManutencaoConfiguracaoForm extends Component {

    componentWillMount() {

    }

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

        return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>
					<ContentCardBody>
						<Row>

							<Field
								name='ano'
								component={Select}
								options={[{ id: 2024, valor: 2024 }, { id: 2025, valor: 2025 }, { id: 2026, valor: 2026 }]}
								label='Ano'
								cols='12 4 3 2'
								placeholder='Selecione o ano'
								readOnly={readOnly} />
								
							<Field
								name='trimestre'
								component={Select}
								options={[{ id: 1, valor: '1º trimestre' }, { id: 2, valor: '2º trimestre' }, { id: 3, valor: '3º trimestre' }, { id: 4, valor: '4º trimestre' } ]}
								label='Trimestre'
								cols='12 4 3 2'
								placeholder='Selecione o trimestre'
								readOnly={readOnly} />

							<Field
								name='id_usuario'
								component={Select}
								options={this.props.listaUsuario}
								label='Usuário'
								cols='12 12 12 12'
								placeholder='Selecione o usuário'
								readOnly={readOnly} />

						</Row> 

						<Row>
							<Field
								name='percentual_reducao_custo_frota_faixa_1'
								component={LabelAndInputNumber}
								label='Custo Frota (Faixa 1) (%)' placeholder='Informe'
								cols='12 6 4 3'
								casas={2}
								readOnly={readOnly}
								title='Percentual de redução no custo da frota em comparação ao mesmo trimestre do ano anterior' />

							<Field
								name='percentual_reducao_custo_frota_faixa_2'
								component={LabelAndInputNumber}
								label='Custo Frota (Faixa 2) (%)' placeholder='Informe'
								cols='12 6 4 3'
								casas={2}
								readOnly={readOnly}
								title='Percentual de redução no custo da frota em comparação ao mesmo trimestre do ano anterior' />
							
							<Field
								name='custo_frota_ano_anterior'
								component={LabelAndInputNumber}
								label='Custo Frota Anterior (R$/km)' placeholder='Informe'
								cols='12 6 4 3'
								casas={2}
								readOnly={readOnly}
								title='Custo da frota do mesmo trimestre do ano anterior' />
						</Row>

						<Row>						
							<Field
								name='media_produtividade_km'
								component={LabelAndInputNumber}
								label='Média de Produtividade Trimestre (km)' placeholder='Informe'
								cols='12 6 4 3'
								casas={0}
								readOnly={readOnly}
								title='Meta da média de produtividade de km rodados no trimestre' />
						</Row>

						<Row>
							<Field
								name='percentual_feedback_motorista'
								component={LabelAndInputNumber}
								label='Feedback dos motoristas (%)' placeholder='Informe'
								cols='12 6 4 3'
								casas={2}
								readOnly={readOnly}
								title='Percentual de efetividade da realização de feedbacks no prazo de fechamento da folha dos motoritas' />
						</Row>

						<Row>	
							<Field
								name='executou_processos'
								component={LabelAndCheckbox}
								label='Executou os processos da manutenção com êxito' placeholder='Informe'
								cols='12 6 4 3'
								readOnly={readOnly} />
						
						</Row>

						<Row>
							<Select
								name='veiculos'
								label='Veículos' placeholder='Selecione os veículos'
								cols='12 12 12 12'
								multiple
								readOnly={readOnly}
								options={this.props.listaVeiculo}
								value={this.props.listaVeiculo.map(item => ({ value: item.id, label: item.valor })).filter(item => (this.props.formularioValues.veiculos || []).includes(item.value))}
								onChange={value => {
									this.props.initForm({
										...this.props.formularioValues,
										veiculos: value.map(item => item.value)
									});
								}} />
						</Row>

					</ContentCardBody>
					<ContentCardFooter>
						<Row alignCenter>
							<Grid cols='6 6 4 3'>
								{this.props.excluir ? (
									<Button
										text='Excluir'
										submit type={'danger'}
										icon={'fa fa-trash'} />
								) : (
								<Button
									text='Salvar'
									submit
									type={'success'}
									icon={'fa fa-check'} />
								)}
							</Grid>
							<Grid cols='6 6 4 3'>
								<Button
									text='Voltar'
									type={'warning'}
									icon={'fa fa-chevron-left'}
									event={() => this.props.setModoTela('lista')} />
							</Grid>
						</Row>
					</ContentCardFooter>

				</Form>
			</ContentCard>
        )
    }


}

MetaManutencaoConfiguracaoForm = reduxForm({form: 'metaManutencaoConfiguracaoForm', destroyOnUnmount: false})(MetaManutencaoConfiguracaoForm);
const mapStateToProps = state => ({
	sessao: state.auth.metaManutencaoConfiguracao,
	formularioValues: getFormValues('metaManutencaoConfiguracaoForm')(state),
	registro: state.metaManutencaoConfiguracao.registro,
	listaUsuario: state.metaManutencaoConfiguracao.listaUsuario.filter(item => item.administrador),
	listaVeiculo: state.metaManutencaoConfiguracao.listaVeiculo
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, initForm }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(MetaManutencaoConfiguracaoForm);
