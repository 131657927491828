const INITIAL_STATE = { 
    registro: {}, 
    modoTela: 'lista', 
    lista: [], 
    listaUsuario: [],
    listaMetaApuracao: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'META_LOGISTICA_CONFIGURACAO_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

        case 'META_LOGISTICA_CONFIGURACAO_LISTADO':
            return { ...state, lista: action.payload.data };

        case 'META_LOGISTICA_CONFIGURACAO_USUARIO_SELECT_LISTADO':
			return {
				...state,
				listaUsuario: action.payload.data
            };
        
        case 'META_LOGISTICA_CONFIGURACAO_APURACAO_LISTADO':
			return {
				...state,
				listaMetaApuracao: action.payload.data
            };
                      
        default:
            return state;
    }
}
