import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndTextarea from '../common/form/labelAndTextarea';
import Select from '../common/form/select';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import LabelAndInputDate from '../common/form/labelAndInputDate';
import LabelAndCheckbox from '../common/form/labelAndCheckbox';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import { setError } from '../common/toast/toast';
import imagemAdd from '../assets/images/icone_add_imagem.png';
import pdfAdd from '../assets/images/icone_add_pdf.png';
import pdfAdicionado from '../assets/images/icone_pdf_adicionado.png';

import { setModoTela, initForm } from './equipamentoAlocacaoActions';
import DateFormat from '../common/dateFormat/DateFormat';

class EquipamentoAlocacaoForm extends Component {

	state = {
		manutencao: null
	}

    componentWillMount() {

		let initSeletorArquivo = () => {
			const fileSelectorArquivo = document.createElement('input');
			fileSelectorArquivo.setAttribute('type', 'file');
			fileSelectorArquivo.addEventListener("change", (data) => {

				let reader = new FileReader();
				let file = data && data.path && data.path[0] && data.path[0].files && data.path[0].files[0] ? data.path[0].files[0] : null;
				if (!file) {
					file = data && data.target && data.target.files && data.target.files[0] ? data.target.files[0] : null;
				}

				if (file) {

					if (!file.type.includes('application/pdf')
						&& !file.type.includes('image/png') && !file.type.includes('image/jpg') && !file.type.includes('image/jpeg')) {
						setError('Selecione apenas (.PDF, .PNG, .JPG e .JPEG)');
						return;
					}

					reader.onloadend = () => {


						let lista = (this.props.formularioValues.arquivos || []).map(item => item);
						lista.push({
							arquivo_base64: reader.result
						});

						this.props.initForm({
							...this.props.formularioValues,
							arquivos: lista
						});

						initSeletorArquivo();

					}

					reader.readAsDataURL(file);

				}
			});

			this.fileSelectorArquivo = fileSelectorArquivo;
		};

		initSeletorArquivo();

    }

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

        return (
			<ContentCard>
				
				{this.modalManutencao()}

				<Form event={this.props.handleSubmit}>
					<ContentCardBody>
						<Row>

							<Field
								name='numero'
								component={LabelAndInput}
								label='Número/ID' placeholder='Informe o número'
								cols='12 2 2 2'
								readOnly={readOnly} />

							<Field
								name='nome'
								component={LabelAndInput}
								label='Nome' placeholder='Informe o nome'
								cols='12 5 5 5'
								readOnly={readOnly} />

							<Field
								name='tipo'
								component={LabelAndInput}
								label='Tipo' placeholder='Informe o tipo'
								cols='12 5 5 5'
								readOnly={readOnly} />
	

							{!this.props.formularioValues.id_veiculo &&
							<Field
								name='id_unidade'
								component={Select}
								options={this.props.listaUnidade}
								label='Unidade'
								cols='12 12 12 12'
								placeholder='Selecione o unidade'
								readOnly={readOnly} />}

							{!this.props.formularioValues.id_unidade &&
							<Field
								name='id_veiculo'
								component={Select}
								options={this.props.listaVeiculo}
								label='Veículo'
								cols='12 12 12 12'
								placeholder='Selecione o veículo'
								readOnly={readOnly} />}
						
							<Field
								name='emprestimo'
								component={LabelAndCheckbox}
								label='Empréstimo' placeholder='Informe se é empréstimo'
								cols='12 12 12 12'
								readOnly={readOnly} />

							<Field
								name='data_inicio'
								component={LabelAndInputDate}
								label='Início da Alocação' placeholder='Informe a data'
								cols='12 4 4 4'
								readOnly={readOnly} />

							<Field
								name='data_fim'
								component={LabelAndInputDate}
								label='Fim da Alocação' placeholder='Informe a data'
								cols='12 4 4 4'
								readOnly={readOnly} />
	
							<Field
								name='observacao'
								component={LabelAndTextarea}
								label='Observação' placeholder='Informe a observação'
								cols='12 12 12 12'
								readOnly={readOnly} />
						</Row> 

						<Row>
							<Grid cols='12 12 12 12'>

								<Row style={{ paddingBottom: 16 }}>
									<Grid cols='12 12 12 12'>
										<label>Anexos</label>
									</Grid>
									{(this.props.formularioValues.arquivos || []).map(arquivo => (
										<div style={{ display: 'flex', flexDirection: 'column' }}>
											<span class="btn btn-outline-primary fileinput-button dz-clickable">
												<img src={arquivo.arquivo_base64 ? pdfAdicionado : (arquivo.arquivo ? pdfAdicionado : pdfAdd)} style={{ height: 30 }} />
											</span>
											<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingRight: 2, marginTop: 4 }}>
												<button
													type='button'
													class='btn btn-flat btn-outline-primary'
													style={{ width: '48%' }}
													disabled={arquivo.arquivo_base64 ?true : false}
													onClick={() => {
														window.open(`https://api-valedaserra.infinitum-ti.com/${arquivo.arquivo}`, '_blank');
													}}>
													<i class='fas fa-download'></i>
												</button>

												<button
													type='button'
													class='btn btn-flat btn-outline-danger'
													style={{ width: '48%' }}
													disabled={readOnly}
													onClick={() => {
														this.props.initForm({
															...this.props.formularioValues,
															arquivos: this.props.formularioValues.arquivos.filter(item => item != arquivo)
														});
													}}>
													<i class='fas fa-trash-alt'></i>
												</button>
											</div>
										</div>
									))}
									{!readOnly ? (
										<span class="btn btn-outline-primary fileinput-button dz-clickable" onClick={() => this.fileSelectorArquivo.click()}>
											<img src={pdfAdd} style={{ height: 30 }} />
										</span>
									) : null}
								</Row>

							</Grid>
						</Row>
						<Row>
							<Grid cols='12 12 12 12'>
								<Table responsive>
									<THead>
										<Tr>
											<Th alignCenter>Data Manutenção</Th>
											<Th alignCenter>Descrição</Th>
											<Th alignCenter>Responsável</Th>
											<Th alignCenter>Observação</Th>
											<Th alignCenter>Conexões Inspecionadas</Th>
											<Th alignCenter>Status das Conexões</Th>
											<Th alignCenter>Próxima Manutenção</Th>
											<Th></Th>
										</Tr>
									</THead>
									<TBody>
										{(this.props.formularioValues.manutencoes || []).map(manutencao => (
											<Tr key={manutencao.id}>
												<Td alignCenter>{DateFormat.formatarDataSqlParaTela(manutencao.data_manutencao)}</Td>
												<Td alignCenter>{manutencao.descricao}</Td>
												<Td alignCenter>{manutencao.responsavel}</Td>
												<Td alignCenter>{manutencao.observacao}</Td>
												<Td alignCenter>{manutencao.conexao_inspecionada}</Td>
												<Td alignCenter>{manutencao.status_conexao}</Td>
												<Td alignCenter>{DateFormat.formatarDataSqlParaTela(manutencao.data_proxima_manutencao)}</Td>
												<Td alignRight minWidth={100}>
													<ButtonTable
														type={'warning'}
														icon={'fas fa-pencil-alt'}
														visible={false}
														event={() => {															
															this.setManutencao({
																...manutencao,
																data_manutencao: DateFormat.formatarDataSqlParaTela(manutencao.data_manutencao),
																data_proxima_manutencao: DateFormat.formatarDataSqlParaTela(manutencao.data_proxima_manutencao)
															});
														}} />

													<ButtonTable
														type={'danger'}
														icon={'fas fa-trash-alt'}
														visible={true}
														event={() => {
															this.props.initForm({
																...this.props.formularioValues,
																manutencoes: this.props.formularioValues.manutencoes.filter(item => item.id != manutencao.id)
															});
														}} />
												</Td>
											</Tr>
										))}
									</TBody>
								</Table>
							</Grid>

							<Grid cols='12 6 4 4'>
								<Button
									text='Adicionar Manutenção'
									type={'primary'}
									icon={'fa fa-chevron-left'}
									event={() => {
										this.setManutencao({
											id: new Date().getTime(),
											data_manutencao: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAtual())
										});
									}} />
							</Grid>
						
						</Row>
					</ContentCardBody>
					<ContentCardFooter>
						<Row alignCenter>
							<Grid cols='6 6 4 3'>
								{this.props.excluir ? (
									<Button
										text='Excluir'
										submit type={'danger'}
										icon={'fa fa-trash'} />
								) : (
								<Button
									text='Salvar'
									submit
									type={'success'}
									icon={'fa fa-check'} />
								)}
							</Grid>
							<Grid cols='6 6 4 3'>
								<Button
									text='Voltar'
									type={'warning'}
									icon={'fa fa-chevron-left'}
									event={() => this.props.setModoTela('lista')} />
							</Grid>
						</Row>
					</ContentCardFooter>

				</Form>
			</ContentCard>
        )
    }

	setManutencao(registro) {
		this.setState({
			...this.state,
			manutencao: registro
		});
	}

	modalManutencao() {
		if (!this.state.manutencao) {
			return null;
		}

		let manutencao = this.state.manutencao;

		return (
			<div class='modal fade show' id='modal-xl' style={{ display: 'block' }} aria-modal='true'>
				<div class='modal-dialog modal-xl'>
					<div class='modal-content'>
						<div class='modal-header'>
							<h4 class='modal-title'>Manutenção</h4>
							<button type='button' class='close' data-dismiss='modal' aria-label='Close' onClick={() => this.setManutencao(null)}>
								<span aria-hidden='true'>×</span>
							</button>
						</div>
						<div class='modal-body' style={{ maxHeight: window.innerHeight - 200, overflowY: 'scroll' }}>
							<ContentCardBody>
								<Row>

									<LabelAndInputMask
										name='data_manutencao'
										label='Data da Manutenção' placeholder='Informe a data'
										cols='12 4 4 4'
										mask='99/99/9999'
										value={manutencao.data_manutencao}
										onChange={data => this.setManutencao({
											...manutencao,
											data_manutencao: data.target.value
										})} />

									<LabelAndInput
										name='descricao'
										label='Descrição' placeholder='Informe'
										cols='12 12 12 12'
										value={manutencao.descricao}
										onChange={data => this.setManutencao({
											...manutencao,
											descricao: data.target.value
										})} />

									<LabelAndInput
										name='responsavel'
										label='Responsável' placeholder='Informe'
										cols='12 12 12 12'
										value={manutencao.responsavel}
										onChange={data => this.setManutencao({
											...manutencao,
											responsavel: data.target.value
										})} />
									
									<LabelAndInput
										name='observacao'
										label='Observação' placeholder='Informe'
										cols='12 12 12 12'
										value={manutencao.observacao}
										onChange={data => this.setManutencao({
											...manutencao,
											observacao: data.target.value
										})} />
									
									<Select
										name='conexao_inspecionada'
										options={[{ id: 'Sim', valor: 'Sim' }, { id: 'Não', valor: 'Não' }]}
										label='Conexões Inspecionadas'
										cols='12 12 12 12'
										placeholder='Selecione'
										value={manutencao.conexao_inspecionada}
										onChange={value => this.setManutencao({
											...manutencao,
											conexao_inspecionada: value
										})} />
										
									<Select
										name='status_conexao'
										options={[{ id: 'Ok', valor: 'Ok' }, { id: 'Precisa de Reparos', valor: 'Precisa de Reparos' }]}
										label='Status das Conexões'
										cols='12 12 12 12'
										placeholder='Selecione'
										value={manutencao.conexao_inspecionada}
										onChange={value => this.setManutencao({
											...manutencao,
											status_conexao: value
										})} />									

									<LabelAndInputMask
										name='data_proxima_manutencao'
										label='Próxima Manutenção' placeholder='Informe a data'
										cols='12 4 4 4'
										mask='99/99/9999'
										value={manutencao.data_proxima_manutencao}
										onChange={data => this.setManutencao({
											...manutencao,
											data_proxima_manutencao: data.target.value
										})} />
			
								</Row>
							</ContentCardBody>
							<ContentCardFooter>
								<Row alignCenter>
									<Grid cols='12 6 4 4'>
										<Button
											text='Salvar'
											type={'success'}
											icon={'fa fa-check'}
											disabled={
												!DateFormat.formatarDataTelaParaSql(manutencao.data_manutencao)
												|| !manutencao.descricao
												|| !manutencao.responsavel
											}
											event={() => {

												let lista = (this.props.formularioValues.manutencoes || []).filter(item => item.id != manutencao.id);
												lista.push({
													...manutencao,
													data_manutencao: DateFormat.formatarDataTelaParaSql(manutencao.data_manutencao),
													data_proxima_manutencao: DateFormat.formatarDataTelaParaSql(manutencao.data_proxima_manutencao)
												});

												this.props.initForm({
													...this.props.formularioValues,
													manutencoes: lista
												});

												this.setManutencao(null);
											}} />
									</Grid>
								</Row>
							</ContentCardFooter>
						</div>
						<div class='modal-footer justify-content-between'>
							<button type='button' class='btn btn-default' data-dismiss='modal' style={{ visibility: 'hidden' }}>Close</button>
						</div>
					</div>
				</div>
			</div>
        );
	}

}

EquipamentoAlocacaoForm = reduxForm({form: 'equipamentoAlocacaoForm', destroyOnUnmount: false})(EquipamentoAlocacaoForm);
const mapStateToProps = state => ({
	sessao: state.auth.equipamentoAlocacao,
	formularioValues: getFormValues('equipamentoAlocacaoForm')(state),
	registro: state.equipamentoAlocacao.registro,
	listaUnidade: state.equipamentoAlocacao.listaUnidade,
    listaVeiculo: state.equipamentoAlocacao.listaVeiculo
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, initForm }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(EquipamentoAlocacaoForm);
