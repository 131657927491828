import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import { setError } from '../common/toast/toast';
import readXlsxFile from 'read-excel-file/web-worker'
import ReactExport from 'react-export-excel-fixed-xlsx';

import {
	getListaApuracao
} from './metaHeitorSemestreActions';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import Select from '../common/form/select';
import FormatUtils from '../common/formatUtils/FormatUtils';
import DateFormat from '../common/dateFormat/DateFormat';
import Aguardando from '../common/template/aguardando';
import imagemLogo from '../assets/images/logo.png';

class MetaHeitorSemestreApuracao extends Component {

	state = {
		linhaSelecionada: null,
		pesquisar: '',
		ano: 2025,
		semestre: 1
	}

	componentWillMount() {
		this.props.getListaApuracao();
	}


    render() {
		
		return (
            <div>
				
				<Aguardando aguardando={this.props.aguardando} />

                <Content>
					{this.lista()}
                </Content>
            </div>
        )
    }

	lista() {

		const ExcelFile = ReactExport.ExcelFile;
		const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
		const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
		
		return (
			<ContentCard>
				<ContentCardHeader>
					<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginBottom: 24 }}>
						<img src={imagemLogo} style={{ width: 250, height: 79 }}/>
						<div style={{ display: 'flex', height: 79, flexDirection: 'column', marginLeft:24, justifyContent: 'center', paddingTop: 25 }}>
							<h4 style={{ fontSize: 18, fontWeight: 'bold', color: '#194682' }}>META HEITOR - TRR VALE DA SERRA</h4>
							<h5 style={{ fontSize: 14, fontWeight: 'bold', color: '#194682' }}>NO VALE DO ARAGUAIA, COMBUSTÍVEL É VALE DA SERRA!</h5>
						</div>
					</div>		
				</ContentCardHeader>
				<ContentCardBody>
					<Row>

						<Select
							options={[{ id: 2025, valor: 2025 }, { id: 2026, valor: 2026 }]}
							label='Ano'
							cols='12 4 3 2'
							placeholder='Selecione o ano'
							value={this.state.ano}
							onChange={value => {
								this.setState({
									...this.state,
									ano: value
								});
							}} />
								
						<Select
							options={[{ id: 1, valor: '1º semestre' }, { id: 2, valor: '2º semestre' } ]}
							label='Semestre'
							cols='12 4 3 2'
							placeholder='Selecione o semestre'
							value={this.state.semestre}
							onChange={value => {
								this.setState({
									...this.state,
									semestre: value
								});
							}} />
					</Row>
				
					{(this.props.listaApuracao || []).filter(item => {
						if (this.state.ano && this.state.semestre) {
							return item.ano == this.state.ano && item.semestre == this.state.semestre;
						}
						return false;
					}).map(item => {

						return (
							<Table responsive key={item.id}>
								<THead>
									<Tr color={item.geral ? '#ffffff' : '#194682'}
										backgroundColor={item.geral ? '#194682' : '#dfe2f5'}>
										<Th fontSize={18}>{item.nome}</Th>										
										<Th alignCenter fontSize={18}>Meta</Th>
										<Th alignCenter fontSize={18}>Realizado</Th>
										<Th alignCenter fontSize={18}>Projetado</Th>
										<Th alignCenter fontSize={18}>Faixa</Th>
									</Tr>
								</THead>
								<TBody>								
									<Tr>
										<Td width={500} bold>Volume</Td>
										<Td width={150} alignCenter fontSize={24}
											title={
												`Faixa 1: ${FormatUtils.formatarValorTelaDecimal(item.volume_1, 0)} \n`
												+ `Faixa 2: ${FormatUtils.formatarValorTelaDecimal(item.volume_2, 0)} \n`
												+ `Faixa 3: ${FormatUtils.formatarValorTelaDecimal(item.volume_3, 0)} \n`
												+ `Faixa 4: ${FormatUtils.formatarValorTelaDecimal(item.volume_4, 0)} \n`
											}>{FormatUtils.formatarValorTelaDecimal(item.volume_4, 0)}</Td>
										<Td width={150} alignCenter fontSize={24} >{FormatUtils.formatarValorTelaDecimal(item.volume_realizado, 0)}</Td>
										<Td width={150} alignCenter fontSize={24}>{FormatUtils.formatarValorTelaDecimal(item.volume_projetado, 0)}</Td>
										<Td width={100} alignCenter fontSize={24}>{item.faixa_volume}</Td>
									</Tr>
									<Tr>
										<Td width={500} bold>Margem</Td>
										<Td width={150} alignCenter fontSize={24}
											title={
												`Faixa 1: ${FormatUtils.formatarValorTelaDecimal(item.margem_1, 2)} \n`
												+ `Faixa 2: ${FormatUtils.formatarValorTelaDecimal(item.margem_2, 2)} \n`
												+ `Faixa 3: ${FormatUtils.formatarValorTelaDecimal(item.margem_3, 2)} \n`
												+ `Faixa 4: ${FormatUtils.formatarValorTelaDecimal(item.margem_4, 2)} \n`
											}>{FormatUtils.formatarValorTelaDecimal(item.margem_4, 2)}</Td>
										<Td width={150} alignCenter fontSize={24} >{FormatUtils.formatarValorTelaDecimal(item.margem_realizado, 2)}</Td>
										<Td width={150} alignCenter fontSize={24}>{FormatUtils.formatarValorTelaDecimal(item.margem_realizado, 2)}</Td>
										<Td width={100} alignCenter fontSize={24}>{item.faixa_margem}</Td>
									</Tr>
									<Tr>
										<Td width={500} bold>Prazo</Td>
										<Td width={150} alignCenter fontSize={24}
											title={
												`Faixa 1: ${FormatUtils.formatarValorTelaDecimal(item.prazo_1, 2)} \n`
												+ `Faixa 2: ${FormatUtils.formatarValorTelaDecimal(item.prazo_2, 2)} \n`
												+ `Faixa 3: ${FormatUtils.formatarValorTelaDecimal(item.prazo_3, 2)} \n`
												+ `Faixa 4: ${FormatUtils.formatarValorTelaDecimal(item.prazo_4, 2)} \n`
											}>{FormatUtils.formatarValorTelaDecimal(item.prazo_4, 2)}</Td>
										<Td width={150} alignCenter fontSize={24} >{FormatUtils.formatarValorTelaDecimal(item.prazo_realizado, 2)}</Td>
										<Td width={150} alignCenter fontSize={24}>{FormatUtils.formatarValorTelaDecimal(item.prazo_realizado, 2)}</Td>
										<Td width={100} alignCenter fontSize={24}>{item.faixa_prazo}</Td>
									</Tr>
									<Tr>
										<Td width={500} bold>Inadimplência</Td>
										<Td width={150} alignCenter fontSize={24}
											title={
												`Inadimplência 1: ${FormatUtils.formatarValorTelaDecimal(item.inadimplencia_1, 2)} \n`
												+ `Inadimplência 2: ${FormatUtils.formatarValorTelaDecimal(item.inadimplencia_2, 2)} \n`
												+ `Inadimplência 3: ${FormatUtils.formatarValorTelaDecimal(item.inadimplencia_3, 2)} \n`
												+ `Inadimplência 4: ${FormatUtils.formatarValorTelaDecimal(item.inadimplencia_4, 2)} \n`
											}>{FormatUtils.formatarValorTelaDecimal(item.inadimplencia_4, 2)}</Td>
										<Td width={150} alignCenter fontSize={24} >{FormatUtils.formatarValorTelaDecimal(item.inadimplencia_realizado, 2)}</Td>
										<Td width={150} alignCenter fontSize={24}>{FormatUtils.formatarValorTelaDecimal(item.inadimplencia_realizado, 2)}</Td>
										<Td width={100} alignCenter fontSize={24}>{item.faixa_inadimplencia}</Td>
									</Tr>
									<Tr>
										<Td width={500} bold>Ocorrência de qualidade</Td>
										<Td width={150} alignCenter fontSize={24}></Td>
										<Td width={150} alignCenter fontSize={24} ></Td>
										<Td width={150} alignCenter fontSize={24}></Td>
										<Td width={100} alignCenter fontSize={24}>{item.faixa_ocorrencia_qualidade}</Td>
									</Tr>
									{/*<Tr>
										<Td width={500} bold>Fidelização</Td>
										<Td width={150} alignCenter fontSize={24}>{item.quantidade_minima_fidelizacao}</Td>
										<Td width={150} alignCenter fontSize={24}>{item.fidelizacao_realizado}</Td>
										<Td width={150} alignCenter fontSize={24}>{item.fidelizacao_realizado}</Td>
										<Td width={100} alignCenter fontSize={24}>{item.faixa_fidelizacao}</Td>
									</Tr>
									<Tr>
										<Td width={500} bold>Cliente Novo</Td>
										<Td width={150} alignCenter fontSize={24}></Td>
										<Td width={150} alignCenter fontSize={24}>{item.cliente_novo_realizado}</Td>
										<Td width={150} alignCenter fontSize={24}>{item.cliente_novo_realizado}</Td>
										<Td width={100} alignCenter fontSize={24}>{item.faixa_cliente_novo}</Td>
									</Tr>
									<Tr>
										<Td width={500} bold>Cliente Retomada</Td>
										<Td width={150} alignCenter fontSize={24}></Td>
										<Td width={150} alignCenter fontSize={24}>{item.cliente_novo_realizado}</Td>
										<Td width={150} alignCenter fontSize={24}>{item.cliente_novo_realizado}</Td>
										<Td width={100} alignCenter fontSize={24}>{item.faixa_cliente_novo}</Td>
									</Tr>*/}
								</TBody>
							</Table>
						);
					})}
				</ContentCardBody>
			</ContentCard>
		);
	}

}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	modoTela: state.metaHeitorSemestre.modoTela,
	aguardando: state.metaHeitorSemestre.aguardando,
	filtro: state.metaHeitorSemestre.filtro,
	listaApuracao: state.metaHeitorSemestre.listaApuracao
});
const mapDispatchToProps = dispatch => bindActionCreators({ 
	getListaApuracao
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(MetaHeitorSemestreApuracao);
